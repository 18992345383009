import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { useUpdateKnowledgeDocumentationMutation } from '../../knowledgeApiSlice'
import KnowledgeDocumentationLinkForm from './KnowledgeDocumentationLinkForm'
import BaseModal from '../../../../components/modal/BaseModal'
import addLinkSchema, {
  initAddLinkValidationErrors,
} from '../../../../common/validations/addKnowledgeLinkSchema'
import validateFields from '../../../../common/validations/validateFields'
import validateForm from '../../../../common/validations/validateForm'
import KnowledgeDocumentationEditLinkModalProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationEditLinkModalProps'

function KnowledgeDocumentationEditLinkModal({
  openModal,
  handleModalClose,
  setPopupMessage,
  link,
  description,
  name,
  id: documentationId,
}: KnowledgeDocumentationEditLinkModalProps) {
  const { t } = useTranslation()
  const { id: knowledgeId } = useParams()
  const [updateDocumentation, { isLoading: updateDocumentationLoading }] =
    useUpdateKnowledgeDocumentationMutation()

  const [validationLinkErrors, setValidationLinkErrors] = useState(initAddLinkValidationErrors)
  const originalAnswers = {
    link,
    description,
    name,
  }
  const [addLinkForm, setAddLinkForm] = useState(originalAnswers)

  const handleClose = () => {
    handleModalClose()
    setAddLinkForm(originalAnswers)
  }

  const handleEditDocumentation = async () => {
    try {
      const formData = new FormData()
      formData.append('Name', addLinkForm.name)
      formData.append('Description', addLinkForm.description)
      formData.append('Link', addLinkForm.link)
      formData.append('IsDocument', 'False')

      await updateDocumentation({ knowledgeId, documentationId, data: formData }).unwrap()
      const successMessage = t('knowledge.view.documentation.messages.edit_success')

      handleModalClose()

      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleSave = async () => {
    const isFormValid = await validateForm(addLinkSchema, addLinkForm)
    if (isFormValid) {
      handleEditDocumentation()
    } else {
      const errors = await validateFields(addLinkSchema, addLinkForm)
      setValidationLinkErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.documentation.edit_modal_title')}
      handleClose={handleClose}
    >
      <KnowledgeDocumentationLinkForm
        addLinkForm={addLinkForm}
        setAddLinkForm={setAddLinkForm}
        validationErrors={validationLinkErrors}
        setValidationErrors={setValidationLinkErrors}
      />
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loadingIndicator={t('common.buttons.save_loading')}
          onClick={handleSave}
          loading={updateDocumentationLoading}
          variant='contained'
        >
          {t('common.buttons.save')}
        </LoadingButton>
        <Button variant='text' color='secondary' onClick={handleClose}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeDocumentationEditLinkModal
