import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, List, ListItem, Typography } from '@mui/material'
import BaseModal from '../../../../components/modal/BaseModal'
import InfoBox from '../../../../components/InfoBox'
import KnowledgeModalProps from '../../../../types/knowledge/view/KnowledgeModalProps'

function KnowledgeDocumentationExampleModal({ openModal, handleModalClose }: KnowledgeModalProps) {
  const { t } = useTranslation()
  const documentFormats = ['pdf', 'svg', 'jpeg', 'png']
  const linkExamples = ['youtube', 'vimeo', 'sharepoint', 'external']

  const closeModal = () => {
    handleModalClose()
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.documentation.example_modal.title')}
      handleClose={closeModal}
    >
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.view.documentation.example_modal.subtitle_description')}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='body2' component='p'>
            {t('knowledge.view.documentation.example_modal.description')}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.view.documentation.example_modal.subtitle_document_format')}
          </Typography>
          <Typography variant='body2' component='p'>
            <List sx={{ listStyleType: 'disc', pl: 3 }}>
              {documentFormats.map((format) => (
                <ListItem key={format} sx={{ display: 'list-item', lineHeight: '0.5em' }}>
                  {t(`knowledge.view.documentation.example_modal.example_format_${format}`)}
                </ListItem>
              ))}
            </List>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.view.documentation.example_modal.subtitle_document_link')}
          </Typography>
          <Typography variant='body2' component='p'>
            <List sx={{ listStyleType: 'disc', pl: 3 }}>
              {linkExamples.map((format) => (
                <ListItem key={format} sx={{ display: 'list-item', lineHeight: '0.5em' }}>
                  {t(`knowledge.view.documentation.example_modal.example_link_${format}`)}
                </ListItem>
              ))}
            </List>
          </Typography>
        </Grid>

        <Grid item>
          <InfoBox
            title={t('knowledge.view.documentation.example_modal.infobox_title')}
            text={t('knowledge.view.documentation.example_modal.infobox_description')}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default KnowledgeDocumentationExampleModal
