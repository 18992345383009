import React from 'react'
import { Box, Typography } from '@mui/material'
import NoteBoxProps from '../types/components/NoteBoxProps'
import flagIcon from '../assets/flag.svg'

function NoteBox({ title, text }: NoteBoxProps) {
  return (
    <Box padding={2} marginBottom={2} sx={{ bgcolor: 'primary.light' }}>
      <Box display='flex' alignItems='self-start' marginBottom={1}>
        <img src={flagIcon} alt='Important' />
        <Typography variant='subtitle2' component='p' marginLeft={1}>
          {title}
        </Typography>
      </Box>

      <Typography variant='body2' component='p'>
        {text}
      </Typography>
    </Box>
  )
}

export default NoteBox
