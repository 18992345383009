import React, { useState } from 'react'
import { t } from 'i18next'
import { Grid, Typography, Box, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import KnowledgeDocumentation from './KnowledgeDocumentation'
import KnowledgeDocumentationExampleModal from './KnowledgeDocumentationExampleModal'
import KnowledgeDocumentationModal from './KnowledgeDocumentationModal'
import BaseContainer from '../../../../components/BaseContainer'
import AlertMessage from '../../../../components/messages/AlertMessage'
import { KnowledgeDocumentationType } from '../../../../types/knowledge/view/KnowledgeType'
import KnowledgeDocumentsProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentsProps'
import AlertMessageType, { initAlertMessage } from '../../../../types/components/AlertMessageType'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'

function KnowledgeDocumentationContainer({ documents, userAccessLevel }: KnowledgeDocumentsProps) {
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [openExampleModal, setOpenExampleModal] = useState(false)
  const [openAddModal, setAddModal] = useState(false)

  const handleExampleOpen = () => {
    setOpenExampleModal(true)
  }

  const handleExampleClose = () => {
    setOpenExampleModal(false)
  }

  const handleAddOpen = () => {
    setAddModal(true)
  }

  const handleAddClose = () => {
    setAddModal(false)
  }

  return (
    <Grid>
      <Typography variant='h6' component='p' marginBottom={3}>
        {t('knowledge.view.documentation.title')}
      </Typography>
      <Typography variant='body2' component='p' marginBottom={2}>
        {t('knowledge.view.documentation.description')}
      </Typography>
      <Typography
        variant='body1'
        component='p'
        marginBottom={3}
        onClick={handleExampleOpen}
        sx={{
          textDecoration: 'underline',
          textUnderlineOffset: 4,
          cursor: 'pointer',
          textDecorationColor: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
        }}
      >
        {t('knowledge.view.documentation.see_examples')}
      </Typography>
      <Box>
        {documents && documents.length > 0 ? (
          documents.map((doc: KnowledgeDocumentationType) => {
            return (
              <KnowledgeDocumentation
                key={doc.id}
                id={doc.id}
                description={doc.description}
                isDocument={doc.isDocument}
                documentFileName={doc.documentFileName}
                documentMimeType={doc.documentMimeType}
                name={doc.name}
                link={doc.link}
                setPopupMessage={setPopupMessage}
                userAccessLevel={userAccessLevel}
              />
            )
          })
        ) : (
          <BaseContainer>
            <Typography variant='subtitle2' component='p'>
              {t('knowledge.view.documentation.empty')}
            </Typography>
          </BaseContainer>
        )}

        <RequireAccessLevel accessLevels={[OWNER, COLLABORATOR]} userAccessLevel={userAccessLevel}>
          <Button variant='contained' endIcon={<Add />} onClick={handleAddOpen}>
            {t('knowledge.view.buttons.add_documentation')}
          </Button>
        </RequireAccessLevel>
      </Box>

      <KnowledgeDocumentationExampleModal
        openModal={openExampleModal}
        handleModalClose={handleExampleClose}
      />

      <KnowledgeDocumentationModal
        handleModalClose={handleAddClose}
        openModal={openAddModal}
        setPopupMessage={setPopupMessage}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </Grid>
  )
}

export default KnowledgeDocumentationContainer
