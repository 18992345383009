import React from 'react'
import { Link } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MenuItemProps from '../../types/components/MenuItemProps'

export default function MenuItem({
  text,
  link,
  isSelected,
  icon,
  iconPosition,
  inset,
}: MenuItemProps) {
  return (
    <ListItemButton
      component={Link}
      to={link}
      selected={isSelected}
      sx={{
        '&.Mui-selected': { backgroundColor: (theme: any) => theme.shade.primary.shade30p }, // eslint-disable-line @typescript-eslint/no-explicit-any
        '&.Mui-selected:hover': { backgroundColor: (theme: any) => theme.shade.primary.shade30p }, // eslint-disable-line @typescript-eslint/no-explicit-any
      }}
    >
      {icon && iconPosition === 'left' ? <ListItemIcon>{icon}</ListItemIcon> : ''}
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'body2' }} inset={inset} />
      {icon && iconPosition !== 'left' ? <ListItemIcon>{icon}</ListItemIcon> : ''}
    </ListItemButton>
  )
}

MenuItem.defaultProps = {
  isSelected: false,
  icon: '',
  iconPosition: 'left',
  inset: false,
}
