import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, MenuItem, SelectChangeEvent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useGetManageableRolesQuery, useUserChangeRoleMutation } from '../usersApiSlice'
import BaseModal from '../../../components/modal/BaseModal'
import BaseSelect from '../../../components/forms/BaseSelect'
import UserChange from '../../../components/UserChange'
import UserRoleModalProps from '../../../types/users/edit/UserRoleModalProps'
import ManageableRolesType from '../../../types/users/ManageableRolesType'

function UserRoleModal({
  openModal,
  handleModalClose,
  setPopupMessage,
  userToEdit,
}: UserRoleModalProps) {
  const { i18n, t } = useTranslation()
  const { data: roles } = useGetManageableRolesQuery('Admin')
  const [changeRole, { isLoading: changeRoleLoading }] = useUserChangeRoleMutation()
  const [userRole, setUserRole] = useState('')
  const filteredRoles = roles && roles.filter((role: ManageableRolesType) => role.canManage)

  useEffect(() => {
    setUserRole(userToEdit.role.name)
  }, [userToEdit])

  const handleChange = (e: SelectChangeEvent) => {
    const { value } = e.target
    setUserRole(value)
  }

  const cancelForm = () => {
    setUserRole('')
    handleModalClose()
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    try {
      await changeRole({ userId: userToEdit.userId, roleName: userRole }).unwrap()
      const successMessage = t('settings.users.messages.role_edit_success')

      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      cancelForm()

      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('settings.users.form.edit_role')}
      handleClose={cancelForm}
    >
      <Box component='form' onSubmit={handleSubmit}>
        <UserChange user={userToEdit}>
          <BaseSelect
            label={t('settings.users.form.role_label')}
            labelId='role-label'
            id='role'
            name='role'
            value={userRole}
            handleChange={handleChange}
          >
            {filteredRoles &&
              filteredRoles.map((role: ManageableRolesType) => {
                const roleLabel = i18n.language === 'en' ? role.translation.en : role.translation.fr
                return (
                  <MenuItem value={`${role.name}`} key={role.name}>
                    {roleLabel}
                  </MenuItem>
                )
              })}
          </BaseSelect>
        </UserChange>

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={changeRoleLoading}
            loadingIndicator={t('common.buttons.edit_loading')}
          >
            {t('common.buttons.edit')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}

export default UserRoleModal
