import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import i18n from '../../../app/i18n'
import {
  useSendConfirmationEmailMutation,
  useUsersDisableMutation,
  useUsersListQuery,
} from '../usersApiSlice'
import UserSearch from './UserSearch'
import UsersTable from './UsersTable'
import AddUserModal from '../add/AddUserModal'
import UserRoleModal from '../edit/UserRoleModal'
import RequireRoles from '../../auth/RequireRoles'
import Breadcrumbs from '../../../components/Breadcrumbs'
import ConfirmDelete from '../../../components/messages/ConfirmDelete'
import AlertMessage from '../../../components/messages/AlertMessage'
import UserType, { initUser } from '../../../types/users/UserType'
import EmployeesWithKnowledgeToTransferType from '../../../types/components/EmployeesWithKnowledgeToTransferType'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import UsersListResponseType from '../../../types/users/list/UsersListResponseType'
import UserListFormattedType from '../../../types/users/list/UserFormattedType'
import { ADMIN, SUPERADMIN } from '../../../common/data/roleNames'
import KnowledgeTransferModal from '../../../components/modal/KnowledgeTransferModal'

function convertRoleName(array: UsersListResponseType[], isActive: string, isInactive: string) {
  const language = i18n.language === 'en' ? 'en' : 'fr'
  const list: UserListFormattedType[] = []
  if (array !== undefined) {
    array.forEach((item, index) => {
      const roleString = language === 'fr' ? item.role.translation.fr : item.role.translation.en
      const isActiveString = item.isActive ? isActive : isInactive

      list[index] = {
        userId: item.userId,
        fullName: item.fullName,
        firstName: item.firstName,
        lastName: item.lastName,
        role: roleString,
        nbrKnowledgeOwner: item.nbrKnowledgeOwner,
        isActive: isActiveString,
      }
    })
  }

  return list
}

function UsersList() {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<readonly string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [newSearchTerm, setNewSearchTerm] = useState('')
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openRoleModal, setOpenRoleModal] = useState(false)
  const [openKnowledgeTransferModal, setOpenKnowledgeTransferModal] = useState(false)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [userToEdit, setUserToEdit] = useState<UserType>(initUser)
  const [idsToDisable, setIdsToDisable] = useState<readonly string[]>([])
  const [usersToTransfer, setUsersToTransfer] = useState<EmployeesWithKnowledgeToTransferType[]>([])
  const { data: usersList } = useUsersListQuery({ term: searchTerm })
  const [sendConfirmationEmail] = useSendConfirmationEmailMutation()
  const [usersDisable] = useUsersDisableMutation()
  const usersData = convertRoleName(
    usersList,
    t('settings.users.active_status.active'),
    t('settings.users.active_status.on_hold'),
  )

  const handleAddModalOpen = () => {
    setOpenAddModal(true)
  }

  const handleAddModalClose = () => {
    setOpenAddModal(false)
  }

  const handleRoleModalOpen = () => {
    setOpenRoleModal(true)
  }

  const handleRoleModalClose = () => {
    setOpenRoleModal(false)
    setUserToEdit(initUser)
  }

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    setSearchTerm(newSearchTerm)
  }

  const handleUserToEdit = (id: string) => {
    setUserToEdit(usersList.find((user: UserType) => user.userId === id))
    handleRoleModalOpen()
  }

  const sendInvitation = async (id: string) => {
    const selectedUser = usersList.find((user: UserType) => user.userId === id)

    try {
      await sendConfirmationEmail({
        employeeId: selectedUser.employeeId,
        roleName: selectedUser.role.name,
      }).unwrap()
      const successMessage = t('settings.users.messages.send_invitation_success')

      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else if (err.status === 400) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('settings.users.messages.user_already_active'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleKnowledgeTransferModalClose = () => {
    setOpenKnowledgeTransferModal(false)
    setUsersToTransfer([])
  }

  const handleCancelDisable = () => {
    setIdsToDisable([])
  }

  const handleDisableMultiple = () => {
    if (selected.length > 0) {
      setIdsToDisable(selected)
    } else {
      setPopupMessage({
        type: 'warning',
        show: true,
        message: t('settings.users.messages.none_selected'),
      })
    }
  }

  const disableUsers = async (ids: readonly string[]) => {
    try {
      const count = ids.length
      const users = await usersDisable(ids).unwrap()

      if (users.length === 0) {
        setSelected([])
        setIdsToDisable([])
        setPopupMessage({
          type: 'success',
          show: true,
          message: t('settings.users.messages.disable_success', { count }),
        })
      } else {
        const usersWithoutKnowledge = ids.length - users.length

        if (usersWithoutKnowledge > 0) {
          setPopupMessage({
            type: 'success',
            show: true,
            message: t('settings.users.messages.disable_no_knowledge_success', {
              usersWithoutKnowledge,
            }),
          })
        }

        setUsersToTransfer(users)
        setOpenKnowledgeTransferModal(true)
      }

      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('settings.users.messages.disable_failed'),
        })
      }
    }
    handleCancelDisable()
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs />
        <Grid container sx={{ marginBottom: 7 }}>
          <Grid item sx={{ flex: 1 }}>
            <Typography component='h1' variant='h4'>
              {t('settings.users.page_title')}
            </Typography>
          </Grid>
          <RequireRoles roles={[SUPERADMIN, ADMIN]}>
            <Grid item>
              <Button variant='contained' endIcon={<Add />} onClick={handleAddModalOpen}>
                {t('settings.users.buttons.add')}
              </Button>
            </Grid>
          </RequireRoles>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <UserSearch
              newSearchTerm={newSearchTerm}
              setNewSearchTerm={setNewSearchTerm}
              handleSubmit={handleSubmit}
            />
          </Grid>
          <RequireRoles roles={[SUPERADMIN, ADMIN]}>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button
                variant='outlined'
                endIcon={<Delete />}
                color='secondary'
                onClick={handleDisableMultiple}
                sx={{ marginX: 2 }}
              >
                {t('settings.users.buttons.disable')}
              </Button>
            </Grid>
          </RequireRoles>
        </Grid>

        <UsersTable
          usersList={usersData}
          selected={selected}
          setSelected={setSelected}
          sendInvitation={sendInvitation}
          handleUserToEdit={handleUserToEdit}
          setIdsToDisable={setIdsToDisable}
        />
      </Box>

      <AddUserModal
        openModal={openAddModal}
        handleModalClose={handleAddModalClose}
        setPopupMessage={setPopupMessage}
      />

      <UserRoleModal
        openModal={openRoleModal}
        handleModalClose={handleRoleModalClose}
        setPopupMessage={setPopupMessage}
        userToEdit={userToEdit}
      />

      <KnowledgeTransferModal
        openModal={openKnowledgeTransferModal}
        handleModalClose={handleKnowledgeTransferModalClose}
        setPopupMessage={setPopupMessage}
        employeesToTransfer={usersToTransfer}
        title={t('common.transfer_knowledge_on_delete.users.title')}
        text={t('common.transfer_knowledge_on_delete.users.text')}
        cancelAlertText={t('settings.users.messages.users_with_knowledge_not_disabled')}
        successAlertText='settings.users.messages.disable_transfer_success'
        forUsers
      />

      <ConfirmDelete
        title='settings.users.messages.confirm_disable_title'
        text='settings.users.messages.confirm_disable_text'
        buttonText={t('common.buttons.disable')}
        idsToDelete={idsToDisable}
        onConfirm={disableUsers}
        onCancel={handleCancelDisable}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default UsersList
