import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, Typography } from '@mui/material'
import BaseModal from '../../../../components/modal/BaseModal'
import KnowledgeResultsModalProps from '../../../../types/knowledge/view/KnowledgeResultsModalProps'
import { KnowledgeCriticalitiesType } from '../../../../types/knowledge/view/KnowledgeType'

function KnowledgeResultsModal({
  criticalityQuestions,
  criticalityValue,
  openModal,
  handleModalClose,
}: KnowledgeResultsModalProps) {
  const { t } = useTranslation()
  const title = t('knowledge.view.results_overview.title')

  return (
    <BaseModal openModal={openModal} title={t(title)} handleClose={handleModalClose}>
      <Box marginTop={-2}>
        {criticalityQuestions.map((criticality: KnowledgeCriticalitiesType) => {
          return (
            <div key={criticality.translationKey}>
              <Grid container alignItems='center' marginTop={3} marginBottom={3}>
                <Grid item xs={9}>
                  <Typography variant='subtitle2' maxWidth='90%'>
                    {t(`knowledge.criticality_questions.${criticality.translationKey}.question`)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>{criticality.value}</Typography>
                </Grid>
              </Grid>
              <Divider light />
            </div>
          )
        })}

        <Divider
          sx={{
            bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
          }}
        />

        <Grid container marginTop={3} alignItems='center' display='flex' direction='row'>
          <Grid item xs={9}>
            <Typography variant='h6' component='span'>
              {t('knowledge.add.step_2.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6' component='span'>
              {criticalityValue}
            </Typography>
            <Typography variant='h6' component='span' sx={{ color: 'secondary.light' }}>
              /100
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  )
}

export default KnowledgeResultsModal
