import styled from '@emotion/styled'
import { TableCell } from '@mui/material'

const BaseTableCell = styled(TableCell)(
  // eslint-disable-next-line
  ({ theme }: any) => `
    border-color: ${theme.shade.secondary.shade12p};
  `,
)

export default BaseTableCell
