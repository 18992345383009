import React from 'react'
import { Grid, Typography, Divider, Button } from '@mui/material'
import { Edit } from '@mui/icons-material'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'
import OverviewRowProps from '../../../../types/knowledge/view/OverviewRowProps'

export default function OverviewRow({
  divider,
  title,
  modalLinkText,
  modalOpen,
  children,
  editButtonText,
  editButtonModalOpen,
  button2,
  userAccessLevel,
  accessLevels,
}: OverviewRowProps) {
  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={4}>
          <Typography variant='subtitle2' component='p'>
            {title}
          </Typography>
          {modalLinkText && (
            <Typography
              variant='body2'
              component='span'
              color='secondary'
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={modalOpen}
            >
              {modalLinkText}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4} display='flex' alignItems='center'>
          {children}
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems='flex-end' direction='column'>
            <Grid item>{button2}</Grid>
            <Grid item>
              <RequireAccessLevel accessLevels={accessLevels} userAccessLevel={userAccessLevel}>
                <Button
                  variant='text'
                  color='secondary'
                  endIcon={<Edit sx={{ color: 'primary.dark' }} />}
                  onClick={editButtonModalOpen}
                >
                  {editButtonText}
                </Button>
              </RequireAccessLevel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {divider && <Divider sx={{ marginBottom: 2, marginTop: 2 }} />}
    </>
  )
}

OverviewRow.defaultProps = {
  modalLinkText: '',
  modalOpen: '',
  button1: '',
  button2: '',
  divider: false,
}
