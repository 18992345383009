import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, Divider, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import BaseModal from '../../../../components/modal/BaseModal'
import { useUpdateKnowledgeCriticalityMutation } from '../../knowledgeApiSlice'
import BaseContainer from '../../../../components/BaseContainer'
import CriticalityQuestion from '../../add/stepTwo/CriticalityQuestion'
import { initValidationErrors } from '../../../../common/validations/addKnowledgeStepTwoSchema'
import {
  KnowledgeCriticalitiesQuestionTypeEdit,
  KnowledgeCriticalitiesTypeEdit,
} from '../../../../types/knowledge/view/KnowledgeCriticalitiesTypeEdit'
import KnowledgeChangeCriticalitiesModalProps from '../../../../types/knowledge/view/KnowledgeChangeCriticalitiesModalProps'
import CriticalityQuestionType from '../../../../types/knowledge/add/CriticalityQuestionType'

export default function KnowledgeChangeCriticalitiesModal({
  openModal,
  handleModalClose,
  criticalityQuestions,
  setPopupMessage,
}: KnowledgeChangeCriticalitiesModalProps) {
  const { t } = useTranslation()
  const { id: knowledgeId } = useParams()
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const [updateKnowledgeCriticality, { isLoading: knowledgeCriticalityLoading }] =
    useUpdateKnowledgeCriticalityMutation()
  const currentAnswers = criticalityQuestions.map((question) => {
    return {
      id: question.questionId,
      value: question.value,
      weight: question.weight,
      translationKey: question.translationKey,
    }
  })

  const [newAnswers, setNewAnswers] = useState(currentAnswers)

  const calculateCriticality = () => {
    let criticality = 0
    newAnswers.forEach((question) => {
      const value = question.weight * +question.value
      criticality += value
    })
    return criticality
  }

  const onConfirm: React.FormEventHandler = async () => {
    try {
      const updateQuestions: KnowledgeCriticalitiesQuestionTypeEdit[] = newAnswers.map((q) => {
        return {
          questionId: q.id,
          value: q.value,
        }
      })

      const updateCriticality: KnowledgeCriticalitiesTypeEdit = {
        criticality: calculateCriticality(),
        knowledgeCriticalities: updateQuestions,
      }

      await updateKnowledgeCriticality({
        id: knowledgeId,
        data: updateCriticality,
      }).unwrap()

      const successMessage = t('knowledge.view.overview.criticality_change_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      handleModalClose()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleQuestionChange = (id: string, value: string) => {
    const newArray = newAnswers
    const questionIndex = newArray.findIndex((x) => x.id === id)
    newArray[questionIndex].value = +value
    setNewAnswers(newArray)
  }

  const closeModal = () => {
    handleModalClose()
    setNewAnswers(currentAnswers)
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.results_overview.title')}
      handleClose={closeModal}
    >
      <BaseContainer>
        {newAnswers &&
          newAnswers.map((question: CriticalityQuestionType, index: number) => {
            return (
              <Box key={question.id}>
                {index > 0 && <Divider sx={{ marginTop: 1.2, marginBottom: 4 }} />}
                <CriticalityQuestion
                  index={index}
                  question={question}
                  selectedValue={question.value.toString()}
                  handleQuestionChange={handleQuestionChange}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </Box>
            )
          })}
      </BaseContainer>

      <Typography variant='caption' component='p'>
        * {t('knowledge.add.mandatory_fields')}
      </Typography>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loading={knowledgeCriticalityLoading}
          loadingIndicator={t('common.buttons.save_loading')}
          variant='contained'
          onClick={onConfirm}
        >
          {t('common.buttons.save')}
        </LoadingButton>
        <Button variant='text' color='secondary' autoFocus onClick={handleModalClose}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}
