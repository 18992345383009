import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import BaseModal from '../../../../components/modal/BaseModal'
import StatusBox from '../../../../components/StatusBox'
import KnowledgeModalProps from '../../../../types/knowledge/view/KnowledgeModalProps'
import knowledgeStatusNames from '../../../../common/data/knowledgeStatusNames'

export default function KnowledgeStatusModal({ openModal, handleModalClose }: KnowledgeModalProps) {
  const { t } = useTranslation()
  const status = knowledgeStatusNames

  const closeModal = () => {
    handleModalClose()
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.status_question')}
      handleClose={closeModal}
    >
      <Grid item xs={4} display='flex' alignItems='center'>
        <StatusBox color={status[0].color} />
        <Typography variant='body2' component='span'>
          {t(`knowledge.status.${status[0].label}`)}
        </Typography>
      </Grid>
      <Typography variant='body2' component='p' sx={{ my: 2 }} align='justify'>
        {t(`knowledge.view.overview.status_description.${status[0].label}`)}
      </Typography>

      <Grid item xs={4} display='flex' alignItems='center'>
        <StatusBox color={status[1].color} />
        <Typography variant='body2' component='span'>
          {t(`knowledge.status.${status[1].label}`)}
        </Typography>
      </Grid>
      <Typography variant='body2' component='p' sx={{ my: 2 }} align='justify'>
        {t(`knowledge.view.overview.status_description.${status[1].label}`)}
      </Typography>

      <Grid item xs={4} display='flex' alignItems='center'>
        <StatusBox color={status[2].color} />
        <Typography variant='body2' component='span'>
          {t(`knowledge.status.${status[2].label}`)}
        </Typography>
      </Grid>
      <Typography variant='body2' component='p' sx={{ my: 2 }} align='justify'>
        {t(`knowledge.view.overview.status_description.${status[2].label}`)}
      </Typography>

      <Grid item xs={4} display='flex' alignItems='center'>
        <StatusBox color={status[3].color} />
        <Typography variant='body2' component='span'>
          {t(`knowledge.status.${status[3].label}`)}
        </Typography>
      </Grid>
      <Typography variant='body2' component='p' sx={{ my: 2 }} align='justify'>
        {t(`knowledge.view.overview.status_description.${status[3].label}`)}
      </Typography>
    </BaseModal>
  )
}
