import React from 'react'
import { Box, Radio, Typography } from '@mui/material'
import CriticalityQuestionValuesProps from '../../../../types/knowledge/add/CriticalityQuestionValuesProps'

function CriticalityQuestionValues({
  selectedValue,
  handleChange,
  validationError,
}: CriticalityQuestionValuesProps) {
  const answers = ['1', '2', '3', '4']

  return (
    <Box display='flex' justifyContent='space-between'>
      {answers.map((answer) => {
        return (
          <Box key={answer}>
            <Typography variant='body1' component='p' color='secondary' paddingX={2}>
              {answer}
            </Typography>
            <Radio
              checked={selectedValue === answer}
              onChange={handleChange}
              value={answer}
              name={`question${answer}`}
              inputProps={{ 'aria-label': `${answer}` }}
              sx={{
                '&.MuiButtonBase-root.MuiRadio-root': {
                  color: validationError && '#d32f2f',
                },
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default CriticalityQuestionValues
