import React from 'react'
import { Box, DialogTitle, Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import BaseModalTitleProps from '../../types/components/BaseModalTitleProps'

function BaseModalTitle({ children, onClose, ...other }: BaseModalTitleProps) {
  return (
    <DialogTitle color='secondary' sx={{ m: 0, paddingTop: 7, paddingX: 5 }} {...other}>
      <Box maxWidth='95%'>{children}</Box>
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 40,
            top: 56,
            padding: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Divider sx={{ paddingTop: 1 }} />
    </DialogTitle>
  )
}

export default BaseModalTitle
