import React from 'react'
import { UploadFile } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'
import calculateFileSize from '../../../common/utils/calculateFileSize'
import UploadedFileProps from '../../../types/components/UploadedFileProps'

function UploadedFile({ uploadedFile }: UploadedFileProps) {
  return (
    <Box
      key={uploadedFile.name}
      sx={{
        position: 'relative',
        marginTop: 4,
      }}
    >
      <Box display='flex'>
        <Avatar
          sx={{
            bgcolor: (theme: any) => theme.shade.primary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
          }}
        >
          <UploadFile
            sx={{
              color: 'primary.dark',
            }}
          />
        </Avatar>
        <Box sx={{ ml: 2 }}>
          <Typography variant='subtitle1' component='p'>
            {uploadedFile.name}
          </Typography>
          <Typography
            variant='body2'
            component='p'
            sx={{
              opacity: 0.6,
            }}
          >
            {uploadedFile.size > 0 ? calculateFileSize(uploadedFile.size) : null}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default UploadedFile
