import { object, ref, string } from 'yup'
import PASSWORD_VALIDATION from '../data/passwordValidation'

const passwordResetSchema = object().shape({
  password: string().required().matches(PASSWORD_VALIDATION),
  passwordConfirm: string().oneOf([ref('password')]),
})

export const initValidationErrors = {
  password: false,
  passwordConfirm: false,
}

export default passwordResetSchema
