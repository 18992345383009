import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MenuItem, Typography } from '@mui/material'
import apiSlice from '../../app/api/apiSlice'
import { logout } from './authSlice'

export default function LogoutButton() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
    dispatch(apiSlice.util.resetApiState())
    localStorage.removeItem('refresh_token')
    sessionStorage.removeItem('refresh_token')
    navigate('/login')
  }

  return (
    <MenuItem onClick={handleLogout}>
      <Typography variant='body2'>{t('header.menu.logout')}</Typography>
    </MenuItem>
  )
}
