export default interface KnowledgeFiltersType {
  searchTerm: string
  position: string
  status: string
  owner: string
}

export const initKnowledgeFilters = {
  searchTerm: '',
  position: '',
  status: '',
  owner: '',
}
