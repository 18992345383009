import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateKnowledgeNameMutation } from '../knowledgeApiSlice'
import BaseModal from '../../../components/modal/BaseModal'
import ChangeNameModalProps from '../../../types/knowledge/view/ChangeNameModalProps'
import validateForm from '../../../common/validations/validateForm'
import validateFields from '../../../common/validations/validateFields'
import knowledgeNameSchema, {
  initValidationErrors,
} from '../../../common/validations/knowledgeNameSchema'

export default function ChangeNameModal({
  openModal,
  setOpenModal,
  currentName,
  setPopupMessage,
}: ChangeNameModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [newName, setNewName] = useState({ name: currentName })
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const [changeName, { isLoading: changeNameLoading }] = useUpdateKnowledgeNameMutation()

  const cancelForm = () => {
    setOpenModal(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setNewName({ name: value })
    setValidationErrors({ name: false })
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(knowledgeNameSchema, newName)

    if (isFormValid) {
      try {
        await changeName({ id, data: newName }).unwrap()
        const successMessage = t('knowledge.view.messages.name_change_success')

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        cancelForm()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(knowledgeNameSchema, newName)
      setValidationErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.status_change')}
      handleClose={cancelForm}
    >
      <Box component='form'>
        <TextField
          fullWidth
          type='text'
          name='firstName'
          id='firstName'
          label={t('organization.employees.form.first_name')}
          value={newName.name}
          onChange={handleChange}
          inputProps={{ maxLength: 450 }}
          error={validationErrors.name}
          helperText={validationErrors.name ? t('errors.forms.mandatory_field') : ''}
        />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={changeNameLoading}
            loadingIndicator={t('common.buttons.save_loading')}
            variant='contained'
            onClick={handleSubmit}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
