import React from 'react'
import { Button, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import ChangeNameButtonProps from '../../../types/knowledge/view/ChangeNameButtonProps'

function ChangeNameButton({ knowledgeName, setOpenModal }: ChangeNameButtonProps) {
  return (
    <Button
      variant='text'
      color='secondary'
      endIcon={<Edit />}
      onClick={() => setOpenModal(true)}
      sx={{
        padding: 0,
        textAlign: 'left',
        '&:hover': {
          backgroundColor: 'transparent',
          '&.MuiButton-text': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      <Typography component='h1' variant='h4'>
        {knowledgeName}
      </Typography>
    </Button>
  )
}

export default ChangeNameButton
