import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField, Typography } from '@mui/material'
import KnowledgeDocumentationAddLinkProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationAddLinkProps'

function KnowledgeDocumentationLinkForm({
  addLinkForm,
  setAddLinkForm,
  validationErrors,
  setValidationErrors,
}: KnowledgeDocumentationAddLinkProps) {
  const { t } = useTranslation()
  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddLinkForm({ ...addLinkForm, link: event.target.value })
    setValidationErrors({ ...validationErrors, link: false })
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddLinkForm({ ...addLinkForm, name: event.target.value })
    setValidationErrors({ ...validationErrors, name: false })
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddLinkForm({ ...addLinkForm, description: event.target.value })
    setValidationErrors({ ...validationErrors, description: false })
  }

  return (
    <Grid>
      <TextField
        fullWidth
        type='text'
        value={addLinkForm.link}
        onChange={handleLinkChange}
        label={t('knowledge.view.documentation.link_type')}
        error={validationErrors.link}
        helperText={validationErrors.link ? t('errors.forms.invalid_website') : ''}
        sx={{ marginBottom: 3 }}
      />

      <Typography variant='h6' component='p' marginBottom={2}>
        {t('knowledge.view.documentation.add_modal.link_information')}
      </Typography>

      <TextField
        fullWidth
        type='text'
        onChange={handleNameChange}
        value={addLinkForm.name}
        error={validationErrors.name}
        helperText={validationErrors.name ? t('errors.forms.mandatory_field') : ''}
        label={t('knowledge.view.documentation.add_modal.link_name')}
        sx={{ marginBottom: 1 }}
      />
      <TextField
        fullWidth
        type='text'
        multiline
        rows={4}
        value={addLinkForm.description}
        error={validationErrors.description}
        helperText={validationErrors.description ? t('errors.forms.mandatory_field') : ''}
        onChange={handleDescriptionChange}
        label={t('knowledge.view.documentation.add_modal.description')}
        sx={{ marginBottom: 1 }}
      />
    </Grid>
  )
}

export default KnowledgeDocumentationLinkForm
