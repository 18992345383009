import { AlertColor } from '@mui/material'

export default interface AlertMessageType {
  type: AlertColor | undefined
  show: boolean
  message: string
}

export const initAlertMessage = {
  type: undefined,
  show: false,
  message: '',
}
