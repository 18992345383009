const getInitials = (firstName: string, lastName: string) => {
  let firstInitial = ''
  let lastInitial = ''

  if (firstName !== '') {
    firstInitial = `${firstName.trim()[0]}`
  }
  if (lastName !== '') {
    lastInitial = `${lastName.trim()[0]}`
  }
  return `${firstInitial}${lastInitial}`
}

export default getInitials
