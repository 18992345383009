import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDeleteKnowledgeKeyResourceMutation } from '../../knowledgeApiSlice'
import BaseModal from '../../../../components/modal/BaseModal'
import KnowledgeKeyResourceDeleteModalProps from '../../../../types/knowledge/view/KnowledgeKeyResourceDeleteModalProps'

function KnowledgeKeyResourceDeleteModal({
  openModal: open,
  title,
  text,
  onCancel,
  employeeId,
  setPopupMessage,
}: KnowledgeKeyResourceDeleteModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [deleteKeyResource, { isLoading: deleteKeyResourceLoading }] =
    useDeleteKnowledgeKeyResourceMutation()

  const onConfirm: React.FormEventHandler = async () => {
    try {
      await deleteKeyResource({
        id,
        employeeId,
      }).unwrap()

      const successMessage = t('knowledge.view.key_resource.messages.delete_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      onCancel()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.delete_failed'),
        })
      }
    }
  }

  return (
    <BaseModal openModal={open} title={t(title)} handleClose={onCancel}>
      <Typography variant='body1'>{t(text)}</Typography>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loading={deleteKeyResourceLoading}
          loadingIndicator={t('common.buttons.delete_loading')}
          variant='contained'
          onClick={onConfirm}
        >
          {t('common.buttons.delete')}
        </LoadingButton>
        <Button variant='text' color='secondary' autoFocus onClick={onCancel}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeKeyResourceDeleteModal
