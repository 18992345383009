import React from 'react'
import { Radio, Typography } from '@mui/material'
import { t } from 'i18next'
import KnowledgeDocumentationRadioProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationRadioProps'
import { DOCUMENT_TYPE } from '../../../../common/data/knowledgeDocumentationType'

function KnowledgeDocumentationRadioButton({
  type,
  selectedType,
  handleChange,
}: KnowledgeDocumentationRadioProps) {
  const RADIO_LABEL =
    type === DOCUMENT_TYPE
      ? t('knowledge.view.documentation.add_modal.document_type')
      : t('knowledge.view.documentation.add_modal.link_type')

  return (
    <>
      <Radio checked={type === selectedType} value={type} onChange={handleChange} />
      <Typography variant='body1' component='span'>
        {RADIO_LABEL}
      </Typography>
    </>
  )
}

export default KnowledgeDocumentationRadioButton
