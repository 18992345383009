import React from 'react'
import { Typography } from '@mui/material'
import EmptyAnswerProps from '../types/components/EmptyAnswerProps'

function EmptyAnswer({ color }: EmptyAnswerProps) {
  return (
    <Typography variant='body2' component='p' color={color}>
      ―
    </Typography>
  )
}

export default EmptyAnswer

EmptyAnswer.defaultProps = {
  color: 'secondary.light',
}
