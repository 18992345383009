/* eslint-disable @typescript-eslint/no-explicit-any */
const validateFields = async (formSchema: any, formValues: any) => {
  let errors

  try {
    await formSchema.validate(formValues, { abortEarly: false })
  } catch (err: any) {
    errors = err.inner.reduce((acc: object, error: any) => {
      return {
        ...acc,
        [error.path]: true,
      }
    }, {})
  }

  return errors
}

export default validateFields
