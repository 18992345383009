import React from 'react'
import { useTranslation } from 'react-i18next'
import { List } from '@mui/material'
import {
  AutoAwesomeMosaicOutlined,
  CorporateFareOutlined,
  PlaylistAddOutlined,
  SettingsOutlined,
} from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuItemCollapse from './MenuItemCollapse'
import IsCurrentPath from '../../common/utils/IsCurrentPath'
import RequireRoles from '../../features/auth/RequireRoles'
import { ADMIN, EXPERT, ORGADMIN, SUPERADMIN } from '../../common/data/roleNames'

export default function PrimaryMenu() {
  const { t } = useTranslation()

  return (
    <nav aria-label='main mailbox folders' style={{ flex: 1 }}>
      <List
        sx={{
          paddingTop: 4,
          [`& .MuiButtonBase-root`]: { paddingX: 1 },
        }}
      >
        <RequireRoles roles={[ADMIN, SUPERADMIN]}>
          <MenuItem
            text={t('side_menu.dashboard')}
            link='/'
            isSelected={IsCurrentPath(['/'])}
            icon={<AutoAwesomeMosaicOutlined />}
          />
        </RequireRoles>

        <RequireRoles roles={[EXPERT, ADMIN, SUPERADMIN]}>
          <MenuItem
            text={t('side_menu.knowledge')}
            link='/knowledge'
            isSelected={IsCurrentPath(['/knowledge', '/knowledge/add'])}
            icon={<PlaylistAddOutlined />}
          />

          <MenuItemCollapse
            text={t('side_menu.organization.organization')}
            icon={<CorporateFareOutlined />}
            isOpen={IsCurrentPath(['/organization/employees', '/organization/profile'])}
          >
            <RequireRoles roles={[ADMIN, SUPERADMIN]}>
              <MenuItem
                text={t('side_menu.organization.employees')}
                link='/organization/employees'
                isSelected={IsCurrentPath(['/organization/employees'])}
                inset
              />
            </RequireRoles>

            <MenuItem
              text={t('side_menu.organization.profile')}
              link='/organization/profile'
              isSelected={IsCurrentPath(['/organization/profile'])}
              inset
            />
          </MenuItemCollapse>
        </RequireRoles>

        <RequireRoles roles={[ADMIN, SUPERADMIN]}>
          <MenuItemCollapse
            text={t('side_menu.settings.settings')}
            icon={<SettingsOutlined />}
            isOpen={IsCurrentPath(['/settings/users'])}
          >
            <MenuItem
              text={t('side_menu.settings.users')}
              link='/settings/users'
              isSelected={IsCurrentPath(['/settings/users'])}
              inset
            />
          </MenuItemCollapse>
        </RequireRoles>

        <RequireRoles roles={[ORGADMIN]}>
          <MenuItem
            text={t('side_menu.organization.list')}
            link='/organization/list'
            icon={<CorporateFareOutlined />}
            isSelected={IsCurrentPath(['/organization/list'])}
          />
        </RequireRoles>
      </List>
    </nav>
  )
}
