import React from 'react'
import { ListItemButton, ListItemText } from '@mui/material'
import MenuItemActionProps from '../../types/components/MenuItemActionProps'

export default function MenuItemAction({ text, onClick }: MenuItemActionProps) {
  return (
    <ListItemButton onClick={() => onClick()}>
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'body2' }} />
    </ListItemButton>
  )
}
