import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import AlertMessageProps from '../../types/components/AlertMessageProps'

function AlertMessage({ message, setMessage }: AlertMessageProps) {
  const vertical = 'top'
  const horizontal = 'center'
  const autoHideDurationInMilliseconds = message.type === 'error' ? 15000 : 5000

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setMessage({
      ...message,
      show: false,
    })
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={message.show}
      autoHideDuration={autoHideDurationInMilliseconds}
      onClose={handleClose}
    >
      <Alert severity={message.type} onClose={handleClose}>
        {message.message}
      </Alert>
    </Snackbar>
  )
}

export default AlertMessage
