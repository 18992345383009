import React from 'react'
import { Dialog, DialogContent, styled } from '@mui/material'
import BaseModalTitle from './BaseModalTitle'
import BaseModalProps from '../../types/components/BaseModalProps'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    paddingTop: theme.spacing(5),
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
  },
}))

function BaseModal({ openModal, title, children, handleClose }: BaseModalProps) {
  const id = title.replaceAll(' ', '-')

  return (
    <StyledDialog
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby={id}
      open={openModal}
    >
      <BaseModalTitle id={id} onClose={handleClose}>
        {title}
      </BaseModalTitle>
      <DialogContent sx={{ paddingTop: 8 }}>{children}</DialogContent>
    </StyledDialog>
  )
}

export default BaseModal
