import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormHelperText, Grid, Typography } from '@mui/material'
import CriticalityDescriptionModal from './CriticalityDescriptionModal'
import CriticalityQuestionProps from '../../../../types/knowledge/add/CriticalityQuestionProps'
import CriticalityQuestionValues from './CriticalityQuestionValues'

function CriticalityQuestion({
  index,
  question,
  selectedValue,
  handleQuestionChange,
  validationErrors,
  setValidationErrors,
}: CriticalityQuestionProps) {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const validationError = validationErrors[`questions[${index}].value`]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationErrors({ ...validationErrors, [`questions[${index}].value`]: false })
    handleQuestionChange(question.id, event.target.value)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant='subtitle2' component='p'>
            {t(`knowledge.criticality_questions.${question.translationKey}.question`)}*
          </Typography>

          <Typography
            variant='body2'
            component='p'
            sx={{ textDecoration: 'underline', color: 'secondary.main', cursor: 'pointer' }}
            onClick={() => setOpenModal(true)}
          >
            {t('knowledge.add.step_2.read_example')}
          </Typography>
        </Grid>
        <Grid item xs={6} paddingRight={4}>
          <CriticalityQuestionValues
            selectedValue={selectedValue}
            handleChange={handleChange}
            validationError={validationError}
          />

          <Box display='flex' justifyContent='space-between'>
            <Typography variant='body2' component='p' maxWidth={120}>
              {t(`knowledge.criticality_questions.${question.translationKey}.answer_low`)}
            </Typography>
            <Typography variant='body2' component='p' maxWidth={120} textAlign='right'>
              {t(`knowledge.criticality_questions.${question.translationKey}.answer_high`)}
            </Typography>
          </Box>
          <FormHelperText error>
            {validationError && t('errors.forms.mandatory_value')}
          </FormHelperText>
        </Grid>
      </Grid>

      <CriticalityDescriptionModal
        open={openModal}
        setOpen={setOpenModal}
        labelKey={question.translationKey}
      />
    </>
  )
}

export default CriticalityQuestion
