import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { Edit, Image } from '@mui/icons-material'
import RequireRoles from '../auth/RequireRoles'
import BaseContainer from '../../components/BaseContainer'
import LogoContainer from '../../components/LogoContainer'
import OrganizationDetailsProps from '../../types/organizations/OrganizationDetailsProps'
import { SUPERADMIN } from '../../common/data/roleNames'
import UnderlineGrid from '../../components/UnderlineGrid'

function OrganizationDetails({ organizationProfile, setEditMode }: OrganizationDetailsProps) {
  const { t } = useTranslation()

  return (
    <>
      <BaseContainer>
        {organizationProfile && (
          <Grid container>
            <Grid item xs={8} paddingRight={9}>
              <UnderlineGrid container>
                <Grid item xs={5}>
                  <Typography variant='subtitle2' component='p'>
                    {t('organization.profile.form.name')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant='body2' component='p'>
                    {organizationProfile.name}
                  </Typography>
                </Grid>
              </UnderlineGrid>
              <UnderlineGrid container>
                <Grid item xs={5}>
                  <Typography variant='subtitle2' component='p'>
                    {t('organization.profile.form.address')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant='body2' component='p'>
                    {organizationProfile.address} #{organizationProfile.suite}
                    <br />
                    {organizationProfile.city}, {organizationProfile.province}
                    <br />
                    {organizationProfile.postalCode}
                  </Typography>
                </Grid>
              </UnderlineGrid>
              <UnderlineGrid container>
                <Grid item xs={5}>
                  <Typography variant='subtitle2' component='p'>
                    {t('organization.profile.form.phone')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant='body2' component='p'>
                    {organizationProfile.phone}
                  </Typography>
                </Grid>
              </UnderlineGrid>
              <UnderlineGrid container>
                <Grid item xs={5}>
                  <Typography variant='subtitle2' component='p'>
                    {t('organization.profile.form.email')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant='body2' component='p'>
                    {organizationProfile.email}
                  </Typography>
                </Grid>
              </UnderlineGrid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography variant='subtitle2' component='p'>
                    {t('organization.profile.form.website')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant='body2' component='p'>
                    <a
                      href={organizationProfile.websiteUrl}
                      className='externalLink'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {organizationProfile.websiteUrl}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='p' marginBottom={2}>
                {t('organization.profile.form.logo')}
              </Typography>

              <LogoContainer>
                {organizationProfile.logo ? (
                  <img
                    src={`data:image/jpeg;base64,${organizationProfile.logo}`}
                    alt='Logo'
                    width='100%'
                  />
                ) : (
                  <Image
                    fontSize='inherit'
                    sx={{
                      color: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
                    }}
                  />
                )}
              </LogoContainer>
            </Grid>
          </Grid>
        )}
      </BaseContainer>

      <RequireRoles roles={[SUPERADMIN]}>
        <Button variant='contained' endIcon={<Edit />} onClick={() => setEditMode(true)}>
          {t('common.buttons.edit')}
        </Button>
      </RequireRoles>
    </>
  )
}

export default OrganizationDetails
