import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { selectCurrentCompanyId } from '../auth/authSlice'
import { useOrganizationProfileQuery } from './organizationsApiSlice'
import AlertMessage from '../../components/messages/AlertMessage'
import Breadcrumbs from '../../components/Breadcrumbs'
import OrganizationDetails from './OrganizationDetails'
import OrganizationEditDetails from './OrganizationEditDetails'
import AlertMessageType, { initAlertMessage } from '../../types/components/AlertMessageType'

function OrganizationProfile() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [editMode, setEditMode] = useState<boolean>(false)
  const companyId = useSelector(selectCurrentCompanyId)
  const breadcrumbsText = id ? t('organization.profile.buttons.back') : ''
  const breadcrumbsRoute = id ? '/organization/list' : ''
  const { data: organizationProfile } = useOrganizationProfileQuery(id || companyId)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)

  useEffect(() => {
    if (id && organizationProfile) {
      setEditMode(true)
    }
  }, [id, organizationProfile])

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs text={breadcrumbsText} route={breadcrumbsRoute} />
        <Box sx={{ marginBottom: 5 }}>
          <Typography component='h1' variant='h4'>
            {t('organization.profile.page_title')}
          </Typography>
        </Box>

        <Box>
          <Typography variant='h6' component='p' marginBottom={3}>
            {t('organization.profile.general_info')}
          </Typography>
        </Box>

        {!editMode ? (
          <OrganizationDetails
            organizationProfile={organizationProfile}
            setEditMode={setEditMode}
          />
        ) : (
          <OrganizationEditDetails
            organizationProfile={organizationProfile}
            setEditMode={setEditMode}
            setPopupMessage={setPopupMessage}
          />
        )}
      </Box>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default OrganizationProfile
