import React, { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useOrganizationAddMutation } from './organizationsApiSlice'
import OrganizationForm from './OrganizationForm'
import EmployeeForm from '../employees/EmployeeForm'
import BaseModal from '../../components/modal/BaseModal'
import OrganizationModalProps from '../../types/organizations/OrganizationModalProps'
import OrganizationProfileType, {
  initOrganizationProfile,
} from '../../types/organizations/OrganizationProfileType'
import EmployeeType, { initEmployee } from '../../types/employees/EmployeeType'
import organizationSchema, {
  initValidationErrors as initOrganizationValidationErrors,
} from '../../common/validations/organizationSchema'
import employeeSchema, {
  initValidationErrors as initEmployeeValidationErrors,
} from '../../common/validations/employeeSchema'
import validateForm from '../../common/validations/validateForm'
import validateFields from '../../common/validations/validateFields'

export default function OrganizationModal({
  openModal,
  handleModalClose,
  setPopupMessage,
}: OrganizationModalProps) {
  const { t } = useTranslation()
  const organizationRef = createRef<HTMLDivElement>()
  const employeeRef = createRef<HTMLDivElement>()
  const [organization, setOrganization] = useState<OrganizationProfileType>(initOrganizationProfile)
  const [employee, setEmployee] = useState<EmployeeType>(initEmployee)
  const [organizationValidationErrors, setOrganizationValidationErrors] = useState(
    initOrganizationValidationErrors,
  )
  const [employeeValidationErrors, setEmployeeValidationErrors] = useState(
    initEmployeeValidationErrors,
  )
  const [employeeEmailNotAvailable, setEmployeeEmailNotAvailable] = useState(false)
  const [organizationAdd, { isLoading: organizationAddLoading }] = useOrganizationAddMutation()

  const cancelForm = () => {
    setOrganization(initOrganizationProfile)
    setEmployee(initEmployee)
    setOrganizationValidationErrors(initOrganizationValidationErrors)
    setEmployeeValidationErrors(initEmployeeValidationErrors)
    setEmployeeEmailNotAvailable(false)
    handleModalClose()
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isOrganizationFormValid = await validateForm(organizationSchema, organization)
    const isEmployeeFormValid = await validateForm(employeeSchema, employee)

    if (isOrganizationFormValid && isEmployeeFormValid) {
      try {
        const formData = new FormData()

        formData.append('Company.Name', organization.name)
        formData.append('Company.Address', organization.address)
        formData.append('Company.Suite', organization.suite)
        formData.append('Company.City', organization.city)
        formData.append('Company.Province', organization.province)
        formData.append('Company.PostalCode', organization.postalCode)
        formData.append('Company.Phone', organization.phone)
        formData.append('Company.Email', organization.email)
        formData.append('Company.WebsiteUrl', organization.websiteUrl)
        formData.append('Logo', organization.logo)
        formData.append('Employee.FirstName', employee.firstName)
        formData.append('Employee.LastName', employee.lastName)
        formData.append('Employee.Position', employee.position)
        formData.append('Employee.Email', employee.email)

        await organizationAdd(formData).unwrap()
        const successMessage = t('organization.profile.messages.add_success')

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        cancelForm()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else if (err.status === 400) {
          setEmployeeEmailNotAvailable(true)
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      if (!isOrganizationFormValid) {
        const organizationErrors = await validateFields(organizationSchema, organization)
        setOrganizationValidationErrors(organizationErrors)
        if (organizationRef.current) {
          organizationRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        }
      }

      if (!isEmployeeFormValid) {
        const employeeErrors = await validateFields(employeeSchema, employee)
        setEmployeeValidationErrors(employeeErrors)
        if (employeeRef.current) {
          employeeRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        }
      }
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('organization.list.form.add_organization')}
      handleClose={cancelForm}
    >
      <Box component='form' onSubmit={handleSubmit}>
        <div ref={organizationRef}>
          <OrganizationForm
            organization={organization}
            setOrganization={setOrganization}
            validationErrors={organizationValidationErrors}
            setValidationErrors={setOrganizationValidationErrors}
          />
        </div>

        <div ref={employeeRef}>
          <Typography variant='h6' component='p' marginY={3}>
            {t('organization.profile.form.employee_title')}
          </Typography>

          <EmployeeForm
            employee={employee}
            setEmployee={setEmployee}
            validationErrors={employeeValidationErrors}
            setValidationErrors={setEmployeeValidationErrors}
            employeeEmailNotAvailable={employeeEmailNotAvailable}
            setEmployeeEmailNotAvailable={setEmployeeEmailNotAvailable}
          />
        </div>

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={organizationAddLoading}
            loadingIndicator={t('common.buttons.save_loading')}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
