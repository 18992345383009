import { object, string } from 'yup'

const addLinkSchema = object().shape({
  link: string().url().required(),
  name: string().required(),
  description: string().required(),
})

export const initAddLinkValidationErrors = {
  link: false,
  name: false,
  description: false,
}

export default addLinkSchema
