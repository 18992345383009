import { array, object, string } from 'yup'

const addKnowledgeStepTwoSchema = object().shape({
  questions: array().of(object().shape({ id: string().required(), value: string().required() })),
})

export const initValidationErrors = {
  questions: {},
}

export default addKnowledgeStepTwoSchema
