import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BaseContainer from '../components/BaseContainer'
import {
  selectCurrentEmail,
  selectCurrentFirstName,
  selectCurrentLastName,
  selectCurrentPosition,
  selectCurrentRole,
} from './auth/authSlice'

function Profile() {
  const { t } = useTranslation()
  const userFirstName = useSelector(selectCurrentFirstName)
  const userLastName = useSelector(selectCurrentLastName)
  const userFullname = `${userFirstName} ${userLastName}`
  const userPosition = useSelector(selectCurrentPosition)
  const userEmail = useSelector(selectCurrentEmail)
  const userRole = useSelector(selectCurrentRole)

  const userInfo = [
    { section: 'fullname', value: userFullname },
    { section: 'position', value: userPosition },
    { section: 'email', value: userEmail },
    { section: 'role', value: userRole.name },
  ]
  const userInfoSize = userInfo.length - 1

  return (
    <>
      <Typography component='h1' variant='h4' marginTop={10}>
        {t('user.profile.page_title')}
      </Typography>
      <Typography component='h1' variant='h6' marginTop={4}>
        {t('user.profile.general_info')}
      </Typography>

      <BaseContainer marginTop={4} width='50%'>
        {userInfo.map((info, index) => {
          return (
            <div key={info.section}>
              <Grid container alignItems='center' marginBottom={index === userInfoSize ? 0 : 2}>
                <Grid item xs={5}>
                  <Typography component='p' variant='subtitle2'>
                    {t(`user.profile.${info.section}`)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component='p' variant='body2'>
                    {info.value}
                  </Typography>
                </Grid>
              </Grid>
              {index === userInfoSize ? null : <Divider sx={{ marginBottom: 2 }} />}
            </div>
          )
        })}
      </BaseContainer>
    </>
  )
}

export default Profile
