import React from 'react'
import { Avatar } from '@mui/material'
import AvatarProps from '../types/components/AvatarProps'
import getInitials from '../common/utils/getInitials'

function AvatarRegular({ firstName, lastName, fullName, sx }: AvatarProps) {
  const initials = getInitials(firstName, lastName)
  return (
    // eslint-disable-next-line
    <>
      {initials !== '' ? (
        <Avatar alt={fullName} sx={sx}>
          {initials}
        </Avatar>
      ) : (
        <Avatar src='/broken-image.jpg' sx={sx} />
      )}
    </>
  )
}

export default AvatarRegular

AvatarRegular.defaultProps = {
  sx: {},
}
