import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import BaseCheckbox from '../../components/forms/BaseCheckbox'
import AlertMessage from '../../components/messages/AlertMessage'
import RegularLink from '../../components/RegularLink'
import AlertMessageType, { initAlertMessage } from '../../types/components/AlertMessageType'
import LoginInfoType, { initLoginInfo } from '../../types/auth/LoginInfoType'
import UserLoginDataType from '../../types/users/UserLoginDataType'

function Login() {
  const { t } = useTranslation()
  const [loginInfo, setLoginInfo] = useState<LoginInfoType>(initLoginInfo)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const from = location.state?.from?.pathname || '/'
  const [login, { isLoading }] = useLoginMutation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const passwordResetSuccess = searchParams.get('pr')
    if (passwordResetSuccess) {
      const successMessage = t('reset_password.change_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      navigate(
        {
          search: '',
        },
        { replace: true },
      )
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    })
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: checked,
    })
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    try {
      const { username, password } = loginInfo
      const userLoginData: UserLoginDataType = await login({ username, password }).unwrap()
      const { access_token: accessToken, refresh_token: refreshToken } = userLoginData
      dispatch(setCredentials({ accessToken }))

      if (loginInfo.rememberMe) {
        localStorage.setItem('refresh_token', refreshToken)
      } else {
        sessionStorage.setItem('refresh_token', refreshToken)
        localStorage.removeItem('refresh_token')
      }

      setLoginInfo(initLoginInfo)
      navigate(from, { replace: true })
      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else if (err.status === 400) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.invalid_credentials'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.login_failed'),
        })
      }
    }
  }

  return (
    <>
      <Typography component='h1' variant='h5' sx={{ marginBottom: 2 }}>
        {t('login.page_title')} {t('header.title')}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: 4 }}>
        {t('login.text')}
      </Typography>

      <Box component='form' onSubmit={handleSubmit}>
        <TextField
          name='username'
          id='username'
          label={t('login.form.email')}
          type='email'
          sx={{ width: '100%', marginBottom: 3 }}
          value={loginInfo.username}
          onChange={handleChange}
        />
        <TextField
          name='password'
          id='password'
          label={t('login.form.password')}
          type='password'
          sx={{ width: '100%', marginBottom: 3 }}
          value={loginInfo.password}
          onChange={handleChange}
        />

        <FormControlLabel
          control={
            <BaseCheckbox name='rememberMe' checked={loginInfo.rememberMe} onChange={handleCheck} />
          }
          label={t('login.form.remember_me')}
          sx={{ width: '100%', marginBottom: 3 }}
        />

        <LoadingButton
          type='submit'
          loading={isLoading}
          loadingIndicator={t('login.form.connect_button_loading')}
          variant='contained'
          sx={{ width: '100%', marginBottom: 2 }}
        >
          {t('login.form.connect_button')}
        </LoadingButton>
      </Box>

      <Typography variant='body2'>
        {t('login.forgot_password')}
        &nbsp;&nbsp;
        <RegularLink to='/forgot-password'>{t('login.forgot_password_link')}</RegularLink>
      </Typography>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default Login
