import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import KnowledgeTableHeader from './KnowledgeTableHeader'
import KnowledgeTableRow from './KnowledgeTableRow'
import KnowledgeTableProps from '../../../types/knowledge/KnowledgeTableProps'

function KnowledgeTable({ knowledgeList }: KnowledgeTableProps) {
  const { t } = useTranslation()

  return (
    <>
      <KnowledgeTableHeader />
      {knowledgeList.length > 0 ? (
        knowledgeList.map((knowledge) => {
          return <KnowledgeTableRow key={knowledge.id} knowledge={knowledge} />
        })
      ) : (
        <Typography variant='body1' component='p' textAlign='center'>
          {t('knowledge.list.no_results')}
        </Typography>
      )}
    </>
  )
}

export default KnowledgeTable
