import { array, object, string } from 'yup'

const addKnowledgeStepOneSchema = object().shape({
  name: string().required(),
  positions: array().of(object().shape({ id: string(), name: string() })),
  employees: array().of(
    object().shape({
      id: string(),
      fullName: string(),
      firstName: string(),
      lastName: string(),
      email: string(),
      position: string(),
    }),
  ),
})

export const initValidationErrors = {
  name: false,
}

export default addKnowledgeStepOneSchema
