export default interface LoginInfoType {
  username: string
  password: string
  rememberMe: boolean
}

export const initLoginInfo = {
  username: '',
  password: '',
  rememberMe: false,
}
