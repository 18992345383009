import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Typography } from '@mui/material'
import { Delete, MailOutlineSharp } from '@mui/icons-material'
import KnowledgeKeyResourceDeleteModal from './KnowledgeKeyResourceDeleteModal'
import BaseContainer from '../../../../components/BaseContainer'
import KnowledgeKeyResourceProps from '../../../../types/knowledge/view/KnowledgeKeyResourceProps'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'

function KnowledgeKeyResource({
  employeeId,
  fullName,
  email,
  position,
  userAccessLevel,
  setPopupMessage,
}: KnowledgeKeyResourceProps) {
  const { t } = useTranslation()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleDeleteOpen = () => {
    setOpenDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setOpenDeleteModal(false)
  }

  const handleMailClick = (recipientEmail: string) => {
    window.location.href = `mailto:${recipientEmail}`
  }

  return (
    <>
      <BaseContainer>
        <Grid container alignItems='center' justifyContent='space-evenly'>
          <Grid item xs={4}>
            <Typography variant='body2' component='p'>
              {t(fullName)}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant='body2' component='p'>
              {t(position)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='body2' component='p'>
              {email}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton color='secondary' onClick={() => handleMailClick(email)}>
              <MailOutlineSharp />
            </IconButton>
          </Grid>

          <RequireAccessLevel
            accessLevels={[OWNER, COLLABORATOR]}
            userAccessLevel={userAccessLevel}
          >
            <Grid item>
              <IconButton color='secondary' onClick={handleDeleteOpen}>
                <Delete />
              </IconButton>
            </Grid>
          </RequireAccessLevel>
        </Grid>
      </BaseContainer>

      <KnowledgeKeyResourceDeleteModal
        openModal={openDeleteModal}
        onCancel={handleDeleteClose}
        title={t('knowledge.view.key_resource.messages.confirm_delete_title')}
        text={t('knowledge.view.key_resource.messages.confirm_delete_description')}
        employeeId={employeeId}
        setPopupMessage={setPopupMessage}
      />
    </>
  )
}

export default KnowledgeKeyResource
