import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useOrganizationsListQuery } from './organizationsApiSlice'
import Breadcrumbs from '../../components/Breadcrumbs'
import AlertMessage from '../../components/messages/AlertMessage'
import AlertMessageType, { initAlertMessage } from '../../types/components/AlertMessageType'
import OrganizationsTable from './OrganizationsTable'
import OrganizationModal from './OrganizationModal'

function OrganizationsList() {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const { data: organizationsList } = useOrganizationsListQuery('')

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs />
        <Grid container sx={{ marginBottom: 7 }}>
          <Grid item sx={{ flex: 1 }}>
            <Typography component='h1' variant='h4'>
              {t('organization.list.page_title')}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' endIcon={<Add />} onClick={handleModalOpen}>
              {t('organization.list.buttons.add')}
            </Button>
          </Grid>
        </Grid>

        <OrganizationsTable organizationsList={organizationsList} />
      </Box>

      <OrganizationModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        setPopupMessage={setPopupMessage}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default OrganizationsList
