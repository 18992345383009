import React from 'react'
import { Box, Pagination, Stack } from '@mui/material'
import KnowledgePaginationProps from '../../../types/knowledge/KnowledgePaginationProps'

function KnowledgePagination({ totalPages, page, setPage }: KnowledgePaginationProps) {
  return (
    <Box display='flex' justifyContent='center' marginTop={7}>
      <Stack spacing={2}>
        <Pagination
          count={totalPages || 1}
          page={page}
          size='large'
          onChange={(_e: React.ChangeEvent<unknown>, value: number) => setPage(value)}
          sx={{
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                backgroundColor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
              },
            },
          }}
        />
      </Stack>
    </Box>
  )
}

export default KnowledgePagination
