import React from 'react'
import { useTranslation } from 'react-i18next'
import { List } from '@mui/material'
import { OpenInNewOutlined } from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuItemAction from './MenuItemAction'
import IsCurrentPath from '../../common/utils/IsCurrentPath'

export default function SecondaryMenu() {
  const { i18n, t } = useTranslation()

  const handleSwitchLanguage = (): void => {
    const language = i18n.language === 'en' ? 'fr' : 'en'
    i18n.changeLanguage(language)
  }

  return (
    <nav aria-label='secondary mailbox folders'>
      <List
        sx={{
          paddingTop: 3,
          paddingBottom: 10,
          [`& .MuiButtonBase-root`]: { paddingLeft: 3, paddingRight: 1 },
        }}
      >
        <MenuItem
          text={t('side_menu.user_guide')}
          link='/'
          icon={<OpenInNewOutlined fontSize='small' />}
          iconPosition='right'
        />
        <MenuItem text={t('side_menu.faq')} link='/faq' isSelected={IsCurrentPath(['/faq'])} />
        <MenuItem
          text={t('side_menu.lexicon')}
          link='/lexicon'
          isSelected={IsCurrentPath(['/lexicon'])}
        />
        <MenuItem
          text={t('side_menu.contact')}
          link='/contact'
          isSelected={IsCurrentPath(['/contact'])}
        />
        <MenuItemAction text={t('side_menu.language_switch')} onClick={handleSwitchLanguage} />
      </List>
    </nav>
  )
}
