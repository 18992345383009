import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import AvatarSmall from '../../../../components/AvatarSmall'
import BorderlessSelect from '../../../../components/forms/BorderlessSelect'
import SelectedSharedToRowProps from '../../../../types/knowledge/view/shareKnowledge/SelectedSharedToRowProps'

function SelectedSharedToRow({ employee, handleChange, handleDelete }: SelectedSharedToRowProps) {
  const { i18n, t } = useTranslation()
  const roleName = employee && employee.role ? employee.role.name : ''

  return (
    <Box>
      <Box paddingY={2}>
        <Grid container spacing={3} display='flex' alignItems='center'>
          {employee && (
            <>
              <Grid item xs={8} display='flex' alignItems='center'>
                <AvatarSmall
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  fullName={`${employee.firstName} ${employee.lastName}`}
                />
                <Typography variant='subtitle2' component='p' marginLeft={1} marginRight={1}>
                  {`${employee.firstName} ${employee.lastName}`}
                </Typography>

                {employee.role && (
                  <>
                    <Divider orientation='vertical' flexItem />

                    <Typography variant='body2' component='p' marginLeft={1}>
                      {i18n.language === 'en'
                        ? employee.role.translation.en
                        : employee.role.translation.fr}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                justifyContent='right'
                display='flex'
                alignItems='center'
                minHeight={56}
              >
                {roleName !== 'SuperAdmin' && (
                  // eslint-disable-next-line
                  <>
                    {employee.accessLevelName !== 'Owner' ? (
                      <>
                        <BorderlessSelect
                          label={t('knowledge.view.share.access_level_label')}
                          labelId='accessLevel-label'
                          id={`access_level_${employee.employeeId}`}
                          name={employee.employeeId}
                          value={employee.accessLevelName}
                          handleChange={(event) => handleChange(event, employee.employeeId)}
                          hideLabel
                        >
                          <MenuItem value='Learner'>{t('knowledge.access_level.learner')}</MenuItem>
                          <MenuItem value='Collaborator'>
                            {t('knowledge.access_level.collaborator')}
                          </MenuItem>
                        </BorderlessSelect>

                        <IconButton
                          onClick={() => handleDelete(employee.employeeId)}
                          size='small'
                          sx={{ marginLeft: 1, padding: 0.5 }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    ) : (
                      t('knowledge.access_level.owner')
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Divider />
    </Box>
  )
}

export default SelectedSharedToRow
