import React from 'react'
import { Divider, Drawer, Toolbar } from '@mui/material'
import PrimaryMenu from './PrimaryMenu'
import SecondaryMenu from './SecondaryMenu'

export default function SideMenuDrawer() {
  const drawerWidth = 256

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <PrimaryMenu />
      <Divider />
      <SecondaryMenu />
    </Drawer>
  )
}
