import React, { useState } from 'react'
import { t } from 'i18next'
import { Grid, Typography, Box, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import KnowledgeKeyResource from './KnowledgeKeyResource'
import KnowledgeKeyResourceAddModal from './KnowledgeKeyResourceAddModal'
import BaseContainer from '../../../../components/BaseContainer'
import AlertMessage from '../../../../components/messages/AlertMessage'
import KnowledgeKeyResourceProps from '../../../../types/knowledge/view/KnowledgeKeyResourceProps'
import KnowledgeKeyResourcesProps from '../../../../types/knowledge/view/KnowledgeKeyResourcesProps'
import AlertMessageType, { initAlertMessage } from '../../../../types/components/AlertMessageType'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'

function KnowledgeKeyResourceContainer({
  keyResources,
  userAccessLevel,
}: KnowledgeKeyResourcesProps) {
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [openAddModal, setOpenAddModal] = useState(false)

  const handleAddOpen = () => {
    setOpenAddModal(true)
  }

  const handleAddClose = () => {
    setOpenAddModal(false)
  }

  return (
    <Grid>
      <Typography variant='h6' component='p' marginBottom={3}>
        {t('knowledge.view.key_resource.title')}
      </Typography>
      <Typography variant='body2' component='p' marginBottom={2}>
        {t('knowledge.view.key_resource.description')}
      </Typography>
      <Box>
        {keyResources && keyResources.length > 0 ? (
          keyResources.map((kr: KnowledgeKeyResourceProps) => {
            return (
              <KnowledgeKeyResource
                key={kr.employeeId}
                employeeId={kr.employeeId}
                fullName={kr.fullName}
                email={kr.email}
                position={kr.position}
                userAccessLevel={userAccessLevel}
                setPopupMessage={setPopupMessage}
              />
            )
          })
        ) : (
          <BaseContainer>
            <Typography variant='subtitle2' component='p'>
              {t('knowledge.view.key_resource.empty')}
            </Typography>
          </BaseContainer>
        )}

        <RequireAccessLevel accessLevels={[OWNER, COLLABORATOR]} userAccessLevel={userAccessLevel}>
          <Button variant='contained' endIcon={<Add />} onClick={handleAddOpen}>
            {t('knowledge.view.buttons.add_key_resource')}
          </Button>
        </RequireAccessLevel>
      </Box>

      <KnowledgeKeyResourceAddModal
        openModal={openAddModal}
        onCancel={handleAddClose}
        title='knowledge.view.key_resource.messages.add_title'
        text='knowledge.view.key_resource.messages.add_description'
        setPopupMessage={setPopupMessage}
      />
      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </Grid>
  )
}

export default KnowledgeKeyResourceContainer
