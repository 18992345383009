import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useGetKnowledgeListQuery } from './knowledgeApiSlice'
import KnowledgeSearch from './KnowledgeSearch'
import KnowledgeSort from './KnowledgeSort'
import KnowledgeTable from './knowledgeTable/KnowledgeTable'
import KnowledgePagination from './knowledgeTable/KnowledgePagination'
import RequireRoles from '../auth/RequireRoles'
import Breadcrumbs from '../../components/Breadcrumbs'
import AlertMessage from '../../components/messages/AlertMessage'
import KnowledgeFiltersType, {
  initKnowledgeFilters,
} from '../../types/knowledge/KnowledgeFiltersType'
import AlertMessageType, { initAlertMessage } from '../../types/components/AlertMessageType'
import { ADMIN, SUPERADMIN } from '../../common/data/roleNames'

function KnowledgeList() {
  const { t } = useTranslation()
  const knowledgePerPage: unknown = process.env.REACT_APP_KNOWLEDGE_PER_PAGE
  const [newFilters, setNewFilters] = useState<KnowledgeFiltersType>(initKnowledgeFilters)
  const [filters, setFilters] = useState(initKnowledgeFilters)
  const [sortBy, setSortBy] = useState('0')
  const [page, setPage] = useState(1)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const { data: knowledgeList, isLoading } = useGetKnowledgeListQuery({
    term: filters.searchTerm,
    positionId: filters.position,
    status: filters.status,
    ownerId: filters.owner,
    sort: sortBy,
    page,
    nbPerPage: knowledgePerPage,
  })
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const totalResults: number = knowledgeList ? knowledgeList.totalResult : 0
  const totalPages = knowledgeList ? Math.ceil(totalResults / (knowledgePerPage as number)) : 1

  useEffect(() => {
    const deleteSuccess = searchParams.get('delete')

    if (deleteSuccess) {
      const successMessage = t('knowledge.view.messages.delete_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      navigate(
        {
          search: '',
        },
        { replace: true },
      )
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!newFilters.searchTerm && !newFilters.position && !newFilters.status && !newFilters.owner) {
      setFilters(initKnowledgeFilters)
      setPage(1)
    }
  }, [newFilters])

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    setFilters(newFilters)
    setPage(1)
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs />

        <Grid container sx={{ marginBottom: 6 }}>
          <Grid item sx={{ flex: 1 }}>
            <Typography component='h1' variant='h4' marginRight={3}>
              {t('knowledge.list.page_title')}
            </Typography>
          </Grid>
          <RequireRoles roles={[SUPERADMIN, ADMIN]}>
            <Grid item>
              <Link to='/knowledge/add' style={{ textDecoration: 'none' }}>
                <Button variant='contained' endIcon={<Add />}>
                  {t('knowledge.list.buttons.add')}
                </Button>
              </Link>
            </Grid>
          </RequireRoles>
        </Grid>

        <KnowledgeSearch
          newFilters={newFilters}
          setNewFilters={setNewFilters}
          handleSubmit={handleSubmit}
        />

        <Grid container marginBottom={4}>
          <Grid item xs={8}>
            <Typography variant='h6' component='h2'>
              {t('knowledge.list.all_knowledge')}
            </Typography>

            <Typography variant='body2' component='p' sx={{ color: 'secondary.light' }}>
              {totalResults} {t('knowledge.list.results', { count: totalResults })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <KnowledgeSort sortBy={sortBy} setSortBy={setSortBy} />
          </Grid>
        </Grid>

        {!isLoading && (
          <>
            <KnowledgeTable knowledgeList={knowledgeList.knowledges} />
            <KnowledgePagination totalPages={totalPages} page={page} setPage={setPage} />
          </>
        )}
      </Box>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default KnowledgeList
