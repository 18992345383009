import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import BaseModal from '../../../components/modal/BaseModal'
import KnowledgeOptionExampleModalProps from '../../../types/knowledge/details/KnowledgeOptionExampleModalProps'

function KnowledgeDetailOptionExampleModal({
  openModal: open,
  title,
  handleModalClose,
}: KnowledgeOptionExampleModalProps) {
  const { t } = useTranslation()
  return (
    <BaseModal openModal={open} title={t(title)} handleClose={handleModalClose}>
      <Typography variant='subtitle2' component='h3' marginBottom={2}>
        {t('knowledge.details.example')}
      </Typography>
      <Typography variant='body2' component='p'>
        {t(`${title}_Example`)}
      </Typography>
    </BaseModal>
  )
}

export default KnowledgeDetailOptionExampleModal
