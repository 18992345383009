import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useUserCreateMutation } from '../usersApiSlice'
import { useLoginMutation } from '../../auth/authApiSlice'
import { setCredentials } from '../../auth/authSlice'
import BaseCheckbox from '../../../components/forms/BaseCheckbox'
import AlertMessage from '../../../components/messages/AlertMessage'
import RegularLink from '../../../components/RegularLink'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import CreateAccountInfoType, {
  initCreateAccountInfo,
} from '../../../types/users/add/CreateAccountInfoType'
import UserLoginDataType from '../../../types/users/UserLoginDataType'
import validateForm from '../../../common/validations/validateForm'
import createAccountSchema, {
  initValidationErrors,
} from '../../../common/validations/createAccountSchema'
import validateFields from '../../../common/validations/validateFields'

function CreateAccount() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [createAccountInfo, setCreateAccountInfo] = useState<CreateAccountInfoType>({
    ...initCreateAccountInfo,
    email: searchParams.get('email'),
    token: searchParams.get('token'),
  })
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [createUser, { isLoading: createUserLoading }] = useUserCreateMutation()
  const [login, { isLoading: loginLoading }] = useLoginMutation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setCreateAccountInfo({
      ...createAccountInfo,
      [name]: value,
    })
    setValidationErrors({ ...validationErrors, [name]: false })
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setCreateAccountInfo({
      ...createAccountInfo,
      [name]: checked,
    })
  }

  const userLogin = async () => {
    try {
      const { email, password, rememberMe } = createAccountInfo
      const userLoginData: UserLoginDataType = await login({
        username: email,
        password,
      }).unwrap()
      const { access_token: accessToken, refresh_token: refreshToken } = userLoginData
      dispatch(setCredentials({ accessToken }))
      if (rememberMe) {
        localStorage.setItem('refresh_token', refreshToken)
      } else {
        sessionStorage.setItem('refresh_token', refreshToken)
        localStorage.removeItem('refresh_token')
      }
      setCreateAccountInfo(initCreateAccountInfo)
      navigate('/', { replace: true })
      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else if (err.status === 400) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.invalid_credentials'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.login_failed'),
        })
      }
    }
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(createAccountSchema, createAccountInfo)

    if (isFormValid) {
      try {
        await createUser({
          token: createAccountInfo.token,
          password: createAccountInfo.password,
          email: createAccountInfo.email,
        }).unwrap()

        userLogin()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(createAccountSchema, createAccountInfo)
      setValidationErrors(errors)
    }
  }

  return (
    <>
      <Typography component='h1' variant='h5' sx={{ marginBottom: 2 }}>
        {t('create_account.page_title')}
      </Typography>

      <Box component='form' onSubmit={handleSubmit}>
        <TextField
          disabled
          name='email'
          id='email'
          label={t('login.form.email')}
          type='email'
          sx={{ width: '100%', marginBottom: 3 }}
          value={createAccountInfo.email}
          onChange={handleChange}
        />

        <TextField
          name='password'
          id='password'
          label={t('login.form.password')}
          type='password'
          sx={{ width: '100%', marginBottom: 3 }}
          value={createAccountInfo.password}
          onChange={handleChange}
          error={validationErrors.password}
          helperText={validationErrors.password ? t('errors.forms.invalid_password') : ''}
        />

        <TextField
          name='passwordConfirm'
          id='passwordConfirm'
          label={t('login.form.confirm_password')}
          type='password'
          sx={{ width: '100%', marginBottom: 3 }}
          value={createAccountInfo.passwordConfirm}
          onChange={handleChange}
          error={validationErrors.passwordConfirm}
          helperText={
            validationErrors.passwordConfirm ? t('errors.forms.password_not_matching') : ''
          }
        />

        <FormControlLabel
          control={
            <BaseCheckbox
              name='rememberMe'
              checked={createAccountInfo.rememberMe}
              onChange={handleCheck}
            />
          }
          label={t('login.form.remember_me')}
          sx={{ width: '100%', marginBottom: 3 }}
        />

        <LoadingButton
          type='submit'
          loading={createUserLoading || loginLoading}
          loadingIndicator={t('create_account.buttons.create_account_loading')}
          variant='contained'
          sx={{ width: '100%', marginBottom: 2 }}
        >
          {t('create_account.buttons.create_account')}
        </LoadingButton>
      </Box>

      <Typography variant='body2'>
        {t('create_account.already_have_account')}
        &nbsp;&nbsp;
        <RegularLink to='/login'>{t('create_account.login_link')}</RegularLink>
      </Typography>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default CreateAccount
