import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateKnowledgeEmployeeBroadcastMutation } from '../../knowledgeApiSlice'
import SelectedEmployees from '../../add/stepOne/SelectedEmployees'
import BaseModal from '../../../../components/modal/BaseModal'
import SelectEmployees from '../../../../components/forms/autocomplete/SelectEmployees'
import KnowledgeChangeEmployeeBroadcastModalProps from '../../../../types/knowledge/view/ChangeEmployeeBroadcastModalProps'
import AutocompleteSelectedEmployeeType from '../../../../types/components/AutocompleteSelectedEmployeeType'
import { EmployeeBroadcastType } from '../../../../types/knowledge/view/KnowledgeType'

export default function ChangeEmployeeBroadcastModal({
  openModal,
  handleModalClose,
  currentEmployees,
  setPopupMessage,
}: KnowledgeChangeEmployeeBroadcastModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const currentSelectedEmployees: AutocompleteSelectedEmployeeType[] = currentEmployees.map(
    (employee: EmployeeBroadcastType) => {
      return {
        id: employee.employeeId,
        fullName: employee.fullName,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        position: employee.position,
      }
    },
  )

  const [newSelectedEmployees, setNewSelectedEmployees] =
    useState<AutocompleteSelectedEmployeeType[]>(currentSelectedEmployees)
  const [changeEmployees, { isLoading: changeEmployeesLoading }] =
    useUpdateKnowledgeEmployeeBroadcastMutation()

  const handleChange = (newValue: AutocompleteSelectedEmployeeType[]) => {
    setNewSelectedEmployees(newValue)
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()
    try {
      const employeesId: string[] = newSelectedEmployees.map((employee) => {
        return employee.id
      })

      await changeEmployees({ id, employeesId }).unwrap()

      const successMessage = t('knowledge.view.overview.employee_broadcast_edit_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      handleModalClose()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.employee_broadcast_edit')}
      handleClose={handleModalClose}
    >
      <Box component='form'>
        <SelectEmployees
          selectedEmployees={newSelectedEmployees}
          handleEmployeesChange={handleChange}
        />

        {newSelectedEmployees.length > 0 && <SelectedEmployees employees={newSelectedEmployees} />}

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={changeEmployeesLoading}
            loadingIndicator={t('common.buttons.save_loading')}
            variant='contained'
            onClick={handleSubmit}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={handleModalClose}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
