import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { usePasswordResetMutation } from '../usersApiSlice'
import AlertMessage from '../../../components/messages/AlertMessage'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import PasswordInfoType, { initPasswordInfo } from '../../../types/users/password/PasswordInfoType'
import validateForm from '../../../common/validations/validateForm'
import passwordResetSchema, {
  initValidationErrors,
} from '../../../common/validations/passwordResetSchema'
import validateFields from '../../../common/validations/validateFields'

function PasswordReset() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfoType>(initPasswordInfo)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const [passwordReset, { isLoading }] = usePasswordResetMutation()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setPasswordInfo({ ...passwordInfo, [name]: value })
    setValidationErrors({ ...validationErrors, [name]: false })
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(passwordResetSchema, passwordInfo)

    if (isFormValid) {
      try {
        await passwordReset({ token, password: passwordInfo.password, email }).unwrap()

        setPasswordInfo(initPasswordInfo)
        setValidationErrors(initValidationErrors)
        navigate('/login?pr=true', { replace: true })
        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else if (err.status === 400) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.password_reset_failed'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(passwordResetSchema, passwordInfo)
      setValidationErrors(errors)
    }
  }

  return (
    <>
      <Typography component='h1' variant='h5' sx={{ marginBottom: 2 }}>
        {t('reset_password.page_title')}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: 4 }}>
        {t('reset_password.text')}
      </Typography>

      <Box component='form' onSubmit={handleSubmit}>
        <TextField
          name='password'
          id='password'
          label={t('login.form.password')}
          type='password'
          sx={{ width: '100%', marginBottom: 3 }}
          value={passwordInfo.password}
          onChange={handleChange}
          error={validationErrors.password}
          helperText={validationErrors.password ? t('errors.forms.invalid_password') : ''}
        />

        <TextField
          name='passwordConfirm'
          id='passwordConfirm'
          label={t('login.form.confirm_password')}
          type='password'
          sx={{ width: '100%', marginBottom: 3 }}
          value={passwordInfo.passwordConfirm}
          onChange={handleChange}
          error={validationErrors.passwordConfirm}
          helperText={
            validationErrors.passwordConfirm ? t('errors.forms.password_not_matching') : ''
          }
        />

        <LoadingButton
          type='submit'
          loading={isLoading}
          loadingIndicator={t('common.buttons.save_loading')}
          variant='contained'
          sx={{ width: '100%', marginBottom: 2 }}
        >
          {t('common.buttons.save')}
        </LoadingButton>
      </Box>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default PasswordReset
