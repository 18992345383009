export default interface CreateAccountInfoType {
  email: string | null
  password: string
  passwordConfirm: string
  rememberMe: boolean
  token: string | null
}

export const initCreateAccountInfo = {
  email: '',
  password: '',
  passwordConfirm: '',
  rememberMe: false,
  token: '',
}
