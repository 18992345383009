import React, { useState } from 'react'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import MenuItemCollapseProps from '../../types/components/MenuItemCollapseProps'

export default function MenuItemCollapse({ text, icon, children, isOpen }: MenuItemCollapseProps) {
  const [open, setOpen] = useState<boolean>(isOpen)

  const handleClick = (): void => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} primaryTypographyProps={{ variant: 'body2' }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}
