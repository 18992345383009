import React, { useState } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { Delete, Download, Edit } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import KnowledgeDocumentationEditDocumentModal from './KnowledgeDocumentationEditDocumentModal'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'
import KnowledgeDocumentationDeleteModal from './KnowledgeDocumentationDeleteModal'
import { useLazyDownloadKnowledgeDocumentQuery } from '../../knowledgeApiSlice'
import KnowledgeDocumentationHeaderDocumentProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationHeaderDocumentProps'

function KnowledgeDocumentationHeaderDocument({
  id,
  documentMimeType,
  userAccessLevel,
  documentFileName,
  description,
  name,
  setPopupMessage,
}: KnowledgeDocumentationHeaderDocumentProps) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const { id: knowledgeId } = useParams()
  const [trigger] = useLazyDownloadKnowledgeDocumentQuery()

  const handleDownloadClick = async () => {
    try {
      const result = await trigger({ knowledgeId, documentId: id })
      const link = document.createElement('a')
      const url = window.URL || window.webkitURL
      const blob = url.createObjectURL(result.data)
      link.href = blob
      link.target = '_blank'
      link.download = `${documentFileName}`
      link.click()
      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.download_failed'),
        })
      }
    }
  }

  const handleDeleteOpen = () => {
    setOpenDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setOpenDeleteModal(false)
  }

  const handleEditOpen = () => {
    setOpenEditModal(true)
  }

  const handleEditClose = () => {
    setOpenEditModal(false)
  }

  return (
    <Grid container alignItems='center' justifyContent='flex-start' spacing={2}>
      <Grid item>
        <Typography variant='body2' sx={{ color: 'secondary.light' }}>
          {documentMimeType.toUpperCase()}
        </Typography>
      </Grid>

      <Grid item sx={{ marginLeft: 'auto' }}>
        <IconButton color='secondary' onClick={handleDownloadClick}>
          <Download />
        </IconButton>
        <RequireAccessLevel accessLevels={[OWNER, COLLABORATOR]} userAccessLevel={userAccessLevel}>
          <IconButton color='secondary' onClick={handleEditOpen}>
            <Edit />
          </IconButton>
          <IconButton color='secondary' onClick={handleDeleteOpen}>
            <Delete />
          </IconButton>
        </RequireAccessLevel>
      </Grid>

      <KnowledgeDocumentationDeleteModal
        handleDeleteModalClose={handleDeleteClose}
        id={id}
        openDeleteModal={openDeleteModal}
        setPopupMessage={setPopupMessage}
      />

      <KnowledgeDocumentationEditDocumentModal
        openModal={openEditModal}
        handleModalClose={handleEditClose}
        setPopupMessage={setPopupMessage}
        description={description}
        name={name}
        filename={documentFileName}
        id={id}
      />
    </Grid>
  )
}

export default KnowledgeDocumentationHeaderDocument
