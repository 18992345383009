import apiSlice from '../../app/api/apiSlice'

export const employeesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    employeesList: builder.query({
      query: (arg) => ({
        url: '/employee/search',
        params: { ...arg },
      }),
      providesTags: ['Employees'],
      transformResponse: (response) => response.employees,
    }),
    employeeAdd: builder.mutation({
      query: ({ firstName, lastName, position, email }) => ({
        url: '/employee',
        method: 'POST',
        body: {
          firstName,
          lastName,
          position,
          email,
        },
      }),
      invalidatesTags: ['Employees'],
    }),
    employeeEdit: builder.mutation({
      query: ({ employeeId, firstName, lastName, position, email }) => ({
        url: `/employee/${employeeId}`,
        method: 'PUT',
        body: {
          firstName,
          lastName,
          position,
          email,
        },
      }),
      invalidatesTags: ['Employees'],
    }),
    employeeDelete: builder.mutation({
      query: (ids) => ({
        url: '/employee',
        method: 'DELETE',
        body: ids,
      }),
      invalidatesTags: ['Employees'],
    }),
    employeeImport: builder.mutation({
      query: (data) => ({
        url: `/employee/import`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Employees'],
    }),
    employeeChangeOwner: builder.mutation({
      query: (data) => ({
        url: `/account/change-owner`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Employees', 'Users'],
    }),
  }),
})

export const {
  useEmployeesListQuery,
  useEmployeeAddMutation,
  useEmployeeEditMutation,
  useEmployeeDeleteMutation,
  useEmployeeImportMutation,
  useEmployeeChangeOwnerMutation,
} = employeesApiSlice
