import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import KnowledgeSortProps from '../../types/knowledge/KnowledgeSortProps'
import knowledgeSortNames from '../../common/data/knowledgeSortNames'

function KnowledgeSort({ sortBy, setSortBy }: KnowledgeSortProps) {
  const { t } = useTranslation()

  const handleSortChange = (e: SelectChangeEvent) => {
    const { value } = e.target
    setSortBy(value)
  }

  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel id='sort-label'>{t('knowledge.list.sort.sorted_by_label')}</InputLabel>
      <Select
        labelId='sort-label'
        id='sort'
        name='sort'
        label={t('knowledge.list.sort.sorted_by_label')}
        value={sortBy}
        onChange={handleSortChange}
      >
        {knowledgeSortNames.map((sort) => {
          return (
            <MenuItem value={sort.value} key={sort.value}>
              {t(`knowledge.list.sort.sort_by.${sort.label}`)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default KnowledgeSort
