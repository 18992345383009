import { Box, styled } from '@mui/material'

const DarkContainer = styled(Box)(({ theme }) => ({
  padding: 24,
  marginBottom: 24,
  borderRadius: 4,
  backgroundColor: theme.palette?.secondary?.main,
  color: 'white',
}))

export default DarkContainer
