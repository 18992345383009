import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import { Box, Grid } from '@mui/material'
import EmptyAnswer from '../../../components/EmptyAnswer'
import KnowledgeDetailQuestionProps from '../../../types/knowledge/details/KnowledgeDetailQuestionProps'

function KnowledgeDetailAnswerText({ answer }: Pick<KnowledgeDetailQuestionProps, 'answer'>) {
  return (
    <Grid container marginBottom={1}>
      {answer ? (
        <Box marginTop={-1} marginLeft={-1.5}>
          <ReactQuill value={answer} readOnly theme='bubble' />
        </Box>
      ) : (
        <EmptyAnswer />
      )}
    </Grid>
  )
}

export default KnowledgeDetailAnswerText
