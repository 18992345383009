import { Box, styled } from '@mui/material'

const LogoContainer = styled(Box)(({ theme }) => ({
  height: 240,
  maxWidth: 240,
  width: '100%',
  fontSize: '88px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.shade?.secondary?.shade12p,
  border: '1px solid #aeb1b6',
  overflow: 'hidden',
}))

export default LogoContainer
