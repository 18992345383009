import React from 'react'
import { Avatar } from '@mui/material'
import AvatarProps from '../types/components/AvatarProps'
import getInitials from '../common/utils/getInitials'

function AvatarSmall({ firstName, lastName, fullName }: AvatarProps) {
  const initials = getInitials(firstName, lastName)
  return (
    <div>
      {initials !== '' ? (
        <Avatar alt={fullName} sx={{ width: 24, height: 24, fontSize: 12 }}>
          {initials}
        </Avatar>
      ) : (
        <Avatar src='/broken-image.jpg' sx={{ width: 24, height: 24, fontSize: 12 }} />
      )}
    </div>
  )
}

export default AvatarSmall
