import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import UsersActions from './UsersActions'
import RequireRoles from '../../auth/RequireRoles'
import BaseTableCell from '../../../components/tables/BaseTableCell'
import BaseCheckbox from '../../../components/forms/BaseCheckbox'
import { UsersTableHeadCellType } from '../../../types/users/list/UsersTableHeadCellType'
import { UsersTableHeaderProps } from '../../../types/users/list/UsersTableHeaderProps'
import UserListFormattedType from '../../../types/users/list/UserFormattedType'
import { UsersTableProps } from '../../../types/users/list/UsersTableProps'
import OrderType from '../../../types/OrderType'
import { ADMIN, SUPERADMIN } from '../../../common/data/roleNames'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aOrderBy = a[orderBy]
  const bOrderBy = b[orderBy]
  let newAOrderBy
  let newBOrderBy

  if (typeof aOrderBy === 'string' && typeof bOrderBy === 'string') {
    newAOrderBy = aOrderBy.toLowerCase()
    newBOrderBy = bOrderBy.toLowerCase()
  } else {
    newAOrderBy = aOrderBy
    newBOrderBy = bOrderBy
  }

  if (newBOrderBy < newAOrderBy) {
    return -1
  }
  if (newBOrderBy > newAOrderBy) {
    return 1
  }
  return 0
}

// eslint-disable-next-line
function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function TableHeader(props: UsersTableHeaderProps) {
  const { t } = useTranslation()
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler =
    (property: keyof UserListFormattedType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const tableHeadCellsUser: readonly UsersTableHeadCellType[] = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'full_name',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'role',
    },
    {
      id: 'nbrKnowledgeOwner',
      numeric: false,
      disablePadding: false,
      label: 'nbr_knowledge_owner',
    },
    {
      id: 'isActive',
      numeric: false,
      disablePadding: false,
      label: 'status',
    },
  ]

  return (
    <TableHead>
      <TableRow>
        <BaseTableCell padding='checkbox'>
          <BaseCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </BaseTableCell>
        {tableHeadCellsUser.map((tableHeadCellUser) => (
          <BaseTableCell
            key={tableHeadCellUser.id}
            align={tableHeadCellUser.numeric ? 'right' : 'left'}
            padding={tableHeadCellUser.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === tableHeadCellUser.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === tableHeadCellUser.id}
              direction={orderBy === tableHeadCellUser.id ? order : 'asc'}
              onClick={createSortHandler(tableHeadCellUser.id)}
            >
              {t(`settings.users.table.${tableHeadCellUser.label}`)}
              {orderBy === tableHeadCellUser.id ? (
                <Box component='span' sx={{ display: 'none' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </BaseTableCell>
        ))}
        <RequireRoles roles={[SUPERADMIN]}>
          <BaseTableCell padding='checkbox' />
        </RequireRoles>
      </TableRow>
    </TableHead>
  )
}

export default function UsersTable({
  usersList,
  selected,
  setSelected,
  sendInvitation,
  handleUserToEdit,
  setIdsToDisable,
}: UsersTableProps) {
  const [order, setOrder] = useState<OrderType>('asc')
  const [orderBy, setOrderBy] = useState<keyof UserListFormattedType>('fullName')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserListFormattedType,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = usersList.map((n: UserListFormattedType) => n.userId)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      {usersList !== undefined && (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={usersList.length}
              />
              <TableBody>
                {stableSort(usersList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.userId)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.userId)}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.fullName}
                        selected={isItemSelected}
                      >
                        <BaseTableCell padding='checkbox'>
                          <BaseCheckbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </BaseTableCell>
                        <BaseTableCell component='th' id={labelId} scope='row' padding='none'>
                          {row.fullName}
                        </BaseTableCell>
                        <BaseTableCell>{row.role}</BaseTableCell>
                        <BaseTableCell>{row.nbrKnowledgeOwner}</BaseTableCell>
                        <BaseTableCell>{row.isActive}</BaseTableCell>
                        <RequireRoles roles={[ADMIN, SUPERADMIN]}>
                          <BaseTableCell align='right'>
                            <UsersActions
                              id={row.userId}
                              sendInvitation={sendInvitation}
                              handleUserToEdit={handleUserToEdit}
                              setIdsToDisable={setIdsToDisable}
                            />
                          </BaseTableCell>
                        </RequireRoles>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <BaseTableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  )
}
