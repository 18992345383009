import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Delete, Person } from '@mui/icons-material'
import {
  useDeleteKnowledgeMutation,
  useGetKnowledgeDocumentationQuery,
  useGetKnowledgeQuery,
  useShareKnowledgeMutation,
} from '../knowledgeApiSlice'
import { selectCurrentUserId } from '../../auth/authSlice'
import RequireAccessLevel from '../../auth/RequireAccessLevel'
import KnowledgeOverviewContainer from './overview/KnowledgeOverviewContainer'
import KnowledgeDetailsOverview from './KnowledgeDetailsOverview'
import ChangeNameModal from './ChangeNameModal'
import ChangeNameButton from './ChangeNameButton'
import SharedTo from './shareKnowledge/SharedTo'
import ShareKnowledgeModal from './shareKnowledge/ShareKnowledgeModal'
import KnowledgeKeyResourceContainer from './keyResources/KnowledgeKeyResourceContainer'
import KnowledgeDocumentationContainer from './documentation/KnowledgeDocumentationContainer'
import AlertMessage from '../../../components/messages/AlertMessage'
import Breadcrumbs from '../../../components/Breadcrumbs'
import ConfirmDelete from '../../../components/messages/ConfirmDelete'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import { KnowledgeUserAccessLevelType } from '../../../types/knowledge/view/KnowledgeType'
import SelectedSharedToType from '../../../types/knowledge/view/shareKnowledge/SelectedSharedToType'
import SelectedSharedType from '../../../types/knowledge/view/shareKnowledge/SelectedSharedType'
import { OWNER } from '../../../common/data/accessLevelNames'
import { SUPERADMIN } from '../../../common/data/roleNames'

function Knowledge() {
  const skipFetching = useRef(false)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [idsToDelete, setIdsToDelete] = useState<readonly string[]>([])
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: knowledge } = useGetKnowledgeQuery(id, { skip: skipFetching.current })
  const { data: knowledgeDocumentation } = useGetKnowledgeDocumentationQuery({ id })
  const [knowledgeDelete] = useDeleteKnowledgeMutation()
  const breadcrumbsText = t('knowledge.view.buttons.back')
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userId = useSelector(selectCurrentUserId)
  const [newSelectedShared, setNewSelectedShared] = useState<SelectedSharedToType[]>()
  const [shareKnowledge, { isLoading: shareKnowledgeLoading }] = useShareKnowledgeMutation()

  const userAccessLevel = knowledge?.knowledgeUserAccessLevel.find(
    (kual: KnowledgeUserAccessLevelType) => kual.userId === userId,
  )

  const currentSelectedShared: SelectedSharedToType[] = knowledge?.knowledgeUserAccessLevel.map(
    (employee: KnowledgeUserAccessLevelType) => {
      return {
        employeeId: employee.employeeId,
        userId: employee.userId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        role: employee.role,
        accessLevelName: employee.accessLevel.name,
        email: employee.email,
      }
    },
  )

  useEffect(() => {
    setNewSelectedShared(currentSelectedShared)
    // eslint-disable-next-line
  }, [knowledge])

  useEffect(() => {
    const addSuccess = searchParams.get('add')
    if (addSuccess) {
      const successMessage = t('knowledge.add.add_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      navigate(
        {
          search: '',
        },
        { replace: true },
      )
    }
    // eslint-disable-next-line
  }, [])

  const handleShareSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    try {
      const filteredSelectedShared = newSelectedShared?.filter(
        (employee) => employee.accessLevelName !== SUPERADMIN,
      )

      const employeeList: SelectedSharedType[] | undefined = filteredSelectedShared?.map(
        (employee: SelectedSharedToType) => {
          return {
            employeeId: employee.employeeId,
            userId:
              employee.userId !== '00000000-0000-0000-0000-000000000000' ? employee.userId : null,
            accessLevelName: employee.accessLevelName,
            email: employee.email,
          }
        },
      )

      shareKnowledge({ id, data: { employeeList } }).unwrap()

      const successMessage = t('knowledge.view.share.share_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      setOpenShareModal(false)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleDelete = () => {
    setIdsToDelete([id as string])
  }

  const handleCancelDelete = () => {
    setIdsToDelete([])
  }

  const deleteKnowledge = async (ids: readonly string[]) => {
    try {
      skipFetching.current = true

      ids.forEach((i) => {
        knowledgeDelete(i).unwrap()
      })

      setIdsToDelete([])
      handleCancelDelete()
      navigate(`/knowledge?delete=true`, { replace: true })
      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.delete_failed'),
        })
      }
    }
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs text={breadcrumbsText} route='/knowledge' />

        {knowledge && (
          <>
            <Grid container spacing={5} sx={{ marginBottom: 6 }}>
              <Grid item flex={1}>
                <RequireAccessLevel
                  accessLevels={[OWNER]}
                  userAccessLevel={userAccessLevel.accessLevel.name}
                  alternative={
                    <Typography component='h1' variant='h4' color='secondary'>
                      {knowledge.name}
                    </Typography>
                  }
                >
                  <ChangeNameButton
                    knowledgeName={knowledge.name}
                    setOpenModal={setOpenChangeNameModal}
                  />
                </RequireAccessLevel>
              </Grid>

              <Grid item textAlign='right' display='flex' alignItems='center'>
                <SharedTo sharedUsers={knowledge.knowledgeUserAccessLevel} />

                <RequireAccessLevel
                  accessLevels={[OWNER]}
                  userAccessLevel={userAccessLevel.accessLevel.name}
                >
                  <Button
                    variant='contained'
                    startIcon={<Person />}
                    onClick={() => setOpenShareModal(true)}
                  >
                    {t('common.buttons.share')}
                  </Button>
                </RequireAccessLevel>
              </Grid>
            </Grid>

            <KnowledgeOverviewContainer
              knowledge={knowledge}
              userAccessLevel={userAccessLevel?.accessLevel.name}
            />

            <Grid container direction='column' spacing={5}>
              <Grid item>
                <KnowledgeDetailsOverview userAccessLevel={userAccessLevel?.accessLevel.name} />
              </Grid>
              <Grid item>
                <KnowledgeKeyResourceContainer
                  keyResources={knowledge.keyResources}
                  userAccessLevel={userAccessLevel?.accessLevel.name}
                />
              </Grid>

              <Grid item>
                <KnowledgeDocumentationContainer
                  documents={knowledgeDocumentation}
                  userAccessLevel={userAccessLevel?.accessLevel.name}
                />
              </Grid>
            </Grid>

            <RequireAccessLevel
              accessLevels={[OWNER]}
              userAccessLevel={userAccessLevel?.accessLevel.name}
            >
              <Button
                variant='outlined'
                endIcon={<Delete />}
                color='secondary'
                onClick={handleDelete}
                sx={{ marginTop: 10 }}
              >
                {t('knowledge.view.buttons.delete')}
              </Button>
            </RequireAccessLevel>
          </>
        )}
      </Box>

      {knowledge && (
        <>
          <ChangeNameModal
            openModal={openChangeNameModal}
            setOpenModal={setOpenChangeNameModal}
            currentName={knowledge.name}
            setPopupMessage={setPopupMessage}
          />

          {newSelectedShared && (
            <ShareKnowledgeModal
              openModal={openShareModal}
              setOpenModal={setOpenShareModal}
              currentSelectedShared={currentSelectedShared}
              newSelectedShared={newSelectedShared}
              setNewSelectedShared={setNewSelectedShared}
              handleSubmit={handleShareSubmit}
              isLoading={shareKnowledgeLoading}
            />
          )}
        </>
      )}

      <ConfirmDelete
        title='knowledge.view.messages.confirm_delete_title'
        text='knowledge.view.messages.confirm_delete_text'
        buttonText={t('common.buttons.delete')}
        idsToDelete={idsToDelete}
        onConfirm={deleteKnowledge}
        onCancel={handleCancelDelete}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default Knowledge
