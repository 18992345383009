import React, { useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useRefreshMutation } from './authApiSlice'
import { selectCurrentToken } from './authSlice'

function PersistLogin() {
  const refreshToken = localStorage.getItem('refresh_token')
    ? localStorage.getItem('refresh_token')
    : sessionStorage.getItem('refresh_token')
  const accessToken = useSelector(selectCurrentToken)
  const effectRan = useRef(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [refresh, { isUninitialized, isSuccess, isError, error }] = useRefreshMutation()

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
      const verifyRefreshToken = async () => {
        try {
          await refresh({ refreshToken })
          setVerifySuccess(true)
          // eslint-disable-next-line
        } catch (err: any) {
          throw new Error(error)
        }
      }

      if (!accessToken && refreshToken) verifyRefreshToken()
    }

    return () => {
      effectRan.current = true
    }

    // eslint-disable-next-line
  }, [])

  let content = <div />
  if (
    !refreshToken ||
    isError ||
    (isSuccess && verifySuccess) ||
    (accessToken && isUninitialized)
  ) {
    content = <Outlet />
  } else {
    content = <div />
  }

  return content
}

export default PersistLogin
