import { mixed, object, string } from 'yup'

const addDocumentSchema = object().shape({
  file: mixed().required(),
  name: string().required(),
  description: string().required(),
})

export const initAddDocumentValidationErrors = {
  file: false,
  name: false,
  description: false,
}

export default addDocumentSchema
