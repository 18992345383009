import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, MenuItem, SelectChangeEvent } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import BaseModal from '../../../../components/modal/BaseModal'
import KnowledgeChangeStatusModalProps from '../../../../types/knowledge/view/KnowledgeChangeStatusModalProps'
import knowledgeStatusNames from '../../../../common/data/knowledgeStatusNames'
import BaseSelect from '../../../../components/forms/BaseSelect'
import { useUpdateKnowledgeStatusMutation } from '../../knowledgeApiSlice'

export default function KnowledgeChangeStatusModal({
  openModal,
  handleModalClose,
  knowledgeStatus,
  setPopupMessage,
}: KnowledgeChangeStatusModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [selectedStatus, setSelectedStatus] = useState(knowledgeStatus)
  const [changeStatus, { isLoading: changeStatusLoading }] = useUpdateKnowledgeStatusMutation()

  const handleChange = (e: SelectChangeEvent) => {
    const { value } = e.target
    setSelectedStatus(+value)
  }

  const onConfirm: React.FormEventHandler = async (event) => {
    event.preventDefault()
    try {
      await changeStatus({
        id,
        statusId: selectedStatus,
      }).unwrap()

      const successMessage = t('knowledge.view.overview.status_change_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      handleModalClose()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const closeModal = () => {
    handleModalClose()
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.status_change')}
      handleClose={closeModal}
    >
      <Box component='form'>
        <BaseSelect
          label={t('knowledge.list.search.status_label')}
          labelId='status-label'
          id='status'
          name='status'
          value={selectedStatus.toString()}
          handleChange={handleChange}
        >
          {knowledgeStatusNames.map((status) => {
            return (
              <MenuItem value={`${status.value}`} key={status.value}>
                {t(`knowledge.status.${status.label}`)}
              </MenuItem>
            )
          })}
        </BaseSelect>

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={changeStatusLoading}
            loadingIndicator={t('common.buttons.save_loading')}
            variant='contained'
            onClick={onConfirm}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={handleModalClose}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
