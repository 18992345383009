import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, Grid, Typography } from '@mui/material'
import ReadExampleLink from '../../../components/ReadExampleLink'
import BaseCheckbox from '../../../components/forms/BaseCheckbox'
import KnowledgeDetailListFormProps from '../../../types/knowledge/details/KnowledgeDetailListForm'

function KnowledgeDetailListForm({
  id,
  questionCode,
  optionCode,
  answer,
  questions,
  setQuestions,
}: KnowledgeDetailListFormProps) {
  const { t } = useTranslation()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const updatedQuestions = questions.map((q) => {
      if (q.options !== null) {
        const updatedOptions = q.options?.map((op) => {
          if (op.id === name) {
            return { ...op, answer: checked }
          }
          return op
        })
        return { ...q, options: updatedOptions }
      }
      return q
    })
    setQuestions(updatedQuestions)
  }

  return (
    <Grid item marginBottom={3}>
      <FormControlLabel
        control={<BaseCheckbox name={id} checked={answer} onChange={handleChange} />}
        label={
          <Typography variant='body2' component='p'>
            {t(`knowledge.details.${questionCode}.options.${optionCode}`)}
          </Typography>
        }
      />
      <Box marginLeft={4} marginTop={-1}>
        <ReadExampleLink title={`knowledge.details.${questionCode}.options.${optionCode}`} />
      </Box>
    </Grid>
  )
}

export default KnowledgeDetailListForm
