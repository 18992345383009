/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initRole = {
  name: '',
  translation: {
    en: '',
    fr: '',
  },
}

const initCompany = {
  companyId: null,
  logo: null,
  name: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: '',
    employeeId: '',
    userId: '',
    companyDetails: initCompany,
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    role: initRole,
    token: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken } = action.payload
      state.token = accessToken
    },
    setUserInfo: (state, action) => {
      const {
        username,
        employeeId,
        userId,
        companyDetails,
        firstName,
        lastName,
        email,
        position,
        role,
      } = action.payload
      state.username = username
      state.employeeId = employeeId
      state.userId = userId
      state.companyDetails = companyDetails
      state.firstName = firstName
      state.lastName = lastName
      state.email = email
      state.position = position
      state.role = role
    },
    logout: (state) => {
      state.username = ''
      state.employeeId = ''
      state.userId = ''
      state.companyDetails = initCompany
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.position = ''
      state.role = initRole
      state.token = null
    },
  },
})

export const { setCredentials, setUserInfo, logout } = authSlice.actions

export default authSlice.reducer

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectCurrentUsername = (state: any) => state.auth.username
export const selectCurrentCompanyId = (state: any) => state.auth.companyDetails.companyId
export const selectCurrentCompanyName = (state: any) => state.auth.companyDetails.name
export const selectCurrentCompanyLogo = (state: any) => state.auth.companyDetails.logo
export const selectCurrentFirstName = (state: any) => state.auth.firstName
export const selectCurrentLastName = (state: any) => state.auth.lastName
export const selectCurrentPosition = (state: any) => state.auth.position
export const selectCurrentEmail = (state: any) => state.auth.email
export const selectCurrentRole = (state: any) => state.auth.role
export const selectCurrentToken = (state: any) => state.auth.token
export const selectCurrentUserId = (state: any) => state.auth.userId
export const selectCurrentEmployeeId = (state: any) => state.auth.employeeId
