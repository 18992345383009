import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import KnowledgeDetailOptionExampleModal from '../features/knowledge/details/KnowledgeDetailOptionExampleModal'
import KnowledgeOptionExampleTitle from '../types/knowledge/details/KnowledgeOptionExampleTitle'

function ReadExampleLink({ title }: KnowledgeOptionExampleTitle) {
  const { t } = useTranslation()
  const [openOptionExampleModal, setOpenOptionExampleModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const handleOptionExampleModalOpen = (text: string) => {
    setModalTitle(text)
    setOpenOptionExampleModal(true)
  }

  const handleOptionExampleModalClose = () => {
    setOpenOptionExampleModal(false)
  }

  return (
    <>
      <Typography
        variant='body2'
        component='p'
        onClick={() => handleOptionExampleModalOpen(title)}
        sx={{
          textDecoration: 'underline',
          textUnderlineOffset: 4,
          cursor: 'pointer',
          textDecorationColor: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
        }}
      >
        {t(`knowledge.details.read_example`)}
      </Typography>

      <KnowledgeDetailOptionExampleModal
        openModal={openOptionExampleModal}
        title={modalTitle}
        handleModalClose={handleOptionExampleModalClose}
      />
    </>
  )
}

export default ReadExampleLink
