/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const knowledgeSlice = createSlice({
  name: 'accessLevel',
  initialState: {
    userAccessLevel: '',
  },
  reducers: {
    setUserAccessLevel: (state, action) => {
      const { userAccessLevel } = action.payload
      state.userAccessLevel = userAccessLevel
    },
  },
})

export const { setUserAccessLevel } = knowledgeSlice.actions

export default knowledgeSlice.reducer

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectCurrentUserAccessLevel = (state: any) => state.knowledge.userAccessLevel
