import styled from '@emotion/styled'
import { Checkbox } from '@mui/material'

const BaseCheckbox = styled(Checkbox)(
  // eslint-disable-next-line
  ({ theme }: any) => `
  &.Mui-checked {
    color: ${theme.palette.primary.dark};
  }

  &.MuiCheckbox-indeterminate {
    color: ${theme.palette.primary.dark}; 
  }
`,
)

export default BaseCheckbox
