import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import EmployeesActionsProps from '../../types/employees/EmployeesActionsProps'

export default function EmployeesActions({
  id,
  handleEmployeeToEdit,
  setIdsToDelete,
}: EmployeesActionsProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    setIdsToDelete([id])
    setAnchorEl(null)
  }

  const handleEdit = () => {
    handleEmployeeToEdit(id)
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz sx={{ color: open ? 'primary.dark' : 'secondary' }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem onClick={handleDelete}>{t('organization.employees.buttons.delete')}</MenuItem>
        <MenuItem onClick={handleEdit}>{t('organization.employees.buttons.edit')}</MenuItem>
      </Menu>
    </>
  )
}
