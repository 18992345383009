import React from 'react'
import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

interface BreadcrumbsProps {
  text?: string
  route?: string
}

export default function Breadcrumbs({ text, route }: BreadcrumbsProps) {
  return (
    <Box height={72} display='flex' alignItems='center'>
      {text && route && (
        <Link to={route} style={{ textDecoration: 'none' }}>
          <Button
            variant='text'
            color='secondary'
            startIcon={
              <ArrowBack
                sx={{
                  color: (theme: any) => theme.palette.primary.dark, // eslint-disable-line @typescript-eslint/no-explicit-any
                }}
              />
            }
            sx={{ textTransform: 'none', paddingLeft: 0 }}
          >
            {text}
          </Button>
        </Link>
      )}
    </Box>
  )
}

Breadcrumbs.defaultProps = {
  text: '',
  route: '',
}
