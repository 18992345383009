import { object, string } from 'yup'

const addUserSchema = object().shape({
  employeeId: string().required(),
  roleName: string().required(),
})

export const initValidationErrors = {
  employeeId: false,
  roleName: false,
}

export default addUserSchema
