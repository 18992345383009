import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSendPasswordResetEmailMutation } from '../usersApiSlice'
import AlertMessage from '../../../components/messages/AlertMessage'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import validateForm from '../../../common/validations/validateForm'
import forgotPasswordSchema from '../../../common/validations/forgotPasswordSchema'
import validateFields from '../../../common/validations/validateFields'

function ForgotPassword() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [validationErrors, setValidationErrors] = useState(false)
  const [sendPasswordResetEmail, { isLoading }] = useSendPasswordResetEmailMutation()
  const backButtonText = t('forgot_password.back')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
    setValidationErrors(false)
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(forgotPasswordSchema, { email })

    if (isFormValid) {
      try {
        await sendPasswordResetEmail({ email }).unwrap()
        const successMessage = t('forgot_password.request_success')
        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })
        setEmail('')
        setValidationErrors(false)
      } catch (err) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    } else {
      await validateFields(forgotPasswordSchema, { email })
      setValidationErrors(true)
    }
  }

  return (
    <Box position='relative'>
      <Box position='absolute' top={-106}>
        <Breadcrumbs text={backButtonText} route='/login' />
      </Box>

      <Typography component='h1' variant='h5' sx={{ marginBottom: 2 }}>
        {t('forgot_password.page_title')}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: 4 }}>
        {t('forgot_password.text')}
      </Typography>

      <Box component='form' onSubmit={handleSubmit}>
        <TextField
          name='email'
          id='email'
          label={t('login.form.email')}
          type='text'
          sx={{ width: '100%', marginBottom: 3 }}
          value={email}
          onChange={handleChange}
          error={validationErrors}
          helperText={validationErrors ? t('errors.forms.invalid_email') : ''}
        />

        <LoadingButton
          type='submit'
          loading={isLoading}
          loadingIndicator={t('common.buttons.send_loading')}
          variant='contained'
          sx={{ width: '100%', marginBottom: 2 }}
        >
          {t('common.buttons.send')}
        </LoadingButton>
      </Box>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </Box>
  )
}

export default ForgotPassword
