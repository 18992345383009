import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { AccountCircle, ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  selectCurrentFirstName,
  selectCurrentLastName,
  selectCurrentPosition,
  selectCurrentRole,
} from '../../features/auth/authSlice'
import AvatarRegular from '../AvatarRegular'
import LogoutButton from '../../features/auth/LogoutButton'

export default function ProfileMenu() {
  const { i18n, t } = useTranslation()
  const userFirstName = useSelector(selectCurrentFirstName)
  const userLastName = useSelector(selectCurrentLastName)
  const completeName = `${userFirstName} ${userLastName}`
  const userPosition = useSelector(selectCurrentPosition)
  const userRole = useSelector(selectCurrentRole)
  const userRoleName = i18n.language === 'en' ? userRole.translation.en : userRole.translation.fr
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <Typography
        variant='body2'
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        {completeName}
        {open ? (
          <ExpandLess fontSize='small' sx={{ marginLeft: 1 }} />
        ) : (
          <ExpandMore fontSize='small' sx={{ marginLeft: 1 }} />
        )}
      </Typography>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: { minWidth: '248px', marginTop: 4 },
        }}
      >
        <Box sx={{ padding: '6px 16px', textAlign: 'center' }}>
          <AvatarRegular
            firstName={userFirstName}
            lastName={userLastName}
            fullName={completeName}
            sx={{ margin: 'auto', marginBottom: 1 }}
          />
          <Typography variant='body2'>{completeName}</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ marginBottom: 1 }}>
            {userPosition}
          </Typography>
          <Typography variant='overline' color='text.secondary'>
            {userRoleName}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 1 }} />
        <MenuItem component={Link} to='/profile' onClick={handleClose}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body2'>{t('header.menu.profile')}</Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        <LogoutButton />
      </Menu>
    </>
  )
}
