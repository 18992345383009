import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'
import BaseSelectProps from '../../types/components/BaseSelectProps'

function BaseSelect({ label, labelId, id, name, value, handleChange, children }: BaseSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default BaseSelect
