import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'

function KnowledgeTableHeader() {
  const { t } = useTranslation()

  return (
    <Box padding={3} paddingRight={0}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.list.table.name')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.list.table.position')}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.list.table.criticality')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.list.table.status')}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant='subtitle2' component='p'>
            {t('knowledge.list.table.owner')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default KnowledgeTableHeader
