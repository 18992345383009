/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '../../features/auth/authSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    const { token } = getState().auth
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions)

  if (result?.error?.originalStatus === 401) {
    let refreshToken: string | undefined

    if (localStorage.getItem('refesh_token') !== null) {
      refreshToken = localStorage.getItem('refesh_token') || ''
    } else if (sessionStorage.getItem('refesh_token') !== null) {
      refreshToken = sessionStorage.getItem('refesh_token') || ''
    } else {
      refreshToken = ''
    }

    const refreshResult: any = await baseQuery(
      {
        url: '/connect/token',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          refresh_token: refreshToken,
          client_id: 'aeromtl.ktdp',
          grant_type: 'refresh_token',
        }),
      },
      api,
      extraOptions,
    )

    if (refreshResult?.data) {
      const newAccessToken = refreshResult.data.access_token
      api.dispatch(setCredentials({ newAccessToken }))
      result = await baseQuery(args, api, extraOptions)
    } else {
      if (refreshResult?.error?.status === 403) {
        throw new Error('Login has expired')
      }
      return refreshResult
    }
  }

  return result
}

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Knowledge',
    'KnowledgeDetails',
    'KnowledgeDocumentation',
    'KnowledgeList',
    'Employees',
    'Organization',
    'Organizations',
    'Users',
  ],
  // eslint-disable-next-line
  endpoints: (builder) => ({}),
})

export default apiSlice
