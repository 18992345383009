import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import DarkContainer from '../../../../components/DarkContainer'
import EmptyAnswer from '../../../../components/EmptyAnswer'
import BasicInfoProps from '../../../../types/knowledge/view/BasicInfoProps'

function BasicInfo({ basicInfos }: BasicInfoProps) {
  const { t } = useTranslation()
  const formatParams = {
    val: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  }

  return (
    <DarkContainer>
      <Typography variant='subtitle2' component='p' color='primary'>
        {t('knowledge.view.overview.basic_info.added')}
      </Typography>

      <Typography variant='body2' component='p'>
        <>
          {t('knowledge.view.overview.basic_info.formated_date', {
            val: new Date(basicInfos.createdDate),
            formatParams,
          })}
          <br />
          {t('knowledge.view.overview.basic_info.by')} {basicInfos.createdBy.fullName}
        </>
      </Typography>

      <Typography variant='subtitle2' component='p' color='primary' marginTop={2}>
        {t('knowledge.view.overview.basic_info.documented')}
      </Typography>

      {basicInfos.documentedBy ? (
        <Typography variant='body2' component='p'>
          <>
            {t('knowledge.view.overview.basic_info.formated_date', {
              val: new Date(basicInfos.documentedDate),
              formatParams,
            })}
            <br />
            {t('knowledge.view.overview.basic_info.by')} {basicInfos.documentedBy.fullName}
          </>
        </Typography>
      ) : (
        <EmptyAnswer color='white' />
      )}

      <Typography variant='subtitle2' component='p' color='primary' marginTop={2}>
        {t('knowledge.view.overview.basic_info.recalculated')}
      </Typography>

      {basicInfos.criticalityCalculationBy ? (
        <Typography variant='body2' component='p'>
          <>
            {t('knowledge.view.overview.basic_info.formated_date', {
              val: new Date(basicInfos.criticalityCalculationDate),
              formatParams,
            })}
            <br />
            {t('knowledge.view.overview.basic_info.by')}{' '}
            {basicInfos.criticalityCalculationBy.fullName}
          </>
        </Typography>
      ) : (
        <EmptyAnswer color='white' />
      )}
    </DarkContainer>
  )
}

export default BasicInfo
