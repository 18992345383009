import apiSlice from '../../app/api/apiSlice'

export const knowledgeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKnowledgeFilters: builder.query({
      query: () => ({
        url: '/knowledge/search/filters',
      }),
    }),
    getKnowledgePositions: builder.query({
      query: () => ({
        url: '/position',
      }),
    }),
    getKnowledgeCriticalityQuestions: builder.query({
      query: () => ({
        url: '/question',
      }),
    }),
    getKnowledgeList: builder.query({
      query: ({ term, positionId, status, ownerId, sort, page, nbPerPage }) => ({
        url: '/knowledge/search',
        params: { term, positionId, status, ownerId, sort, page, nbPerPage },
      }),
      providesTags: ['KnowledgeList', { type: 'Knowledge', id: 'LIST' }],
    }),
    getKnowledge: builder.query({
      query: (id) => ({
        url: `/knowledge/${id}`,
      }),
      providesTags: ['Knowledge'],
    }),
    getKnowledgeDetails: builder.query({
      query: ({ id }) => ({
        url: `/knowledge/${id}/details`,
        params: { id },
      }),
      providesTags: ['KnowledgeDetails'],
    }),
    addKnowledgeKeyResource: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}/key-resource`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Knowledge'],
    }),
    deleteKnowledgeKeyResource: builder.mutation({
      query: ({ id, employeeId }) => ({
        url: `/knowledge/${id}/key-resource/${employeeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Knowledge'],
    }),
    addKnowledge: builder.mutation({
      query: (data) => ({
        url: '/knowledge',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['KnowledgeList'],
    }),
    updateKnowledgeDetails: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}/details`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['KnowledgeDetails'],
    }),
    deleteKnowledge: builder.mutation({
      query: (id) => ({
        url: `/knowledge/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Knowledge', id: 'LIST' }],
    }),
    getKnowledgeDocumentation: builder.query({
      query: ({ id }) => ({
        url: `/knowledge/${id}/documents`,
      }),
      providesTags: ['KnowledgeDocumentation'],
    }),
    downloadKnowledgeDocument: builder.query({
      query: ({ knowledgeId, documentId }) => ({
        url: `/knowledge/${knowledgeId}/document/${documentId}`,
        // eslint-disable-next-line
        responseHandler: (response: any) => response.blob(),
        serialize: false,
      }),
    }),
    addKnowledgeDocumentation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}/document`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['KnowledgeDocumentation'],
    }),
    deleteKnowledgeDocumentation: builder.mutation({
      query: ({ knowledgeId, documentationId }) => ({
        url: `/knowledge/${knowledgeId}/document/${documentationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['KnowledgeDocumentation'],
    }),
    updateKnowledgeDocumentation: builder.mutation({
      query: ({ knowledgeId, documentationId, data }) => ({
        url: `/knowledge/${knowledgeId}/document/${documentationId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['KnowledgeDocumentation'],
    }),
    updateKnowledgeName: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Knowledge'],
    }),
    updateKnowledgeStatus: builder.mutation({
      query: ({ id, statusId }) => ({
        url: `/knowledge/${id}/status/${statusId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Knowledge'],
    }),
    updateKnowledgeCriticality: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}/criticality`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Knowledge'],
    }),
    updateKnowledgePosition: builder.mutation({
      query: ({ id, positionsId }) => ({
        url: `/knowledge/${id}/position`,
        method: 'PUT',
        body: positionsId,
      }),
      invalidatesTags: ['Knowledge'],
    }),
    updateKnowledgeEmployeeBroadcast: builder.mutation({
      query: ({ id, employeesId }) => ({
        url: `/knowledge/${id}/employeebroadcast`,
        method: 'PUT',
        body: employeesId,
      }),
      invalidatesTags: ['Knowledge'],
    }),
    shareKnowledge: builder.mutation({
      query: ({ id, data }) => ({
        url: `/knowledge/${id}/share`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Knowledge'],
    }),
  }),
})

export const {
  useGetKnowledgeFiltersQuery,
  useGetKnowledgeDetailsQuery,
  useGetKnowledgePositionsQuery,
  useGetKnowledgeCriticalityQuestionsQuery,
  useGetKnowledgeDocumentationQuery,
  useLazyDownloadKnowledgeDocumentQuery,
  useAddKnowledgeDocumentationMutation,
  useDeleteKnowledgeDocumentationMutation,
  useUpdateKnowledgeDocumentationMutation,
  useGetKnowledgeListQuery,
  useGetKnowledgeQuery,
  useAddKnowledgeMutation,
  useUpdateKnowledgeDetailsMutation,
  useDeleteKnowledgeKeyResourceMutation,
  useAddKnowledgeKeyResourceMutation,
  useDeleteKnowledgeMutation,
  useUpdateKnowledgeNameMutation,
  useUpdateKnowledgeStatusMutation,
  useUpdateKnowledgeCriticalityMutation,
  useUpdateKnowledgePositionMutation,
  useUpdateKnowledgeEmployeeBroadcastMutation,
  useShareKnowledgeMutation,
} = knowledgeApiSlice
