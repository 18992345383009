import styled from '@emotion/styled'
import { Grid } from '@mui/material'

const UnderlineGrid = styled(Grid)(
  // eslint-disable-next-line
  ({ theme }: any) => `
    &.MuiGrid-container {
      border-bottom: 1px solid ${theme.shade.secondary.shade12p};
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  `,
)

export default UnderlineGrid
