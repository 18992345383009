import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Box } from '@mui/material'
import KnowledgeDetailTextFormProps from '../../../types/knowledge/details/KnowledgeDetailTextFormProps'
import './react-quill.css' // import your custom CSS file

function KnowledgeDetailTextForm({
  id,
  answer,
  questions,
  setQuestions,
}: KnowledgeDetailTextFormProps) {
  const handleInputChange = (questionId: string, value: string) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === questionId) {
        return { ...q, answer: value }
      }
      return q
    })
    setQuestions(updatedQuestions)
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ]

  return (
    <Box marginBottom={3}>
      <ReactQuill
        theme='snow'
        id={id}
        value={answer}
        onChange={(value) => handleInputChange(id, value)}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </Box>
  )
}

export default KnowledgeDetailTextForm
