import { object, string } from 'yup'

const knowledgeNameSchema = object().shape({
  name: string().required(),
})

export const initValidationErrors = {
  name: false,
}

export default knowledgeNameSchema
