import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateKnowledgeDetailsMutation } from '../knowledgeApiSlice'
import KnowledgeDetailListForm from './KnowledgeDetailListForm'
import KnowledgeDetailTextForm from './KnowledgeDetailTextForm'
import BaseContainer from '../../../components/BaseContainer'
import RequireRoles from '../../auth/RequireRoles'
import KnowledgeDetailQuestionProps from '../../../types/knowledge/details/KnowledgeDetailQuestionProps'
import KnowledgeDetailQuestionEdit from '../../../types/knowledge/details/KnowledgeDetailQuestionEdit'
import KnowledgeDetailEditProps from '../../../types/knowledge/details/KnowledgeDetailEditProps'
import { SUPERADMIN, ADMIN, EXPERT } from '../../../common/data/roleNames'
import { CHECKBOX_LIST } from '../../../common/data/knowledgeDetailsTypeCodes'

function KnowledgeDetailContainerEdit({
  code,
  questions,
  editModeIndex: index,
  editModes: editStates,
  setEditMode,
  setPopupMessage,
}: KnowledgeDetailEditProps) {
  const { t } = useTranslation()
  const { id: knowledgeId } = useParams()
  const [detailQuestions, setDetailQuestions] = useState<KnowledgeDetailQuestionProps[]>(questions)
  const [knowledgeDetailEdit, { isLoading: knowledgeDetailEditLoading }] =
    useUpdateKnowledgeDetailsMutation()

  const closeEdit = () => {
    const edit = [...editStates]
    edit[index] = false
    setEditMode(edit)
  }

  const handleSubmit: React.FormEventHandler = async () => {
    try {
      const updates: KnowledgeDetailQuestionEdit[] = []
      detailQuestions.forEach((q) => {
        updates.push({
          id: q.id,
          answer: q.answer === null ? '' : q.answer,
          options: q.options,
        })
      })

      await knowledgeDetailEdit({
        id: knowledgeId,
        data: updates,
      }).unwrap()

      const successMessage = t('knowledge.details.messages.edit_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      closeEdit()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  return (
    <Box marginBottom={6}>
      <Typography variant='h6' component='h2' marginBottom={3}>
        {t(`knowledge.details.${code}.title`)}
      </Typography>

      <BaseContainer>
        <Box marginBottom={3} component='form' onSubmit={handleSubmit}>
          {detailQuestions.map((q) => {
            return (
              <Grid item key={q.id}>
                <Grid container sx={{ marginBottom: 1 }}>
                  <Typography variant='subtitle2' component='p'>
                    {t(`knowledge.details.${code}.${q.code}`)}
                  </Typography>
                </Grid>

                {q.typeCode !== CHECKBOX_LIST ? (
                  <KnowledgeDetailTextForm
                    id={q.id}
                    code={q.code}
                    answer={q.answer}
                    questions={detailQuestions}
                    setQuestions={setDetailQuestions}
                  />
                ) : (
                  <Box marginBottom={5} marginTop={3}>
                    {q.options?.map((op) => {
                      return (
                        <KnowledgeDetailListForm
                          key={op.id}
                          id={op.id}
                          questionCode={code}
                          optionCode={op.code}
                          answer={op.answer}
                          questions={detailQuestions}
                          setQuestions={setDetailQuestions}
                        />
                      )
                    })}
                  </Box>
                )}
              </Grid>
            )
          })}
        </Box>

        <LoadingButton
          type='submit'
          loading={knowledgeDetailEditLoading}
          loadingIndicator={t('common.buttons.save_loading')}
          variant='contained'
          onClick={handleSubmit}
        >
          {t('common.buttons.save')}
        </LoadingButton>

        <RequireRoles roles={[SUPERADMIN, ADMIN, EXPERT]}>
          <Button
            variant='outlined'
            color='secondary'
            sx={{ marginLeft: 1 }}
            onClick={() => closeEdit()}
          >
            {t('common.buttons.cancel')}
          </Button>
        </RequireRoles>
      </BaseContainer>
    </Box>
  )
}

export default KnowledgeDetailContainerEdit
