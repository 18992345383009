import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateKnowledgePositionMutation } from '../../knowledgeApiSlice'
import BaseModal from '../../../../components/modal/BaseModal'
import SelectPositions from '../../../../components/forms/autocomplete/SelectPositions'
import ChangePositionsModalProps from '../../../../types/knowledge/view/ChangePositionsModalProps'
import AutocompleteSelectedPositionType from '../../../../types/components/AutocompleteSelectedPositionType'
import { KnowledgePositionType } from '../../../../types/knowledge/view/KnowledgeType'

export default function ChangePositionsModal({
  openModal,
  handleModalClose,
  currentPositions,
  setPopupMessage,
}: ChangePositionsModalProps) {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const currentSelectedPositions: AutocompleteSelectedPositionType[] = currentPositions.map(
    (position: KnowledgePositionType) => {
      return {
        id: position.positionId,
        name: i18n.language === 'en' ? position.positionTitles.en : position.positionTitles.fr,
      }
    },
  )

  const [newSelectedPositions, setNewSelectedPositions] =
    useState<AutocompleteSelectedPositionType[]>(currentSelectedPositions)
  const [changePosition, { isLoading: changePositionLoading }] =
    useUpdateKnowledgePositionMutation()

  const handleChange = (newValue: AutocompleteSelectedPositionType[]) => {
    setNewSelectedPositions(newValue)
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()
    try {
      const positionsId: string[] = newSelectedPositions.map((position) => {
        return position.id
      })

      await changePosition({ id, positionsId }).unwrap()

      const successMessage = t('knowledge.view.overview.position_edit_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      handleModalClose()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.position_edit')}
      handleClose={handleModalClose}
    >
      <Box component='form'>
        <SelectPositions
          selectedPositions={newSelectedPositions}
          handlePositionsChange={handleChange}
        />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={changePositionLoading}
            loadingIndicator={t('common.buttons.save_loading')}
            variant='contained'
            onClick={handleSubmit}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={handleModalClose}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
