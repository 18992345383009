import styled from '@emotion/styled'
import { Box } from '@mui/material'

const BaseContainer = styled(Box)`
  padding: 24px;
  margin-bottom: 24px;
  background-color: #ffffff;
  border-radius: 4px;
`

export default BaseContainer
