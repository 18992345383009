import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Link, Typography } from '@mui/material'
import { Delete, Edit, Launch } from '@mui/icons-material'
import KnowledgeDocumentationDeleteModal from './KnowledgeDocumentationDeleteModal'
import KnowledgeDocumentationEditLinkModal from './KnowledgeDocumentationEditLinkModal'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'
import RequireAccessLevel from '../../../auth/RequireAccessLevel'
import KnowledgeDocumentationHeaderLinkProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationHeaderLinkProps'

function KnowledgeDocumentationHeaderLink({
  id,
  link,
  description,
  name,
  userAccessLevel,
  setPopupMessage,
}: KnowledgeDocumentationHeaderLinkProps) {
  const { t } = useTranslation()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const handleDeleteOpen = () => {
    setOpenDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setOpenDeleteModal(false)
  }

  const handleEditOpen = () => {
    setOpenEditModal(true)
  }

  const handleEditClose = () => {
    setOpenEditModal(false)
  }

  return (
    <Grid container alignItems='center' justifyContent='flex-start' spacing={1}>
      <Grid item display='flex'>
        <Typography variant='body2' sx={{ color: 'secondary.light' }}>
          {t('knowledge.view.documentation.link_type')} {' ― '}
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <Link
          variant='body2'
          href={link}
          target='_blank'
          rel='noopener'
          sx={{
            color: 'secondary.dark',
            textUnderlineOffset: 4,
            textDecorationColor: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
          }}
        >
          {link}
        </Link>
      </Grid>

      <Grid item sx={{ marginLeft: 'auto' }}>
        <a href={link} target='_blank' rel='noreferrer'>
          <IconButton color='secondary'>
            <Launch />
          </IconButton>
        </a>
        <RequireAccessLevel accessLevels={[OWNER, COLLABORATOR]} userAccessLevel={userAccessLevel}>
          <IconButton color='secondary' onClick={handleEditOpen}>
            <Edit />
          </IconButton>
          <IconButton color='secondary' onClick={handleDeleteOpen}>
            <Delete />
          </IconButton>
        </RequireAccessLevel>
      </Grid>
      <KnowledgeDocumentationDeleteModal
        handleDeleteModalClose={handleDeleteClose}
        id={id}
        openDeleteModal={openDeleteModal}
        setPopupMessage={setPopupMessage}
      />
      <KnowledgeDocumentationEditLinkModal
        openModal={openEditModal}
        handleModalClose={handleEditClose}
        setPopupMessage={setPopupMessage}
        description={description}
        name={name}
        link={link}
        id={id}
      />
    </Grid>
  )
}

export default KnowledgeDocumentationHeaderLink
