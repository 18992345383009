import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import KnowledgeDocumentationHeaderLink from './KnowledgeDocumentationHeaderLink'
import KnowledgeDocumentationHeaderDocument from './KnowledgeDocumentationHeaderDocument'
import BaseContainer from '../../../../components/BaseContainer'
import KnowledgeDocumentProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentProps'

function KnowledgeDocumentation({
  id,
  description,
  link,
  name,
  isDocument,
  documentMimeType,
  userAccessLevel,
  documentFileName,
  setPopupMessage,
}: KnowledgeDocumentProps) {
  const { t } = useTranslation()

  return (
    <BaseContainer>
      {isDocument ? (
        <KnowledgeDocumentationHeaderDocument
          id={id}
          documentFileName={documentFileName || ''}
          documentMimeType={documentMimeType || ''}
          userAccessLevel={userAccessLevel}
          description={description}
          name={name}
          setPopupMessage={setPopupMessage}
        />
      ) : (
        <KnowledgeDocumentationHeaderLink
          id={id}
          link={link || ''}
          userAccessLevel={userAccessLevel}
          description={description}
          name={name}
          setPopupMessage={setPopupMessage}
        />
      )}

      <Typography variant='h6' component='p' marginBottom={2}>
        {name}
      </Typography>

      <Typography variant='body2' sx={{ color: 'secondary.light' }}>
        {t('knowledge.view.documentation.description_subtitle')}
      </Typography>

      <Typography variant='body2'>{description}</Typography>
    </BaseContainer>
  )
}

export default KnowledgeDocumentation
