import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import AvatarSmall from '../../../components/AvatarSmall'
import SelectedEmployeeRowProps from '../../../types/users/add/SelectedEmployeeRowProps'

function SelectedEmployeeRow({ employee }: SelectedEmployeeRowProps) {
  return (
    <Box>
      <Box paddingY={2}>
        <Grid container spacing={3} display='flex' alignItems='center'>
          <Grid item xs={4} display='flex' alignItems='center'>
            <AvatarSmall
              firstName={employee.firstName}
              lastName={employee.lastName}
              fullName={employee.fullName}
            />
            <Typography variant='subtitle2' component='p' marginLeft={1}>
              {employee.fullName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body2' component='p'>
              {employee.position}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body2' component='p'>
              {employee.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider />
    </Box>
  )
}

export default SelectedEmployeeRow
