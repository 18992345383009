import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'
import { useGetKnowledgePositionsQuery } from '../../../features/knowledge/knowledgeApiSlice'
import SelectPositionsProps from '../../../types/components/SelectPositionsProps'
import PositionsType from '../../../types/components/PositionsType'
import AutocompleteSelectedPositionType from '../../../types/components/AutocompleteSelectedPositionType'

function SelectPositions({ selectedPositions, handlePositionsChange }: SelectPositionsProps) {
  const { t, i18n } = useTranslation()
  const { data: positions } = useGetKnowledgePositionsQuery('')
  const autocompletePositions =
    positions &&
    positions.map((position: PositionsType) => {
      return {
        id: position.id,
        name: i18n.language === 'en' ? position.translation.en : position.translation.fr,
      }
    })

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    newValue: AutocompleteSelectedPositionType[],
  ) => {
    handlePositionsChange(newValue)
  }

  return (
    <Autocomplete
      multiple
      id='positions'
      value={selectedPositions || []}
      onChange={handleChange}
      options={autocompletePositions || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={t('knowledge.add.step_1.form.positions_label')} />
      )}
      sx={{
        '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
          bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
      }}
    />
  )
}

export default SelectPositions
