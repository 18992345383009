import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import {
  useAddKnowledgeMutation,
  useGetKnowledgeCriticalityQuestionsQuery,
} from '../knowledgeApiSlice'
import { useSuperAdminListQuery } from '../../users/usersApiSlice'
import {
  selectCurrentEmail,
  selectCurrentEmployeeId,
  selectCurrentFirstName,
  selectCurrentLastName,
  selectCurrentRole,
  selectCurrentUserId,
} from '../../auth/authSlice'
import StepProgression from './StepProgression'
import StepOne from './stepOne/StepOne'
import StepTwo from './stepTwo/StepTwo'
import StepThree from './stepThree/StepThree'
import Breadcrumbs from '../../../components/Breadcrumbs'
import InfoAccordion from '../../../components/InfoAccordion'
import AlertMessage from '../../../components/messages/AlertMessage'
import GuideButton from '../../../components/GuideButton'
import AddKnowledgeType from '../../../types/knowledge/add/AddKnowledgeType'
import NewKnowledgeType, { initNewKnowledge } from '../../../types/knowledge/add/NewKnowledgeType'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'
import CriticalityQuestionType from '../../../types/knowledge/add/CriticalityQuestionType'
import UserType from '../../../types/users/UserType'
import SelectedSharedToType from '../../../types/knowledge/view/shareKnowledge/SelectedSharedToType'
import { SUPERADMIN } from '../../../common/data/roleNames'

function KnowledgeAdd() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: questions } = useGetKnowledgeCriticalityQuestionsQuery('')
  const { data: superAdminList } = useSuperAdminListQuery('')
  const [addKnowledge] = useAddKnowledgeMutation()
  const [step, setStep] = useState(0)
  const [expandAccrodion, setExpandAccordion] = useState(true)
  const [newKnowledge, setNewKnowledge] = useState<NewKnowledgeType>(initNewKnowledge)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const from = location.state?.from?.pathname || '/knowledge'
  const userFirstName = useSelector(selectCurrentFirstName)
  const userLastName = useSelector(selectCurrentLastName)
  const userRole = useSelector(selectCurrentRole)
  const userUserId = useSelector(selectCurrentUserId)
  const userEmployeeId = useSelector(selectCurrentEmployeeId)
  const userEmail = useSelector(selectCurrentEmail)
  const progressionSteps = [
    t('knowledge.add.step_1.title'),
    t('knowledge.add.step_2.title'),
    t('knowledge.add.step_3.title'),
  ]

  useEffect(() => {
    const currentShared: SelectedSharedToType[] = [...newKnowledge.employeeShared]

    if (userEmployeeId && superAdminList) {
      superAdminList.forEach((user: UserType) => {
        currentShared.push({
          employeeId: user.employeeId,
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
          accessLevelName: 'SuperAdmin',
          email: user.email,
        })
      })

      currentShared.unshift({
        employeeId: userEmployeeId,
        userId: userUserId,
        firstName: userFirstName,
        lastName: userLastName,
        role: userRole,
        accessLevelName: 'Owner',
        email: userEmail,
      })
    }

    setNewKnowledge({
      ...newKnowledge,
      employeeShared: currentShared,
    })
    // eslint-disable-next-line
  }, [userEmployeeId, superAdminList])

  useEffect(() => {
    if (newKnowledge.questions.length === 0) {
      const newArray: { id: string; value: string }[] = []
      if (questions) {
        questions.forEach((question: CriticalityQuestionType) => {
          newArray.push({ id: question.id, value: '' })
        })
        setNewKnowledge({ ...newKnowledge, questions: newArray })
      }
    }
    // eslint-disable-next-line
  }, [questions])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const handleNextStep = () => {
    setExpandAccordion(false)
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setExpandAccordion(false)
    setStep(step - 1)
  }

  const handleCancel = () => {
    navigate(from)
  }

  const handleSave = async () => {
    const newObject: AddKnowledgeType = {
      name: newKnowledge.name,
      criticality: newKnowledge.criticality,
      knowledgePositions: [],
      employeeBroadcasts: [],
      employeeList: [],
      knowledgeCriticalities: [],
    }

    newKnowledge.positions.forEach((position) => {
      newObject.knowledgePositions.push({ positionId: position.id })
    })

    newKnowledge.employeeBroadcasts.forEach((employee) => {
      newObject.employeeBroadcasts.push({ employeeId: employee.id })
    })

    const filteredSelectedShared =
      newKnowledge.employeeShared &&
      newKnowledge.employeeShared.filter((employee) => employee.accessLevelName !== SUPERADMIN)

    filteredSelectedShared.forEach((employee) => {
      newObject.employeeList.push({
        employeeId: employee.employeeId,
        userId: employee.userId !== '00000000-0000-0000-0000-000000000000' ? employee.userId : null,
        accessLevelName: employee.accessLevelName,
        email: employee.email,
      })
    })

    newKnowledge.questions.forEach((question) => {
      newObject.knowledgeCriticalities.push({
        questionId: question.id,
        value: question.value,
      })
    })

    try {
      const newId = await addKnowledge(newObject).unwrap()
      navigate(`/knowledge/${newId.knowledgeId}?add=true`, { replace: true })
      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const showCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          <StepTwo
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleCancel={handleCancel}
            newKnowledge={newKnowledge}
            setNewKnowledge={setNewKnowledge}
            questions={questions}
          />
        )
      case 2:
        return (
          <StepThree
            handlePreviousStep={handlePreviousStep}
            handleCancel={handleCancel}
            handleSave={handleSave}
            newKnowledge={newKnowledge}
            setNewKnowledge={setNewKnowledge}
            questions={questions}
          />
        )
      default:
        return (
          <StepOne
            handleNextStep={handleNextStep}
            handleCancel={handleCancel}
            newKnowledge={newKnowledge}
            setNewKnowledge={setNewKnowledge}
          />
        )
    }
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs />

        <Typography component='h1' variant='h4' color='secondary' marginBottom={3}>
          {t('knowledge.add.page_title')}
        </Typography>

        <Typography variant='body1' component='p' marginBottom={1.5}>
          {t('knowledge.add.intro')}
        </Typography>

        <GuideButton />

        <Box marginTop={2.5} marginBottom={7}>
          <InfoAccordion
            title={t('knowledge.add.info_title')}
            text={t('knowledge.add.info_text')}
            expand={expandAccrodion}
            setExpand={setExpandAccordion}
          />
        </Box>

        <StepProgression steps={progressionSteps} step={step} />

        {showCurrentStep()}
      </Box>

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default KnowledgeAdd
