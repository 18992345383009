import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { useGetKnowledgeDetailsQuery } from '../knowledgeApiSlice'
import KnowledgeDetailContainer from './KnowledgeDetailContainer'
import KnowledgeDetailContainerEdit from './KnowledgeDetailContainerEdit'
import Breadcrumbs from '../../../components/Breadcrumbs'
import GuideButton from '../../../components/GuideButton'
import InfoBox from '../../../components/InfoBox'
import AlertMessage from '../../../components/messages/AlertMessage'
import KnowledgeDetailProps from '../../../types/knowledge/details/KnowledgeDetailProps'
import AlertMessageType, { initAlertMessage } from '../../../types/components/AlertMessageType'

function KnowledgeDetails() {
  const { t } = useTranslation()
  const { id: knowledgeId } = useParams()
  const breadcrumbsText = knowledgeId ? t('knowledge.details.buttons.back') : ''
  const breadcrumbsRoute = knowledgeId ? `/knowledge/${knowledgeId}` : ''
  const { data: knowledgeDetailsList, isLoading } = useGetKnowledgeDetailsQuery({ id: knowledgeId })
  const [editMode, setEditMode] = useState([false, false, false])
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)

  return (
    <Box marginBottom={10}>
      <Box marginBottom={1} marginTop={3}>
        <Breadcrumbs text={breadcrumbsText} route={breadcrumbsRoute} />
      </Box>

      <Typography marginBottom={3} component='h1' variant='h4' marginRight={3}>
        {t('knowledge.details.page_title')}
      </Typography>

      <Typography marginBottom={1} variant='body2' component='p'>
        {t('knowledge.details.description')}
      </Typography>

      <Box marginBottom={4}>
        <GuideButton />
      </Box>

      {!isLoading &&
        knowledgeDetailsList.map((k: KnowledgeDetailProps, index: number) => {
          return !editMode[index] ? (
            <KnowledgeDetailContainer
              key={k.code}
              code={k.code}
              questions={k.questions}
              hasAnswers={k.hasAnswers}
              editModeIndex={index}
              editModes={editMode}
              setEditMode={setEditMode}
            />
          ) : (
            <KnowledgeDetailContainerEdit
              key={k.code}
              code={k.code}
              questions={k.questions}
              hasAnswers={k.hasAnswers}
              editModeIndex={index}
              editModes={editMode}
              setEditMode={setEditMode}
              setPopupMessage={setPopupMessage}
            />
          )
        })}
      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
      <InfoBox title={t('knowledge.details.info_title')} text={t('knowledge.details.info_text')} />
    </Box>
  )
}

export default KnowledgeDetails
