import React from 'react'
import { Box, Divider } from '@mui/material'
import SelectedEmployeesProps from '../../../../types/knowledge/add/SelectedEmployeesProps'
import SelectedEmployeeRow from '../../../users/add/SelectedEmployeeRow'

function SelectedEmployees({ employees }: SelectedEmployeesProps) {
  return (
    <>
      <Divider />

      <Box marginBottom={3}>
        {employees.map((employee) => {
          return <SelectedEmployeeRow employee={employee} key={employee.id} />
        })}
      </Box>
    </>
  )
}

export default SelectedEmployees
