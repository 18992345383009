import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import BaseModal from '../../../../components/modal/BaseModal'
import KnowledgeModalProps from '../../../../types/knowledge/view/KnowledgeModalProps'

export default function KnowledgeEmployeeBroadcastModal({
  openModal,
  handleModalClose,
}: KnowledgeModalProps) {
  const { t } = useTranslation()

  const closeModal = () => {
    handleModalClose()
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.overview.employee_broadcast_question')}
      handleClose={closeModal}
    >
      <Typography variant='body2' component='p' align='justify'>
        {t('knowledge.view.overview.employee_broadcast_description')}
      </Typography>
    </BaseModal>
  )
}
