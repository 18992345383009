import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {
    shade?: {
      primary?: {
        shade12p?: string
        shade30p?: string
        shade50p?: string
      }
      secondary?: {
        shade12p?: string
        shade30p?: string
        shade50p?: string
      }
    }
  }

  interface ThemeOptions {
    shade?: {
      primary?: {
        shade12p?: React.CSSProperties['color']
        shade30p?: React.CSSProperties['color']
        shade50p?: React.CSSProperties['color']
      }
      secondary?: {
        shade12p?: React.CSSProperties['color']
        shade30p?: React.CSSProperties['color']
        shade50p?: React.CSSProperties['color']
      }
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#f8d782',
      dark: '#f6b001',
      light: '#fcefcc',
    },
    secondary: {
      main: '#333c49',
      dark: '#1c2128',
      light: '#848a93',
    },
    action: {
      selected: 'rgba(0, 0, 0, 0.30)',
    },
  },
  shade: {
    primary: {
      shade12p: 'rgba(248, 215, 130, 0.12)',
      shade30p: 'rgba(248, 215, 130, 0.30)',
      shade50p: 'rgba(248, 215, 130, 0.50)',
    },
    secondary: {
      shade12p: 'rgba(51, 60, 73, 0.12)',
      shade30p: 'rgba(51, 60, 73, 0.30)',
      shade50p: 'rgba(51, 60, 73, 0.50)',
    },
  },
})

export default theme
