import React from 'react'
import { Box, Divider } from '@mui/material'
import SelectedSharedToRow from './SelectedSharedToRow'
import SelectedSharedToProps from '../../../../types/knowledge/view/shareKnowledge/SelectedSharedToProps'

function SelectedSharedTo({ employees, handleChange, handleDelete }: SelectedSharedToProps) {
  return (
    <>
      <Divider />

      <Box marginBottom={3}>
        {employees.map((employee) => {
          return (
            <SelectedSharedToRow
              key={employee.employeeId}
              employee={employee}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          )
        })}
      </Box>
    </>
  )
}

export default SelectedSharedTo
