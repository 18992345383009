import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import ShareKnowledge from './ShareKnowledge'
import CriticalityAnswers from './CriticalityAnswers'
import SummaryRow from './SummaryRow'
import ButtonsBar from '../ButtonsBar'
import BaseContainer from '../../../../components/BaseContainer'
import KnowledgeAddStepThreeProps from '../../../../types/knowledge/add/KnowledgeAddStepThreeProps'

function StepThree({
  handlePreviousStep,
  handleCancel,
  handleSave,
  newKnowledge,
  setNewKnowledge,
  questions,
}: KnowledgeAddStepThreeProps) {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant='h6' component='h2' color='secondary' marginTop={7} marginBottom={3}>
        3. {t('knowledge.add.step_3.title')}
      </Typography>

      <BaseContainer>
        <SummaryRow title={t('knowledge.add.step_1.form.name_label')}>
          {newKnowledge.name}
        </SummaryRow>

        <SummaryRow title={t('knowledge.add.step_1.form.positions_label')} divider>
          {newKnowledge.positions.map((position, index) => {
            return index === 0 ? position.name : `, ${position.name}`
          })}
        </SummaryRow>

        <SummaryRow title={t('knowledge.add.step_3.employees')} divider>
          {newKnowledge.employeeBroadcasts.map((employee, index) => {
            return index === 0 ? employee.fullName : `, ${employee.fullName}`
          })}
        </SummaryRow>

        <CriticalityAnswers questions={questions} newKnowledge={newKnowledge} />

        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />

        <Grid container spacing={3} paddingTop={3}>
          <Grid item xs={6}>
            <Typography variant='h6' component='p'>
              {t('knowledge.add.step_3.criticity_level')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' component='span'>
              {newKnowledge.criticality}
            </Typography>
            <Typography
              variant='h6'
              component='span'
              sx={{
                color: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
              }}
            >
              /100
            </Typography>
          </Grid>
        </Grid>
      </BaseContainer>

      <ShareKnowledge newKnowledge={newKnowledge} setNewKnowledge={setNewKnowledge} />

      <ButtonsBar
        cancel
        back
        save
        handleClickCancel={handleCancel}
        handleClickBack={handlePreviousStep}
        handleClickSave={handleSave}
      />
    </>
  )
}

export default StepThree
