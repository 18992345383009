import apiSlice from '../../app/api/apiSlice'
import { setCredentials } from './authSlice'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: '/connect/token',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username,
          password,
          client_id: 'aeromtl.ktdp',
          grant_type: 'password',
          scope: 'aeromtl.ktdp.api offline_access',
        }),
      }),
    }),
    refresh: builder.mutation({
      query: ({ refreshToken }) => ({
        url: '/connect/token',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          refresh_token: refreshToken,
          client_id: 'aeromtl.ktdp',
          grant_type: 'refresh_token',
        }),
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { access_token: accessToken } = data
          dispatch(setCredentials({ accessToken }))
          // eslint-disable-next-line
        } catch (error: any) {
          throw new Error(error.error.data.error_description)
        }
      },
    }),
    getUserInfo: builder.query({
      query: () => '/connect/userinfo',
    }),
  }),
})

export const { useLoginMutation, useRefreshMutation, useGetUserInfoQuery } = authApiSlice
