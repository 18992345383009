import { useLocation } from 'react-router-dom'

const IsCurrentPath = (paths: string[]): boolean => {
  const location = useLocation()
  if (paths.indexOf(location.pathname) > -1) {
    return true
  }
  return false
}

export default IsCurrentPath
