import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import BaseModal from '../../../../components/modal/BaseModal'
import CriticalityDescriptionModalProps from '../../../../types/knowledge/add/CriticalityDescriptionModalProps'

function CriticalityDescriptionModal({
  open,
  setOpen,
  labelKey,
}: CriticalityDescriptionModalProps) {
  const { t } = useTranslation()

  return (
    <BaseModal
      title={t(`knowledge.criticality_questions.${labelKey}.question`)}
      openModal={open}
      handleClose={() => setOpen(false)}
    >
      <Typography variant='body1' component='h3' fontWeight={500} marginBottom={2}>
        {t(`knowledge.criticality_questions.${labelKey}.answer_low`)}
      </Typography>

      <Typography variant='body2' component='p' marginBottom={3}>
        {t(`knowledge.criticality_questions.${labelKey}.description_low`)}
      </Typography>

      <Typography variant='body1' component='h3' fontWeight={500} marginBottom={2}>
        {t(`knowledge.criticality_questions.${labelKey}.answer_high`)}
      </Typography>

      <Typography variant='body2' component='p' marginBottom={3}>
        {t(`knowledge.criticality_questions.${labelKey}.description_high`)}
      </Typography>
    </BaseModal>
  )
}

export default CriticalityDescriptionModal
