import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useOrganizationEditMutation } from './organizationsApiSlice'
import RequireRoles from '../auth/RequireRoles'
import OrganizationForm from './OrganizationForm'
import BaseContainer from '../../components/BaseContainer'
import OrganizationEditDetailsProps from '../../types/organizations/OrganizationEditDetailsProps'
import OrganizationProfileType from '../../types/organizations/OrganizationProfileType'
import validateForm from '../../common/validations/validateForm'
import validateFields from '../../common/validations/validateFields'
import organizationSchema, {
  initValidationErrors,
} from '../../common/validations/organizationSchema'
import { SUPERADMIN } from '../../common/data/roleNames'

function OrganizationEditDetails({
  organizationProfile,
  setEditMode,
  setPopupMessage,
}: OrganizationEditDetailsProps) {
  const { t } = useTranslation()
  const [organization, setOrganization] = useState<OrganizationProfileType>(organizationProfile)
  const [organizationEdit, { isLoading: organizationEditLoading }] = useOrganizationEditMutation()
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(organizationSchema, organization)

    if (isFormValid) {
      try {
        const formData = new FormData()

        formData.append('Company.Name', organization.name)
        formData.append('Company.Address', organization.address)
        formData.append('Company.Suite', organization.suite)
        formData.append('Company.City', organization.city)
        formData.append('Company.Province', organization.province)
        formData.append('Company.PostalCode', organization.postalCode)
        formData.append('Company.Phone', organization.phone)
        formData.append('Company.Email', organization.email)
        formData.append('Company.WebsiteUrl', organization.websiteUrl)
        formData.append('Logo', organization.logo)

        await organizationEdit({ id: organization.id, data: formData }).unwrap()
        const successMessage = t('organization.profile.messages.edit_success')

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        setEditMode(false)

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(organizationSchema, organization)
      setValidationErrors(errors)
    }
  }

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <BaseContainer>
        <OrganizationForm
          organization={organization}
          setOrganization={setOrganization}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
      </BaseContainer>

      <LoadingButton
        type='submit'
        loading={organizationEditLoading}
        loadingIndicator={t('common.buttons.save_loading')}
        variant='contained'
      >
        {t('common.buttons.save')}
      </LoadingButton>

      <RequireRoles roles={[SUPERADMIN]}>
        <Button
          variant='text'
          color='secondary'
          onClick={() => setEditMode(false)}
          sx={{ marginLeft: 1 }}
        >
          {t('common.buttons.cancel')}
        </Button>
      </RequireRoles>
    </Box>
  )
}

export default OrganizationEditDetails
