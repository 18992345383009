import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, FormHelperText, Stack, Typography } from '@mui/material'
import { UploadFile } from '@mui/icons-material'
import UploadedFile from './UploadedFile'
import FileUploadProps from '../../../types/components/FileUploadProps'
import calculateFileSize from '../../../common/utils/calculateFileSize'

function FileUpload({
  uploadedFile,
  setUploadedFile,
  limitSizeInBytes,
  acceptedFilesInput,
  acceptedFilesText,
  validationErrors,
  popupMessage,
  setPopupMessage,
}: FileUploadProps) {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const wrappedRef = useRef<HTMLDivElement>(null)

  const onDragEnter = () => wrappedRef.current?.classList.add('dragover')
  const onDragLeave = () => wrappedRef.current?.classList.remove('dragover')

  const onFileDrop = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement
      if (!target.files) return

      setPopupMessage({
        ...popupMessage,
        show: false,
      })

      Object.values(target.files).forEach((file: File) => {
        if (file.size > limitSizeInBytes) {
          const errMsg = t('errors.forms.file_to_large')
          setError(errMsg)
        } else if (!acceptedFilesInput.includes(file.type)) {
          const errMsg = t('errors.forms.file_wrong_type')
          setError(errMsg)
        } else {
          setError('')
          setUploadedFile(file)
        }

        return file
      })
    },
    // eslint-disable-next-line
    [limitSizeInBytes, acceptedFilesInput],
  )

  useEffect(() => {
    if (validationErrors) {
      const errMsg = t('errors.forms.mandatory_file')
      setError(errMsg)
    }
    // eslint-disable-next-line
  }, [validationErrors])

  return (
    <>
      <Box
        sx={{
          '&.MuiBox-root:hover, &.MuiBox-root.dragover': {
            opacity: 0.6,
          },
        }}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            position: 'relative',
            width: '100%',
            paddingX: 2,
            paddingY: 3,
            border: '1px dashed',
            borderColor: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
            borderRadius: '4px',
          }}
          ref={wrappedRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDragLeave}
        >
          <Stack justifyContent='center' sx={{ textAlign: 'center' }}>
            <Avatar
              sx={{
                bgcolor: (theme: any) => theme.shade.primary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
                margin: 'auto',
              }}
            >
              <UploadFile
                sx={{
                  color: 'primary.dark',
                }}
              />
            </Avatar>
            <Typography variant='body2' component='p' paddingY={1}>
              <u>{t('common.file_upload.click_to_upload')}</u>{' '}
              {t('common.file_upload.drag_and_drop')}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              sx={{
                opacity: 0.6,
              }}
            >
              {acceptedFilesText}{' '}
              {t('common.file_upload.max_size', { size: calculateFileSize(limitSizeInBytes) })}
            </Typography>
          </Stack>
          <input
            type='file'
            name='upload'
            onChange={onFileDrop}
            accept={acceptedFilesInput.join(',')}
            style={{
              opacity: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>

      <FormHelperText error sx={{ textAlign: 'center' }}>
        {error}
      </FormHelperText>

      {uploadedFile && <UploadedFile uploadedFile={uploadedFile} />}
    </>
  )
}

export default FileUpload
