import React from 'react'
import { AppBar, Box, Divider, Toolbar, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ProfileMenu from './ProfileMenu'
import { selectCurrentCompanyLogo, selectCurrentCompanyName } from '../../features/auth/authSlice'

export default function SiteHeader() {
  const { t } = useTranslation()
  const companyName = useSelector(selectCurrentCompanyName)
  const companyLogo = useSelector(selectCurrentCompanyLogo)

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        color: 'text.primary',
        borderBottom: '1px solid #e0e0e0',
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1 }} flexDirection='row' display='flex'>
          <Typography variant='h6' component='div' marginRight={1}>
            {t('header.title')}
          </Typography>

          <Divider orientation='vertical' flexItem sx={{ marginRight: 1 }} />

          {companyLogo ? (
            <img src={`data:image/jpeg;base64,${companyLogo}`} alt={companyName} />
          ) : (
            <Typography variant='h6' component='div' marginRight={1} color='secondary.light'>
              {companyName}
            </Typography>
          )}
        </Box>

        <Typography variant='body2' component='div'>
          <ProfileMenu />
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
