import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import EmployeeSearchProps from '../../types/employees/EmployeeSearchProps'

export default function EmployeeSearch({
  newSearchTerm,
  setNewSearchTerm,
  handleSubmit,
}: EmployeeSearchProps) {
  const { t } = useTranslation()
  const searchLabel: string = t('organization.employees.search_label')
  const searchPlaceholder: string = t('organization.employees.search_placeholder')

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <Grid container sx={{ display: 'flex', marginBottom: 6 }}>
        <Grid item sx={{ flex: 1 }}>
          <TextField
            focused
            variant='outlined'
            label={searchLabel}
            placeholder={searchPlaceholder}
            value={newSearchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewSearchTerm(e.target.value)}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': { height: 36.5 },
              '& label.Mui-focused': {
                color: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
                },
              },
            }}
          />
        </Grid>
        <Grid item>
          <Button type='submit' variant='contained' endIcon={<Search />} sx={{ marginX: 2 }}>
            {t('organization.employees.buttons.search')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
