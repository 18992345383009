import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { Cached } from '@mui/icons-material'
import { useGetKnowledgeFiltersQuery } from './knowledgeApiSlice'
import BaseContainer from '../../components/BaseContainer'
import KnowledgeSearchProps from '../../types/knowledge/KnowledgeSearchProps'
import { initKnowledgeFilters } from '../../types/knowledge/KnowledgeFiltersType'
import KnowledgeFilterPositionType from '../../types/knowledge/KnowledgeFilterPositionType'
import KnowledgeFilterOwnerType from '../../types/knowledge/KnowledgeFilterOwnerType'
import knowledgeStatusNames from '../../common/data/knowledgeStatusNames'
import SelectWithReset from '../../components/forms/SelectWithReset'

function KnowledgeSearch({ newFilters, setNewFilters, handleSubmit }: KnowledgeSearchProps) {
  const { i18n, t } = useTranslation()
  const { data: filters } = useGetKnowledgeFiltersQuery('')

  const handleChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target
    setNewFilters({ ...newFilters, [name]: value })
  }

  const handleClearAllFilters = () => {
    setNewFilters(initKnowledgeFilters)
  }

  return (
    <>
      <Typography variant='h6' component='h2' marginBottom={3}>
        {t('knowledge.list.search.title')}
      </Typography>

      <BaseContainer
        component='form'
        onSubmit={handleSubmit}
        sx={{
          '&.MuiBox-root': {
            paddingY: 5,
            marginBottom: 7,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label={t('knowledge.list.search.search_label')}
              placeholder={t('knowledge.list.search.search_placeholder') as string}
              value={newFilters.searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewFilters({ ...newFilters, searchTerm: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={4}>
            <SelectWithReset
              label={t('knowledge.list.search.position_label')}
              labelId='position-label'
              id='position'
              name='position'
              value={newFilters.position}
              handleChange={handleChange}
              handleReset={() => setNewFilters({ ...newFilters, position: '' })}
            >
              {filters !== undefined &&
                filters.positions.map((position: KnowledgeFilterPositionType) => {
                  return (
                    <MenuItem value={position.id} key={position.id}>
                      {i18n.language === 'en' ? position.translation.en : position.translation.fr}
                    </MenuItem>
                  )
                })}
            </SelectWithReset>
          </Grid>

          <Grid item xs={4}>
            <SelectWithReset
              label={t('knowledge.list.search.status_label')}
              labelId='status-label'
              id='status'
              name='status'
              value={newFilters.status}
              handleChange={handleChange}
              handleReset={() => setNewFilters({ ...newFilters, status: '' })}
            >
              {knowledgeStatusNames.map((status) => {
                return (
                  <MenuItem value={`${status.value}`} key={status.value}>
                    {t(`knowledge.status.${status.label}`)}
                  </MenuItem>
                )
              })}
            </SelectWithReset>
          </Grid>

          <Grid item xs={4}>
            <SelectWithReset
              label={t('knowledge.list.search.owner_label')}
              labelId='owner-label'
              id='owner'
              name='owner'
              value={newFilters.owner}
              handleChange={handleChange}
              handleReset={() => setNewFilters({ ...newFilters, owner: '' })}
            >
              {filters !== undefined &&
                filters.owners.map((owner: KnowledgeFilterOwnerType) => {
                  return (
                    <MenuItem value={owner.id} key={owner.id}>
                      {owner.name}
                    </MenuItem>
                  )
                })}
            </SelectWithReset>
          </Grid>
        </Grid>

        {(newFilters.searchTerm ||
          newFilters.position ||
          newFilters.status ||
          newFilters.owner) && (
          <Box borderTop='1px solid #cccccc' marginTop={4} paddingTop={4} textAlign='right'>
            <Button
              variant='text'
              color='secondary'
              endIcon={
                <Cached
                  sx={{
                    color: (theme: any) => theme.palette.primary.dark, // eslint-disable-line @typescript-eslint/no-explicit-any
                  }}
                />
              }
              onClick={handleClearAllFilters}
            >
              {t('common.buttons.reset_search')}
            </Button>
            <Button
              type='submit'
              variant='outlined'
              color='secondary'
              sx={{
                marginLeft: 2,
              }}
            >
              {t('common.buttons.search')}
            </Button>
          </Box>
        )}
      </BaseContainer>
    </>
  )
}

export default KnowledgeSearch
