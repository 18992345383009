import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container, ThemeProvider } from '@mui/material'
import theme from '../../common/utils/theme'
import LoginImage from '../../assets/AERO-photologin.jpg'

export default function RootLayout() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          sx={{
            width: '50%',
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
          }}
        >
          {/* <img src={LoginImage} alt='Aero Montreal' /> */}
        </Box>
        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <Container maxWidth='xs'>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
