import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useEmployeesListQuery } from '../../employees/employeesApiSlice'
import SelectEmployeeItem from './SelectEmployeeItem'
import SelectEmployeeProps from '../../../types/users/add/SelectEmployeeProps'
import AutocompleteSelectedEmployeeType from '../../../types/components/AutocompleteSelectedEmployeeType'
import EmployeeType from '../../../types/employees/EmployeeType'

function SelectEmployee({
  id,
  selectedEmployee,
  handleEmployeeChange,
  validationErrors,
}: SelectEmployeeProps) {
  const { t } = useTranslation()
  const { data: employees } = useEmployeesListQuery('')
  const filteredEmployees =
    employees &&
    employees.filter((employee: EmployeeType) => {
      return employee.userId === '00000000-0000-0000-0000-000000000000'
    })

  const autocompleteEmployees: AutocompleteSelectedEmployeeType[] =
    filteredEmployees &&
    filteredEmployees.map((employee: EmployeeType) => {
      return {
        id: employee.employeeId,
        fullName: employee.fullName,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        position: employee.position,
      }
    })

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    newValue: AutocompleteSelectedEmployeeType | null,
  ) => {
    handleEmployeeChange(newValue)
  }

  const adminUsersFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AutocompleteSelectedEmployeeType) => option.fullName + option.email,
  })

  return (
    <Autocomplete
      fullWidth
      id={id}
      value={selectedEmployee || null}
      onChange={handleChange}
      options={autocompleteEmployees || []}
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={adminUsersFilterOptions}
      filterSelectedOptions
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <SelectEmployeeItem option={option} />
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          error={validationErrors}
          helperText={validationErrors ? t('errors.forms.mandatory_field') : ''}
          {...params}
          label={t('settings.users.form.employee_label')}
        />
      )}
      sx={{
        marginBottom: 2,
        '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
          bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
      }}
    />
  )
}

export default SelectEmployee
