import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import RouteRequireRolesProps from '../../types/auth/RouteRequireRolesProps'
import { selectCurrentRole } from './authSlice'

function RouteRequireRoles({ roles }: RouteRequireRolesProps) {
  const userRole = useSelector(selectCurrentRole).name
  const show = Boolean(roles.find((role) => userRole === role))
  return show ? <Outlet /> : <Navigate to='/' replace />
}

export default RouteRequireRoles
