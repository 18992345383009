import apiSlice from '../../app/api/apiSlice'

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    organizationsList: builder.query({
      query: () => ({
        url: '/company',
      }),
      providesTags: ['Organizations'],
    }),
    organizationProfile: builder.query({
      query: (arg) => ({
        url: `/company/${arg}`,
      }),
      providesTags: ['Organization'],
    }),
    organizationAdd: builder.mutation({
      query: (data) => ({
        url: `/company`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Organizations'],
    }),
    organizationEdit: builder.mutation({
      query: ({ id, data }) => ({
        url: `/company/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Organization', 'Organizations'],
    }),
  }),
})

export const {
  useOrganizationsListQuery,
  useOrganizationProfileQuery,
  useOrganizationAddMutation,
  useOrganizationEditMutation,
} = organizationsApiSlice
