import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import PrivateRootLayout from '../components/layout/PrivateRootLayout'
import PublicRootLayout from '../components/layout/PublicRootLayout'
import Login from '../features/auth/Login'
import Dashboard from '../pages/Dashboard'
import KnowledgeList from '../features/knowledge/KnowledgeList'
import Knowledge from '../features/knowledge/view/Knowledge'
import KnowledgeAdd from '../features/knowledge/add/KnowledgeAdd'
import EmployeesList from '../features/employees/EmployeesList'
import OrganizationProfile from '../features/organizations/OrganizationProfile'
import OrganizationsList from '../features/organizations/OrganizationsList'
import UsersList from '../features/users/list/UsersList'
import Faq from '../pages/Faq'
import Lexicon from '../features/Lexicon'
import Contact from '../pages/Contact'
import Profile from '../features/Profile'
import NotFound from '../pages/NotFound'
import ForgotPassword from '../features/users/password/ForgotPassword'
import PasswordReset from '../features/users/password/passwordReset'
import CreateAccount from '../features/users/add/CreateAccount'
import RouteRequireAuth from '../features/auth/RouteRequireAuth'
import PersistLogin from '../features/auth/PersistLogin'
import RouteRequireUnAuth from '../features/auth/RouteRequireUnAuth'
import RouteRequireRoles from '../features/auth/RouteRequireRoles'
import KnowledgeDetails from '../features/knowledge/details/KnowledgeDetails'
import { ADMIN, EXPERT, ORGADMIN, SUPERADMIN } from '../common/data/roleNames'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />}>
      <Route element={<PersistLogin />}>
        <Route element={<RouteRequireUnAuth />}>
          <Route element={<PublicRootLayout />}>
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/password-reset' element={<PasswordReset />} />
            <Route path='/create-user' element={<CreateAccount />} />
          </Route>
        </Route>

        <Route element={<RouteRequireAuth />}>
          <Route path='/' element={<PrivateRootLayout />}>
            <Route index element={<Dashboard />} />

            <Route element={<RouteRequireRoles roles={[ADMIN, SUPERADMIN, EXPERT]} />}>
              <Route path='/knowledge' element={<KnowledgeList />} />
              <Route path='/knowledge/:id' element={<Knowledge />} />
              <Route path='/knowledge/:id/details' element={<KnowledgeDetails />} />
              <Route path='/organization/profile' element={<OrganizationProfile />} />
            </Route>

            <Route element={<RouteRequireRoles roles={[ADMIN, SUPERADMIN]} />}>
              <Route path='/knowledge/add' element={<KnowledgeAdd />} />
              <Route path='/organization/employees' element={<EmployeesList />} />
              <Route path='/settings/users' element={<UsersList />} />
            </Route>

            <Route element={<RouteRequireRoles roles={[ORGADMIN]} />}>
              <Route path='/organization/list' element={<OrganizationsList />} />
              <Route path='/organization/profile/:id' element={<OrganizationProfile />} />
            </Route>

            <Route path='/faq' element={<Faq />} />
            <Route path='/lexicon' element={<Lexicon />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/profile' element={<Profile />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
)

export default router
