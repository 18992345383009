import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add, Delete, Download } from '@mui/icons-material'
import { useEmployeeDeleteMutation, useEmployeesListQuery } from './employeesApiSlice'
import EmployeesImport from './EmployeesImport'
import EmployeeModal from './EmployeeModal'
import EmployeeSearch from './EmployeeSearch'
import EmployeesTable from './EmployeesTable'
import RequireRoles from '../auth/RequireRoles'
import Breadcrumbs from '../../components/Breadcrumbs'
import AlertMessage from '../../components/messages/AlertMessage'
import ConfirmDelete from '../../components/messages/ConfirmDelete'
import KnowledgeTransferModal from '../../components/modal/KnowledgeTransferModal'
import AlertMessageType, { initAlertMessage } from '../../types/components/AlertMessageType'
import EmployeeType, { initEmployee } from '../../types/employees/EmployeeType'
import EmployeesWithKnowledgeToTransferType from '../../types/components/EmployeesWithKnowledgeToTransferType'
import { SUPERADMIN } from '../../common/data/roleNames'

function EmployeesList() {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<readonly string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [newSearchTerm, setNewSearchTerm] = useState('')
  const [openFormModal, setOpenFormModal] = useState(false)
  const [openImportModal, setOpenImportModal] = useState(false)
  const [openKnowledgeTransferModal, setOpenKnowledgeTransferModal] = useState(false)
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeType>(initEmployee)
  const [employeesToTransfer, setEmployeesToTransfer] = useState<
    EmployeesWithKnowledgeToTransferType[]
  >([])
  const [idsToDelete, setIdsToDelete] = useState<readonly string[]>([])
  const [knowledgeTransferFromImport, setKnowledgeTransferFromImport] = useState(false)
  const { data: employeesList } = useEmployeesListQuery({ term: searchTerm })
  const [employeeDelete] = useEmployeeDeleteMutation()

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    setSearchTerm(newSearchTerm)
  }

  const handleFormModalOpen = () => {
    setOpenFormModal(true)
  }

  const handleFormModalClose = () => {
    setOpenFormModal(false)
    setEmployeeToEdit(initEmployee)
  }

  const handleImportModalOpen = () => {
    setOpenImportModal(true)
  }

  const handleImportModalClose = () => {
    setOpenImportModal(false)
  }

  const handleKnowledgeTransferModalClose = () => {
    setOpenKnowledgeTransferModal(false)
    setEmployeesToTransfer([])
  }

  const handleEmployeeToEdit = async (id: string) => {
    await setEmployeeToEdit(
      employeesList.find((employee: EmployeeType) => employee.employeeId === id),
    )
    handleFormModalOpen()
  }

  const handleCancelDelete = () => {
    setIdsToDelete([])
  }

  const handleDeleteMultiple = () => {
    if (selected.length > 0) {
      setIdsToDelete(selected)
    } else {
      setPopupMessage({
        type: 'warning',
        show: true,
        message: t('organization.employees.messages.none_selected'),
      })
    }
  }

  const deleteEmployees = async (ids: readonly string[]) => {
    try {
      const count = ids.length
      const employees: [] = await employeeDelete(ids).unwrap()

      if (employees.length === 0) {
        setSelected([])
        setIdsToDelete([])
        setPopupMessage({
          type: 'success',
          show: true,
          message: t('organization.employees.messages.delete_success', { count }),
        })
      } else {
        const employeesWithoutKnowledge = ids.length - employees.length

        if (employeesWithoutKnowledge > 0) {
          setPopupMessage({
            type: 'success',
            show: true,
            message: t('organization.employees.messages.delete_no_knowledge_success', {
              employeesWithoutKnowledge,
            }),
          })
        }

        setKnowledgeTransferFromImport(false)
        setEmployeesToTransfer(employees)
        setOpenKnowledgeTransferModal(true)
      }

      // eslint-disable-next-line
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.delete_failed'),
        })
      }
    }
    handleCancelDelete()
  }

  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Breadcrumbs />
        <Grid container sx={{ marginBottom: 7 }}>
          <Grid item sx={{ flex: 1 }}>
            <Typography component='h1' variant='h4'>
              {t('organization.employees.page_title')}
            </Typography>
          </Grid>
          <RequireRoles roles={[SUPERADMIN]}>
            <Grid item>
              <Button
                variant='contained'
                endIcon={<Download />}
                onClick={handleImportModalOpen}
                sx={{ marginRight: 2 }}
              >
                {t('organization.employees.buttons.import')}
              </Button>
              <Button variant='contained' endIcon={<Add />} onClick={handleFormModalOpen}>
                {t('organization.employees.buttons.add')}
              </Button>
            </Grid>
          </RequireRoles>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <EmployeeSearch
              newSearchTerm={newSearchTerm}
              setNewSearchTerm={setNewSearchTerm}
              handleSubmit={handleSubmit}
            />
          </Grid>
          <RequireRoles roles={[SUPERADMIN]}>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button
                variant='outlined'
                endIcon={<Delete />}
                color='secondary'
                onClick={handleDeleteMultiple}
                sx={{ marginX: 2 }}
              >
                {t('organization.employees.buttons.delete')}
              </Button>
            </Grid>
          </RequireRoles>
        </Grid>

        <EmployeesTable
          employeesList={employeesList}
          selected={selected}
          setSelected={setSelected}
          handleEmployeeToEdit={handleEmployeeToEdit}
          setIdsToDelete={setIdsToDelete}
        />
      </Box>

      <EmployeeModal
        openModal={openFormModal}
        handleModalClose={handleFormModalClose}
        setPopupMessage={setPopupMessage}
        employeeToEdit={employeeToEdit}
      />

      <EmployeesImport
        openModal={openImportModal}
        handleModalClose={handleImportModalClose}
        popupMessage={popupMessage}
        setPopupMessage={setPopupMessage}
        setEmployeesToTransfer={setEmployeesToTransfer}
        setOpenKnowledgeTransferModal={setOpenKnowledgeTransferModal}
        setKnowledgeTransferFromImport={setKnowledgeTransferFromImport}
      />

      <KnowledgeTransferModal
        openModal={openKnowledgeTransferModal}
        handleModalClose={handleKnowledgeTransferModalClose}
        setPopupMessage={setPopupMessage}
        employeesToTransfer={employeesToTransfer}
        title={t('common.transfer_knowledge_on_delete.employees.title')}
        text={
          knowledgeTransferFromImport
            ? t('common.transfer_knowledge_on_delete.employees.text_import')
            : t('common.transfer_knowledge_on_delete.employees.text')
        }
        cancelAlertText={
          knowledgeTransferFromImport
            ? t('organization.employees.messages.imported_employees_with_knowledge_not_deleted')
            : t('organization.employees.messages.employees_with_knowledge_not_deleted')
        }
        successAlertText={
          knowledgeTransferFromImport
            ? 'organization.employees.messages.import_with_transfer_success'
            : 'organization.employees.messages.delete_transfer_success'
        }
      />

      <ConfirmDelete
        title='organization.employees.messages.confirm_delete_title'
        text='organization.employees.messages.confirm_delete_text'
        buttonText={t('common.buttons.delete')}
        idsToDelete={idsToDelete}
        onConfirm={deleteEmployees}
        onCancel={handleCancelDelete}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default EmployeesList
