import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, SelectChangeEvent, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SelectedSharedTo from './SelectedSharedTo'
import BaseModal from '../../../../components/modal/BaseModal'
import SelectSingleEmployee from '../../../../components/forms/autocomplete/SelectSingleEmployee'
import ShareKnowledgeModalProps from '../../../../types/knowledge/view/shareKnowledge/ShareKnowledgeModalProps'
import AutocompleteSelectedSingleEmployeeType from '../../../../types/components/AutocompleteSelectedSingleEmployeeType'

export default function ShareKnowledgeModal({
  openModal,
  setOpenModal,
  currentSelectedShared,
  newSelectedShared,
  setNewSelectedShared,
  handleSubmit,
  isLoading,
}: ShareKnowledgeModalProps) {
  const { t } = useTranslation()

  const [newSelectedEmployee, setNewSelectedEmployee] =
    useState<AutocompleteSelectedSingleEmployeeType | null>(null)
  const employeesToExclude: string[] =
    newSelectedShared && newSelectedShared.map((employee) => employee.employeeId)

  const handleChangeEmployee = (newValue: AutocompleteSelectedSingleEmployeeType | null) => {
    if (newValue) {
      const newArray = [...newSelectedShared]
      const newItem = {
        employeeId: newValue.id,
        userId: newValue.userId,
        firstName: newValue.firstName,
        lastName: newValue.lastName,
        email: newValue.email,
        role: newValue.role,
        accessLevelName: 'Learner',
      }

      newArray.unshift(newItem)
      setNewSelectedShared(newArray)
    }
  }

  const handleChangeAccessLevel = (event: SelectChangeEvent, employeeId: string) => {
    const { value } = event.target
    const newArray = [...newSelectedShared]
    const index = newArray.findIndex((e) => e.employeeId === employeeId)
    newArray[index].accessLevelName = value
    setNewSelectedShared(newArray)
  }

  const handleDeleteShared = (employeeId: string) => {
    const newArray = [...newSelectedShared]
    const index = newArray.findIndex((e) => e.employeeId === employeeId)
    newArray.splice(index, 1)
    setNewSelectedShared(newArray)
  }

  const cancelForm = () => {
    setOpenModal(false)
    setNewSelectedShared(currentSelectedShared)
    setNewSelectedEmployee(null)
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.share.title')}
      handleClose={cancelForm}
    >
      <Box component='form'>
        <Typography variant='body2' component='p' marginBottom={3}>
          {t('knowledge.view.share.text')}
        </Typography>

        <SelectSingleEmployee
          selectedEmployee={newSelectedEmployee}
          handleEmployeeChange={handleChangeEmployee}
          employeesToExclude={employeesToExclude}
        />

        {newSelectedShared && newSelectedShared.length > 0 && (
          <SelectedSharedTo
            employees={newSelectedShared}
            handleChange={handleChangeAccessLevel}
            handleDelete={handleDeleteShared}
          />
        )}

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={isLoading}
            loadingIndicator={t('common.buttons.save_loading')}
            variant='contained'
            onClick={handleSubmit}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}

ShareKnowledgeModal.defaultProps = {
  isLoading: false,
}
