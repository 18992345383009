export default interface AddKnowledgeDocumentType {
  file: File | undefined
  name: string
  description: string
}

export const initAddKnowledgeDocumentType = {
  file: undefined,
  name: '',
  description: '',
}
