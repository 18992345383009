import React from 'react'
import { Box, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import InfoBoxProps from '../types/components/InfoBoxProps'

function InfoBox({ title, text }: InfoBoxProps) {
  return (
    <Box
      padding={2}
      sx={{
        bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
      }}
    >
      <Box display='flex' alignItems='center' marginBottom={1}>
        <InfoOutlined sx={{ color: 'secondary.light', marginRight: 1.5 }} />
        <Typography variant='subtitle2' component='h3'>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant='body2' component='p'>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default InfoBox
