import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './authSlice'

function RouteRequireUnAuth() {
  const token = useSelector(selectCurrentToken)

  return !token ? <Outlet /> : <Navigate to='/' replace />
}

export default RouteRequireUnAuth
