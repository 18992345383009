import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, SelectChangeEvent, Typography } from '@mui/material'
import { Person } from '@mui/icons-material'
import ShareKnowledgeModal from '../../view/shareKnowledge/ShareKnowledgeModal'
import SelectedSharedTo from '../../view/shareKnowledge/SelectedSharedTo'
import BaseContainer from '../../../../components/BaseContainer'
import InfoBox from '../../../../components/InfoBox'
import ShareKnowledgeProps from '../../../../types/knowledge/add/ShareKnowledgeProps'
import SelectedSharedToType from '../../../../types/knowledge/view/shareKnowledge/SelectedSharedToType'

function ShareKnowledge({ newKnowledge, setNewKnowledge }: ShareKnowledgeProps) {
  const { t } = useTranslation()
  const [openShareModal, setOpenShareModal] = useState(false)

  const currentSelectedShared: SelectedSharedToType[] = newKnowledge.employeeShared

  const [newSelectedShared, setNewSelectedShared] =
    useState<SelectedSharedToType[]>(currentSelectedShared)

  const handleChangeAccessLevel = (event: SelectChangeEvent, employeeId: string) => {
    const { value } = event.target
    const newArray = [...newSelectedShared]
    const index = newArray.findIndex((e) => e.employeeId === employeeId)
    newArray[index].accessLevelName = value
    setNewSelectedShared(newArray)

    setNewKnowledge({
      ...newKnowledge,
      employeeShared: newArray,
    })
  }

  const handleDeleteShared = (employeeId: string) => {
    const newArray = [...newSelectedShared]
    const index = newArray.findIndex((e) => e.employeeId === employeeId)
    newArray.splice(index, 1)
    setNewSelectedShared(newArray)

    setNewKnowledge({
      ...newKnowledge,
      employeeShared: newArray,
    })
  }

  const handleShareSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    setNewKnowledge({
      ...newKnowledge,
      employeeShared: newSelectedShared,
    })

    setOpenShareModal(false)
  }

  return (
    <>
      <BaseContainer>
        <Typography variant='subtitle2' component='h2' marginBottom={1}>
          {t('knowledge.add.step_3.share_knowledge_title')}
        </Typography>

        <Typography variant='body2' component='p' marginBottom={2}>
          {t('knowledge.add.step_3.share_knowledge_text')}
        </Typography>

        {newSelectedShared && newSelectedShared.length > 0 && (
          <SelectedSharedTo
            employees={newSelectedShared}
            handleChange={handleChangeAccessLevel}
            handleDelete={handleDeleteShared}
          />
        )}

        <Button
          variant='contained'
          startIcon={<Person />}
          onClick={() => setOpenShareModal(true)}
          sx={{ marginBottom: 3 }}
        >
          {t('common.buttons.share')}
        </Button>

        <InfoBox
          title={t('knowledge.add.step_3.info_title')}
          text={t('knowledge.add.step_3.info_text')}
        />
      </BaseContainer>

      <ShareKnowledgeModal
        openModal={openShareModal}
        setOpenModal={setOpenShareModal}
        currentSelectedShared={currentSelectedShared}
        newSelectedShared={newSelectedShared}
        setNewSelectedShared={setNewSelectedShared}
        handleSubmit={handleShareSubmit}
      />
    </>
  )
}

export default ShareKnowledge
