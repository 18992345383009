import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import ButtonBarProps from '../../../types/knowledge/add/ButtonBarProps'

function ButtonsBar({
  cancel,
  back,
  next,
  save,
  handleClickCancel,
  handleClickBack,
  handleClickNext,
  handleClickSave,
}: ButtonBarProps) {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item xs={6}>
        {back && (
          <Button
            variant='outlined'
            color='secondary'
            startIcon={<ArrowBack />}
            onClick={handleClickBack}
            sx={{
              marginRight: 1,
            }}
          >
            {t('common.buttons.back')}
          </Button>
        )}

        {cancel && (
          <Button variant='outlined' color='secondary' onClick={handleClickCancel}>
            {t('common.buttons.cancel')}
          </Button>
        )}
      </Grid>

      <Grid item xs={6} display='flex' justifyContent='right'>
        {next && (
          <Button variant='contained' endIcon={<ArrowForward />} onClick={handleClickNext}>
            {t('common.buttons.next')}
          </Button>
        )}

        {save && (
          <Button variant='contained' onClick={handleClickSave}>
            {t('common.buttons.save')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default ButtonsBar
