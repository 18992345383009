import { lazy, object } from 'yup'

const transferKnowledgeSchema = lazy((value) => {
  // eslint-disable-next-line
  const shapes: any = {}
  const key = Object.keys(value)

  key.forEach((parameter) => {
    shapes[parameter] = object().required()
  })

  return object().shape(shapes)
})

export const initValidationErrors = {}

export default transferKnowledgeSchema
