import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useGetManageableRolesQuery, useUserAddMutation } from '../usersApiSlice'
import SelectEmployee from './SelectEmployee'
import SelectedEmployeeRow from './SelectedEmployeeRow'
import BaseModal from '../../../components/modal/BaseModal'
import BaseSelect from '../../../components/forms/BaseSelect'
import AddUserModalProps from '../../../types/users/add/AddUserModalProps'
import ManageableRolesType from '../../../types/users/ManageableRolesType'
import AutocompleteSelectedEmployeeType from '../../../types/components/AutocompleteSelectedEmployeeType'
import validateForm from '../../../common/validations/validateForm'
import validateFields from '../../../common/validations/validateFields'
import addUserSchema, { initValidationErrors } from '../../../common/validations/addUserSchema'

function AddUserModal({ openModal, handleModalClose, setPopupMessage }: AddUserModalProps) {
  const { i18n, t } = useTranslation()
  const { data: roles } = useGetManageableRolesQuery('Admin')
  const [addUser, { isLoading: addUserLoading }] = useUserAddMutation()
  const [selectedEmployee, setSelectedEmployee] =
    useState<AutocompleteSelectedEmployeeType | null>()
  const [selectedRole, setSelectedRole] = useState('Admin')
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const filteredRoles = roles && roles.filter((role: ManageableRolesType) => role.canManage)

  const handleEmployeeChange = (newValue: AutocompleteSelectedEmployeeType | null) => {
    setSelectedEmployee(newValue)
    setValidationErrors({ ...validationErrors, employeeId: false })
  }

  const handleRoleChange = (e: SelectChangeEvent) => {
    const { value } = e.target
    setSelectedRole(value)
  }

  const cancelForm = () => {
    setSelectedEmployee(null)
    setSelectedRole('Admin')
    handleModalClose()
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const userToAdd = { employeeId: selectedEmployee?.id, roleName: selectedRole }

    const isFormValid = await validateForm(addUserSchema, userToAdd)

    if (isFormValid) {
      try {
        await addUser(userToAdd).unwrap()
        const successMessage = t('settings.users.messages.add_success')

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        cancelForm()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(addUserSchema, userToAdd)
      setValidationErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('settings.users.form.add_user')}
      handleClose={cancelForm}
    >
      <Box component='form' onSubmit={handleSubmit}>
        <Typography variant='body2' component='p' marginBottom={3}>
          {t('settings.users.form.add_user_text')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <SelectEmployee
              id='employee'
              selectedEmployee={selectedEmployee}
              handleEmployeeChange={handleEmployeeChange}
              validationErrors={validationErrors.employeeId}
            />
          </Grid>
          <Grid item xs={4}>
            <BaseSelect
              label={t('settings.users.form.role_label')}
              labelId='role-label'
              id='role'
              name='role'
              value={selectedRole}
              handleChange={handleRoleChange}
            >
              {filteredRoles &&
                filteredRoles.map((role: ManageableRolesType) => {
                  const roleLabel =
                    i18n.language === 'en' ? role.translation.en : role.translation.fr
                  return (
                    <MenuItem value={`${role.name}`} key={role.name}>
                      {roleLabel}
                    </MenuItem>
                  )
                })}
            </BaseSelect>
          </Grid>
        </Grid>

        {selectedEmployee && (
          <>
            <Divider sx={{ marginTop: 3 }} />
            <SelectedEmployeeRow employee={selectedEmployee} />
          </>
        )}

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={addUserLoading}
            loadingIndicator={t('common.buttons.save_loading')}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}

export default AddUserModal
