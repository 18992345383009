import React from 'react'
import { useSelector } from 'react-redux'
import RequireRolesProps from '../../types/auth/RequireRolesProps'
import { selectCurrentRole } from './authSlice'

function RequireRoles({ roles, children }: RequireRolesProps) {
  const userRole = useSelector(selectCurrentRole).name
  const show = Boolean(roles.find((role) => userRole === role))
  // eslint-disable-next-line
  return show ? <>{children}</> : <></>
}

export default RequireRoles
