import apiSlice from '../../app/api/apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    usersList: builder.query({
      query: (arg) => ({
        url: '/account/search',
        params: { ...arg },
      }),
      providesTags: ['Users'],
      transformResponse: (response) => response.users,
    }),
    adminList: builder.query({
      query: (arg) => ({
        url: '/account/search-admin',
        params: { ...arg },
      }),
      providesTags: ['Users'],
    }),
    superAdminList: builder.query({
      query: () => ({
        url: '/account/super-admins',
      }),
      providesTags: ['Users'],
    }),
    userAdd: builder.mutation({
      query: ({ employeeId, roleName }) => ({
        url: '/account/generate-confirmation-email',
        method: 'POST',
        body: {
          employeeId,
          roleName,
        },
      }),
      invalidatesTags: ['Users', 'Employees'],
    }),
    userCreate: builder.mutation({
      query: ({ token, password, email }) => ({
        url: '/account',
        method: 'POST',
        body: {
          token,
          password,
          email,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    userChangeRole: builder.mutation({
      query: ({ userId, roleName }) => ({
        url: '/account/change-user-role',
        method: 'PUT',
        body: {
          userId,
          roleName,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    usersDisable: builder.mutation({
      query: (ids) => ({
        url: '/account',
        method: 'DELETE',
        body: {
          userIdList: ids,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    sendConfirmationEmail: builder.mutation({
      query: ({ employeeId, roleName }) => ({
        url: '/account/generate-confirmation-email',
        method: 'POST',
        body: {
          employeeId,
          roleName,
        },
      }),
    }),
    sendPasswordResetEmail: builder.mutation({
      query: ({ email }) => ({
        url: `/account/generate-password-reset-email/${email}`,
        method: 'POST',
      }),
    }),
    passwordReset: builder.mutation({
      query: ({ token, password, email }) => ({
        url: '/account/password-reset',
        method: 'POST',
        body: {
          token,
          password,
          email,
        },
      }),
    }),
    getManageableRoles: builder.query({
      query: () => ({
        url: '/account/get-manageable-roles',
      }),
    }),
    userChangeOwner: builder.mutation({
      query: (data) => ({
        url: `/account/change-owner-for-user`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
})

export const {
  useUsersListQuery,
  useAdminListQuery,
  useSuperAdminListQuery,
  useUserAddMutation,
  useUserCreateMutation,
  useUserChangeRoleMutation,
  useUsersDisableMutation,
  useSendConfirmationEmailMutation,
  useSendPasswordResetEmailMutation,
  usePasswordResetMutation,
  useGetManageableRolesQuery,
  useUserChangeOwnerMutation,
} = usersApiSlice

export const selectUsersResult = usersApiSlice.endpoints.usersList.select('')
