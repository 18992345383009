import React from 'react'
import { AvatarGroup } from '@mui/material'
import AvatarRegular from '../../../../components/AvatarRegular'
import { KnowledgeUserAccessLevelType } from '../../../../types/knowledge/view/KnowledgeType'
import SharedToProps from '../../../../types/knowledge/view/shareKnowledge/SharedToProps'

function SharedTo({ sharedUsers }: SharedToProps) {
  return (
    <AvatarGroup
      max={2}
      sx={{
        marginRight: 3,
        '& .MuiAvatarGroup-avatar': {
          backgroundColor: 'secondary.light',
          borderColor: '#e4e4e4',
        },
      }}
    >
      {sharedUsers.map((user: KnowledgeUserAccessLevelType) => {
        return (
          <AvatarRegular
            key={user.userId}
            firstName={user.firstName}
            lastName={user.lastName}
            fullName={`${user.firstName} ${user.lastName}`}
            sx={{ backgroundColor: 'secondary.main' }}
          />
        )
      })}
    </AvatarGroup>
  )
}

export default SharedTo
