import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useEmployeesListQuery } from '../../../features/employees/employeesApiSlice'
import EmployeeType from '../../../types/employees/EmployeeType'
import AutocompleteSelectedEmployeeType from '../../../types/components/AutocompleteSelectedEmployeeType'
import SelectEmployeesProps from '../../../types/components/SelectEmployeesProps'

function SelectEmployees({ selectedEmployees, handleEmployeesChange }: SelectEmployeesProps) {
  const { t } = useTranslation()
  const { data: employees } = useEmployeesListQuery('')

  const autocompleteEmployees: AutocompleteSelectedEmployeeType[] =
    employees &&
    employees.map((employee: EmployeeType) => {
      return {
        id: employee.employeeId,
        fullName: employee.fullName,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        position: employee.position,
      }
    })

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    newValue: AutocompleteSelectedEmployeeType[],
  ) => {
    handleEmployeesChange(newValue)
  }

  const employeesFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AutocompleteSelectedEmployeeType) => option.fullName + option.email,
  })

  return (
    <Autocomplete
      multiple
      id='employees'
      value={selectedEmployees || []}
      onChange={handleChange}
      options={autocompleteEmployees || []}
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={employeesFilterOptions}
      filterSelectedOptions
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.fullName}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('knowledge.add.step_1.form.employees_label')} />
      )}
      sx={{
        marginBottom: 2,
        '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
          bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
      }}
    />
  )
}

export default SelectEmployees
