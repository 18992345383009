import React from 'react'
import { t } from 'i18next'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import ReadExampleLink from '../../../components/ReadExampleLink'
import EmptyAnswer from '../../../components/EmptyAnswer'
import KnowledgeDetailQuestionProps from '../../../types/knowledge/details/KnowledgeDetailQuestionProps'

function KnowledgeDetailAnswerList({
  code,
  options,
}: Pick<KnowledgeDetailQuestionProps, 'code' | 'options'>) {
  return (
    <Box>
      {options && options.some((option) => option.answer) ? (
        <Grid sx={{ marginBottom: 2, marginTop: -1 }}>
          {options
            .filter((option) => option.answer)
            .map((op) => (
              <Grid key={op.id} sx={{ marginBottom: -2 }}>
                <Typography variant='body2' component='span'>
                  <List sx={{ listStyleType: 'disc', pl: 3 }}>
                    <ListItem key={op.id} sx={{ display: 'list-item' }}>
                      {t(`knowledge.details.${code}.options.${op.code}`)}
                      <ReadExampleLink title={`knowledge.details.${code}.options.${op.code}`} />
                    </ListItem>
                  </List>
                </Typography>
              </Grid>
            ))}
        </Grid>
      ) : (
        <EmptyAnswer />
      )}
    </Box>
  )
}

export default KnowledgeDetailAnswerList
