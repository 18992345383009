import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions } from '@mui/material'
import BaseModal from '../modal/BaseModal'
import ConfirmDeleteProps from '../../types/components/ConfirmDeleteProps'

function ConfirmDelete({
  title,
  text,
  buttonText,
  idsToDelete,
  onConfirm,
  onCancel,
}: ConfirmDeleteProps) {
  const { t } = useTranslation()
  const open = Boolean(idsToDelete.length)
  const count = idsToDelete.length

  return (
    <BaseModal openModal={open} title={t(title, { count })} handleClose={onCancel}>
      {t(text, { count })}
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button
          variant='contained'
          color='primary'
          autoFocus
          onClick={() => onConfirm(idsToDelete)}
        >
          {buttonText}
        </Button>
        <Button variant='text' color='secondary' autoFocus onClick={onCancel}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default ConfirmDelete
