import React from 'react'
import { Button } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { t } from 'i18next'

function GuideButton() {
  return (
    <Button
      variant='text'
      color='secondary'
      endIcon={
        <OpenInNew
          sx={{
            color: (theme: any) => theme.palette.primary.dark, // eslint-disable-line @typescript-eslint/no-explicit-any
          }}
        />
      }
    >
      {t('knowledge.add.buttons.guide')}
    </Button>
  )
}

export default GuideButton
