import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEmployeeImportMutation } from './employeesApiSlice'
import BaseModal from '../../components/modal/BaseModal'
import FileUpload from '../../components/forms/fileUpload/FileUpload'
import EmployeesImportProps from '../../types/employees/EmployeesImportProps'
import employeeImportSchema from '../../common/validations/employeeImportSchema'
import validateForm from '../../common/validations/validateForm'
import validateFields from '../../common/validations/validateFields'

export default function EmployeesImport({
  openModal,
  handleModalClose,
  popupMessage,
  setPopupMessage,
  setEmployeesToTransfer,
  setOpenKnowledgeTransferModal,
  setKnowledgeTransferFromImport,
}: EmployeesImportProps) {
  const { t } = useTranslation()
  const [employeeCsv, setEmployeeCsv] = useState<File>()
  const [validationErrors, setValidationErrors] = useState(false)
  const [employeeImport, { isLoading: employeeImportLoading }] = useEmployeeImportMutation()
  const acceptedFilesInput = ['text/csv']

  const cancelForm = () => {
    setEmployeeCsv(undefined)
    setValidationErrors(false)
    handleModalClose()
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(employeeImportSchema, { File: employeeCsv })

    if (isFormValid) {
      try {
        if (employeeCsv) {
          const formData = new FormData()
          formData.append('file', employeeCsv, employeeCsv.name)
          const employees = await employeeImport(formData).unwrap()

          if (employees.length === 0) {
            const successMessage = t('organization.employees.messages.import_success')
            setPopupMessage({
              type: 'success',
              show: true,
              message: successMessage,
            })
          } else {
            setKnowledgeTransferFromImport(true)
            setEmployeesToTransfer(employees)
            setOpenKnowledgeTransferModal(true)
          }

          cancelForm()
        }

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(employeeImportSchema, { File: employeeCsv })
      setValidationErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('organization.employees.form.import_employees')}
      handleClose={cancelForm}
    >
      <Box component='form' onSubmit={handleSubmit} noValidate autoComplete='off'>
        <Typography variant='body2' component='p' paddingBottom={2}>
          {t('organization.employees.form.import_text')}
        </Typography>
        <FileUpload
          uploadedFile={employeeCsv}
          setUploadedFile={setEmployeeCsv}
          limitSizeInBytes={3000000}
          acceptedFilesInput={acceptedFilesInput}
          acceptedFilesText='CSV'
          validationErrors={validationErrors}
          popupMessage={popupMessage}
          setPopupMessage={setPopupMessage}
        />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            loading={employeeImportLoading}
            loadingIndicator={t('common.buttons.upload_loading')}
            variant='contained'
          >
            {t('common.buttons.upload')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
