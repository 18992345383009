export default interface EmployeeType {
  employeeId: string
  fullName: string
  firstName: string
  lastName: string
  position: string
  email: string
  userId: string
}

export const initEmployee = {
  employeeId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  position: '',
  email: '',
  userId: '',
}
