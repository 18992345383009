import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import SummaryRow from './SummaryRow'
import CriticalityAnswersProps from '../../../../types/knowledge/add/CriticalityAnswersProps'
import CriticalityQuestionType from '../../../../types/knowledge/add/CriticalityQuestionType'

function CriticalityAnswers({ questions, newKnowledge }: CriticalityAnswersProps) {
  const { t } = useTranslation()

  return (
    <>
      {questions.map((question: CriticalityQuestionType) => {
        const selectedValue = newKnowledge.questions.find((x) => x.id === question.id)
        return (
          <Box key={question.id}>
            <SummaryRow
              title={t(`knowledge.criticality_questions.${question.translationKey}.question`)}
              divider
            >
              {selectedValue && selectedValue.value}
            </SummaryRow>
          </Box>
        )
      })}
    </>
  )
}

export default CriticalityAnswers
