export default interface AddKnowledgeLinkType {
  link: string
  name: string
  description: string
}

export const initAddKnowledgeLinkType = {
  link: '',
  name: '',
  description: '',
}
