import React from 'react'
import { Box } from '@mui/material'

interface StatusBoxProps {
  color: string
}

function StatusBox({ color }: StatusBoxProps) {
  return (
    <Box
      minHeight={12}
      minWidth={12}
      borderRadius={100}
      marginRight={1}
      sx={{ backgroundColor: color }}
    />
  )
}

export default StatusBox
