import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import BaseTableCell from '../../components/tables/BaseTableCell'
import RegularLink from '../../components/RegularLink'
import OrderType from '../../types/OrderType'
import OrganizationListType from '../../types/organizations/OrganizationListType'
import OrganizationsTableProps from '../../types/organizations/OrganizationsTableProps'
import OrganizationsTableHeaderProps from '../../types/organizations/OrganizationsTableHeaderProps'
import OrganizationsTableHeadCellType from '../../types/organizations/OrganizationsTableHeadCellType'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aOrderBy = a[orderBy]
  const bOrderBy = b[orderBy]
  let newAOrderBy
  let newBOrderBy

  if (typeof aOrderBy === 'string' && typeof bOrderBy === 'string') {
    newAOrderBy = aOrderBy.toLowerCase()
    newBOrderBy = bOrderBy.toLowerCase()
  } else {
    newAOrderBy = aOrderBy
    newBOrderBy = bOrderBy
  }

  if (newBOrderBy < newAOrderBy) {
    return -1
  }
  if (newBOrderBy > newAOrderBy) {
    return 1
  }
  return 0
}

// eslint-disable-next-line
function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function TableHeader(props: OrganizationsTableHeaderProps) {
  const { t } = useTranslation()
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof OrganizationListType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const tableHeadCells: readonly OrganizationsTableHeadCellType[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'name',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'city',
    },
    {
      id: 'province',
      numeric: false,
      disablePadding: false,
      label: 'province',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'phone',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'email',
    },
  ]

  return (
    <TableHead>
      <TableRow>
        {tableHeadCells.map((tableHeadCell) => (
          <BaseTableCell
            key={tableHeadCell.id}
            align={tableHeadCell.numeric ? 'right' : 'left'}
            padding={tableHeadCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === tableHeadCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === tableHeadCell.id}
              direction={orderBy === tableHeadCell.id ? order : 'asc'}
              onClick={createSortHandler(tableHeadCell.id)}
            >
              {t(`organization.list.table.${tableHeadCell.label}`)}
              {orderBy === tableHeadCell.id ? (
                <Box component='span' sx={{ display: 'none' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </BaseTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function OrganizationsTable({ organizationsList }: OrganizationsTableProps) {
  const [order, setOrder] = useState<OrderType>('asc')
  const [orderBy, setOrderBy] = useState<keyof OrganizationListType>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrganizationListType,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - organizationsList.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      {organizationsList !== undefined && (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
              <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {stableSort(organizationsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <BaseTableCell component='th' scope='row' padding='none'>
                          <RegularLink to={`/organization/profile/${row.id}`}>
                            {row.name}
                          </RegularLink>
                        </BaseTableCell>
                        <BaseTableCell>{row.city}</BaseTableCell>
                        <BaseTableCell>{row.province}</BaseTableCell>
                        <BaseTableCell>{row.phone}</BaseTableCell>
                        <BaseTableCell>{row.email}</BaseTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <BaseTableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={organizationsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  )
}
