import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, Divider, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEmployeeChangeOwnerMutation } from '../../features/employees/employeesApiSlice'
import { useUserChangeOwnerMutation } from '../../features/users/usersApiSlice'
import BaseModal from './BaseModal'
import UserChange from '../UserChange'
import SelectAdminUser from '../forms/autocomplete/SelectAdminUser'
import KnowledgeTransferModalProps from '../../types/components/KnowledgeTransferModalProps'
import AutocompleteSelectedAdminUserType from '../../types/components/AutocompleteSelectedAdminUserType'
import NewEmployeesSelectedType from '../../types/employees/NewEmployeesSelectedType'
import KnowledgeTransferValidationErrorsType from '../../types/employees/KnowledgeTransferValidationErrorsType'
import validateForm from '../../common/validations/validateForm'
import transferKnowledgeSchema, {
  initValidationErrors,
} from '../../common/validations/transferKnowledgeSchema'
import validateFields from '../../common/validations/validateFields'

export default function KnowledgeTransferModal({
  openModal,
  handleModalClose,
  setPopupMessage,
  employeesToTransfer,
  title,
  text,
  cancelAlertText,
  successAlertText,
  forUsers,
}: KnowledgeTransferModalProps) {
  const { t } = useTranslation()
  const [changeOwner, { isLoading: changeOwnerLoading }] = useEmployeeChangeOwnerMutation()
  const [changeOwnerForUsers, { isLoading: changeOwnerForUsersLoading }] =
    useUserChangeOwnerMutation()
  const [newEmployeesSelected, setNewEmployeesSelected] = useState<NewEmployeesSelectedType>({})
  const [validationErrors, setValidationErrors] =
    useState<KnowledgeTransferValidationErrorsType>(initValidationErrors)
  const employeesToExclude = employeesToTransfer.map((employee) => employee.employeeId)

  useEffect(() => {
    let NewObject = {}
    employeesToTransfer.forEach((employee) => {
      const key = employee.userId
      NewObject = { ...NewObject, [key]: null }
    })

    setNewEmployeesSelected(NewObject)
  }, [employeesToTransfer])

  const handleAdminUserChange = (
    id: string,
    newValue: AutocompleteSelectedAdminUserType | null,
  ) => {
    setNewEmployeesSelected({ ...newEmployeesSelected, [id]: newValue })
    setValidationErrors({ ...validationErrors, [id]: false })
  }

  const cancelForm = () => {
    setValidationErrors(initValidationErrors)
    handleModalClose()
    setPopupMessage({
      type: 'warning',
      show: true,
      message: cancelAlertText,
    })
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(transferKnowledgeSchema, newEmployeesSelected)

    if (isFormValid) {
      try {
        let FormattedEmployeesSelected = {}
        const count = Object.keys(newEmployeesSelected).length

        Object.entries(newEmployeesSelected).forEach((entry) => {
          const [key, value] = entry
          FormattedEmployeesSelected = { ...FormattedEmployeesSelected, [key]: value?.userId }
        })

        if (forUsers) {
          await changeOwnerForUsers(FormattedEmployeesSelected).unwrap()
        } else {
          await changeOwner(FormattedEmployeesSelected).unwrap()
        }

        const successMessage = t(successAlertText, { count })

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        handleModalClose()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(transferKnowledgeSchema, newEmployeesSelected)
      setValidationErrors(errors)
    }
  }

  return (
    <BaseModal openModal={openModal} title={title} handleClose={cancelForm}>
      <Box component='form' onSubmit={handleSubmit}>
        <Typography variant='body2' component='p' marginBottom={4}>
          {text}
        </Typography>

        <Typography variant='subtitle1' component='p' fontWeight={500}>
          {t('common.transfer_knowledge_on_delete.transfer_list')}
        </Typography>

        {employeesToTransfer.map((employee) => {
          return (
            <div key={employee.employeeId}>
              <Divider sx={{ marginY: 2 }} />

              <UserChange user={employee}>
                <SelectAdminUser
                  id={employee.userId}
                  newEmployeesSelected={newEmployeesSelected[employee.userId]}
                  handleAdminUserChange={handleAdminUserChange}
                  validationErrors={validationErrors[employee.userId]}
                  employeesToExclude={employeesToExclude}
                />
              </UserChange>
            </div>
          )
        })}

        <Divider sx={{ marginTop: 2 }} />

        <DialogActions sx={{ justifyContent: 'flex-start', paddingTop: 1 }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={changeOwnerLoading || changeOwnerForUsersLoading}
            loadingIndicator={t('common.buttons.save_loading')}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
