import React from 'react'
import { Step, StepLabel, Stepper } from '@mui/material'
import StepProgressionProps from '../../../types/components/StepProgressionProps'

function StepProgression({ steps, step }: StepProgressionProps) {
  return (
    <Stepper
      activeStep={step}
      sx={{
        '& .MuiSvgIcon-root': {
          color: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
        '& .MuiSvgIcon-root.Mui-completed, & .MuiSvgIcon-root.Mui-active': {
          color: 'primary.dark',
          bgcolor: '#ffffff',
          borderRadius: 50,
        },
        '& .MuiStepIcon-text': {
          fill: '#ffffff',
        },
      }}
    >
      {steps.map((label) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default StepProgression
