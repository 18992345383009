import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'
import BorderlessSelectProps from '../../types/components/BorderlessSelectProps'

function BorderlessSelect({
  label,
  labelId,
  id,
  name,
  value,
  handleChange,
  children,
  hideLabel,
}: BorderlessSelectProps) {
  return (
    <FormControl variant='standard'>
      {!hideLabel && <InputLabel id={labelId}>{label}</InputLabel>}

      <Select
        labelId={labelId}
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        inputProps={{ 'aria-label': label }}
        sx={{
          '&.MuiInput-root:before': {
            content: 'none',
          },
          '&.MuiInput-root:after': {
            borderBottom: 'none',
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default BorderlessSelect

BorderlessSelect.defaultProps = {
  hideLabel: false,
}
