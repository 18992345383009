import { object, string } from 'yup'

const employeeSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  position: string(),
  email: string().email().required(),
})

export const initValidationErrors = {
  firstName: false,
  lastName: false,
  position: false,
  email: false,
}

export default employeeSchema
