import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useEmployeesListQuery } from '../../../features/employees/employeesApiSlice'
import SelectSingleEmployeeProps from '../../../types/components/SelectSingleEmployeeProps'
import AutocompleteSelectedSingleEmployeeType from '../../../types/components/AutocompleteSelectedSingleEmployeeType'
import SharedEmployeeType from '../../../types/knowledge/view/shareKnowledge/SharedEmployeeType'

function SelectSingleEmployee({
  selectedEmployee,
  handleEmployeeChange,
  employeesToExclude,
}: SelectSingleEmployeeProps) {
  const { t } = useTranslation()
  const { data: employees } = useEmployeesListQuery('')
  const filteredEmployees = employeesToExclude
    ? employeesToExclude &&
      employees &&
      employees.filter((employee: SharedEmployeeType) => {
        return !employeesToExclude.includes(employee.employeeId)
      })
    : employees

  const autocompleteEmployees: AutocompleteSelectedSingleEmployeeType[] =
    filteredEmployees &&
    filteredEmployees.map((employee: SharedEmployeeType) => {
      return {
        id: employee.employeeId,
        userId: employee.userId,
        fullName: employee.fullName,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        position: employee.position,
        role: employee.role,
      }
    })

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    newValue: AutocompleteSelectedSingleEmployeeType | null,
  ) => {
    handleEmployeeChange(newValue)
  }

  const employeesFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AutocompleteSelectedSingleEmployeeType) => option.fullName + option.email,
  })

  return (
    <Autocomplete
      fullWidth
      disableCloseOnSelect
      blurOnSelect
      value={selectedEmployee}
      onChange={handleChange}
      options={autocompleteEmployees || []}
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={employeesFilterOptions}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.fullName}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('knowledge.add.step_1.form.employees_label')} />
      )}
      sx={{
        marginBottom: 2,
        '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
          bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
      }}
    />
  )
}

export default SelectSingleEmployee
