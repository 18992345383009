import KnowledgeSortNamesType from '../../types/knowledge/KnowledgeSortNamesType'

const knowledgeSortNames: KnowledgeSortNamesType[] = [
  {
    value: 0,
    label: 'most_recent',
  },
  {
    value: 1,
    label: 'less_recent',
  },
  {
    value: 2,
    label: 'name_asc',
  },
  {
    value: 3,
    label: 'name_desc',
  },
  {
    value: 4,
    label: 'most_critical',
  },
  {
    value: 5,
    label: 'less_critical',
  },
]

export default knowledgeSortNames
