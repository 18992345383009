import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import KnowledgeResultsModal from './KnowledgeResultsModal'
import KnowledgeResultsOverviewProps from '../../../../types/knowledge/view/KnowledgeResultsOverviewProps'

function KnowledgeResultsButton({
  criticalityQuestions,
  criticalityValue,
}: KnowledgeResultsOverviewProps) {
  const { t } = useTranslation()
  const [openResultsModal, setOpenResultsModal] = useState(false)

  const handleOpen = () => {
    setOpenResultsModal(true)
  }

  const handleClose = () => {
    setOpenResultsModal(false)
  }

  return (
    <>
      <Button
        variant='text'
        color='secondary'
        onClick={handleOpen}
        endIcon={
          <Visibility
            sx={{
              color: 'primary.dark',
            }}
          />
        }
      >
        {t(`knowledge.view.overview.see_details`)}
      </Button>
      <KnowledgeResultsModal
        openModal={openResultsModal}
        handleModalClose={handleClose}
        criticalityQuestions={criticalityQuestions}
        criticalityValue={criticalityValue}
      />
    </>
  )
}

export default KnowledgeResultsButton
