import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEmployeeAddMutation, useEmployeeEditMutation } from './employeesApiSlice'
import EmployeeForm from './EmployeeForm'
import BaseModal from '../../components/modal/BaseModal'
import EmployeeModalProps from '../../types/employees/EmployeeModalProps'
import { initEmployee } from '../../types/employees/EmployeeType'
import validateForm from '../../common/validations/validateForm'
import validateFields from '../../common/validations/validateFields'
import employeeSchema, { initValidationErrors } from '../../common/validations/employeeSchema'

export default function EmployeeModal({
  openModal,
  handleModalClose,
  setPopupMessage,
  employeeToEdit,
}: EmployeeModalProps) {
  const { t } = useTranslation()
  const [employee, setEmployee] = useState(initEmployee)
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)
  const [employeeEmailNotAvailable, setEmployeeEmailNotAvailable] = useState(false)
  const [employeeAdd, { isLoading: employeeAddLoading }] = useEmployeeAddMutation()
  const [employeeEdit, { isLoading: employeeEditLoading }] = useEmployeeEditMutation()

  useEffect(() => {
    setEmployee(employeeToEdit)
  }, [employeeToEdit])

  const cancelForm = () => {
    setEmployee(initEmployee)
    setValidationErrors(initValidationErrors)
    setEmployeeEmailNotAvailable(false)
    handleModalClose()
  }

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault()

    const isFormValid = await validateForm(employeeSchema, employee)

    if (isFormValid) {
      try {
        let successMessage
        if (employeeToEdit.employeeId) {
          await employeeEdit(employee).unwrap()
          successMessage = t('organization.employees.messages.edit_success')
        } else {
          await employeeAdd(employee).unwrap()
          successMessage = t('organization.employees.messages.add_success')
        }

        setPopupMessage({
          type: 'success',
          show: true,
          message: successMessage,
        })

        cancelForm()

        // eslint-disable-next-line
      } catch (err: any) {
        if (!err.status) {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.login.server_not_responding'),
          })
        } else if (err.status === 400) {
          setEmployeeEmailNotAvailable(true)
        } else {
          setPopupMessage({
            type: 'error',
            show: true,
            message: t('errors.forms.submit_failed'),
          })
        }
      }
    } else {
      const errors = await validateFields(employeeSchema, employee)
      setValidationErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('organization.employees.form.add_employee')}
      handleClose={cancelForm}
    >
      <Box component='form' onSubmit={handleSubmit}>
        <EmployeeForm
          employee={employee}
          setEmployee={setEmployee}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          employeeEmailNotAvailable={employeeEmailNotAvailable}
          setEmployeeEmailNotAvailable={setEmployeeEmailNotAvailable}
        />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={employeeAddLoading || employeeEditLoading}
            loadingIndicator={t('common.buttons.save_loading')}
          >
            {t('common.buttons.save')}
          </LoadingButton>
          <Button variant='text' color='secondary' autoFocus onClick={cancelForm}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}
