import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Container, createTheme, ThemeProvider, Toolbar } from '@mui/material'
import * as locales from '@mui/material/locale'
import SideMenuDrawer from '../SideMenu/SideMenuDrawer'
import SiteHeader from '../Header/SiteHeader'
import { useGetUserInfoQuery } from '../../features/auth/authApiSlice'
import { selectCurrentUsername, setUserInfo } from '../../features/auth/authSlice'
import theme from '../../common/utils/theme'

type SupportedLocales = keyof typeof locales

export default function RootLayout() {
  const { i18n } = useTranslation()
  const currentLanguage: SupportedLocales = i18n.language === 'en' ? 'enUS' : 'frFR'
  const { data: userInfo } = useGetUserInfoQuery('')
  const loginUser = useSelector(selectCurrentUsername)
  const dispatch = useDispatch()

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[currentLanguage]),
    [currentLanguage],
  )

  useEffect(() => {
    if (userInfo) {
      const {
        name: username,
        employeeId,
        sub: userId,
        companyDetails: { companyId, logo, name },
        firstName,
        lastName,
        position,
        email,
        role,
      } = userInfo
      dispatch(
        setUserInfo({
          username,
          employeeId,
          userId,
          companyDetails: { companyId, logo, name },
          firstName,
          lastName,
          position,
          email,
          role,
        }),
      )
    }
  }, [userInfo, dispatch])

  return (
    <ThemeProvider theme={themeWithLocale}>
      {loginUser && (
        <>
          <SiteHeader />
          <Box sx={{ display: 'flex' }}>
            <SideMenuDrawer />
            <Container
              maxWidth='xl'
              sx={{ flex: 1, paddingRight: '64px !important', paddingLeft: '64px !important' }}
            >
              <main>
                <Toolbar />
                <Outlet />
              </main>
            </Container>
          </Box>
        </>
      )}
    </ThemeProvider>
  )
}
