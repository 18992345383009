import KnowledgeStatusNamesType from '../../types/knowledge/KnowledgeStatusNamesType'

const knowledgeStatusNames: KnowledgeStatusNamesType[] = [
  {
    value: 0,
    label: 'to_document',
    color: '#ed6c02',
  },
  {
    value: 1,
    label: 'in_progress',
    color: '#f8d782',
  },
  {
    value: 2,
    label: 'to_approve',
    color: '#333c49',
  },
  {
    value: 3,
    label: 'documented',
    color: '#2e7d32',
  },
]

export default knowledgeStatusNames
