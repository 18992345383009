import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BaseContainer from '../components/BaseContainer'

function Lexicon() {
  const { t } = useTranslation()
  const words = ['employee_transmitter', 'knowledge', 'criticality']
  const wordsSize = words.length - 1
  return (
    <>
      <Typography component='h1' variant='h4' marginTop={10}>
        {t('lexicon.page_title')}
      </Typography>

      <BaseContainer marginTop={4}>
        {words.map((word, index) => {
          return (
            <>
              <Typography component='h6' variant='subtitle2' marginBottom={2}>
                {t(`lexicon.word.${word}`)}
              </Typography>
              <Typography component='p' variant='body2' marginBottom={index === wordsSize ? 0 : 3}>
                {t(`lexicon.word.${word}_explanation`)}
              </Typography>
            </>
          )
        })}
      </BaseContainer>
    </>
  )
}

export default Lexicon
