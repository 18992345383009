import React from 'react'
import { FormControl, IconButton, InputLabel, Select } from '@mui/material'
import { Clear } from '@mui/icons-material'
import SelectWithResetProps from '../../types/components/SelectWithResetProps'

function SelectWithReset({
  label,
  labelId,
  id,
  name,
  value,
  handleChange,
  handleReset,
  children,
}: SelectWithResetProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        endAdornment={
          <IconButton
            sx={{
              visibility: value !== '' ? 'visible' : 'hidden',
              height: 24,
              width: 24,
              marginRight: 2,
            }}
            onClick={handleReset}
          >
            <Clear fontSize='small' />
          </IconButton>
        }
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectWithReset
