import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import KnowledgeDetailAnswerList from './KnowledgeDetailAnswerList'
import KnowledgeDetailAnswerText from './KnowledgeDetailAnswerText'
import { selectCurrentUserAccessLevel } from '../knowledgeSlice'
import BaseContainer from '../../../components/BaseContainer'
import KnowledgeDetailProps from '../../../types/knowledge/details/KnowledgeDetailProps'
import { CHECKBOX_LIST } from '../../../common/data/knowledgeDetailsTypeCodes'
import { COLLABORATOR, OWNER } from '../../../common/data/accessLevelNames'
import RequireAccessLevel from '../../auth/RequireAccessLevel'

function KnowledgeDetailContainer({
  code,
  questions,
  hasAnswers,
  editModeIndex,
  editModes,
  setEditMode,
}: KnowledgeDetailProps) {
  const { t } = useTranslation()
  const userAccessLevel = useSelector(selectCurrentUserAccessLevel)

  const handleEditState = () => {
    const edit = [...editModes]
    edit[editModeIndex] = true
    setEditMode(edit)
  }

  return (
    <Box marginBottom={6}>
      <Typography variant='h6' component='h2' marginBottom={3}>
        {t(`knowledge.details.${code}.title`)}
      </Typography>

      <BaseContainer>
        <Box marginBottom={3}>
          {questions.map((q) => {
            return (
              <Grid item key={q.id}>
                <Grid container sx={{ marginBottom: 1 }}>
                  <Typography variant='body1' component='p'>
                    {t(`knowledge.details.${code}.${q.code}`)}
                  </Typography>
                </Grid>

                {q.typeCode === CHECKBOX_LIST ? (
                  <KnowledgeDetailAnswerList code={code} options={q.options} />
                ) : (
                  <KnowledgeDetailAnswerText answer={q.answer} />
                )}
              </Grid>
            )
          })}
        </Box>

        <RequireAccessLevel accessLevels={[OWNER, COLLABORATOR]} userAccessLevel={userAccessLevel}>
          <Grid item>
            {hasAnswers ? (
              <Button variant='contained' endIcon={<Edit />} onClick={() => handleEditState()}>
                {t('common.buttons.edit')}
              </Button>
            ) : (
              <Button variant='contained' endIcon={<Add />} onClick={() => handleEditState()}>
                {t('knowledge.details.buttons.add_details')}
              </Button>
            )}
          </Grid>
        </RequireAccessLevel>
      </BaseContainer>
    </Box>
  )
}

export default KnowledgeDetailContainer
