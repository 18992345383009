import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAddKnowledgeKeyResourceMutation } from '../../knowledgeApiSlice'
import BaseModal from '../../../../components/modal/BaseModal'
import SelectedEmployees from '../../add/stepOne/SelectedEmployees'
import SelectEmployees from '../../../../components/forms/autocomplete/SelectEmployees'
import KnowledgeKeyResourceAddModalProps from '../../../../types/knowledge/view/KnowledgeKeyResourceAddModalProps'
import AutocompleteSelectedEmployeeType from '../../../../types/components/AutocompleteSelectedEmployeeType'

function KnowledgeKeyResourceAddModal({
  openModal: open,
  title,
  text,
  onCancel,
  setPopupMessage,
}: KnowledgeKeyResourceAddModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [selectedEmployees, setSelectedEmployees] = useState<AutocompleteSelectedEmployeeType[]>([])
  const handleEmployeesChange = (newValue: AutocompleteSelectedEmployeeType[]) => {
    setSelectedEmployees(newValue)
  }
  const [addKeyResource, { isLoading: addKeyResourceLoading }] =
    useAddKnowledgeKeyResourceMutation()

  const resetSelectedEmployees = () => {
    setSelectedEmployees([])
    onCancel()
  }

  const onConfirm: React.FormEventHandler = async () => {
    try {
      const employeeIds = selectedEmployees.map((e) => e.id)
      const count = employeeIds.length
      await addKeyResource({
        id,
        data: employeeIds,
      }).unwrap()

      setPopupMessage({
        type: 'success',
        show: true,
        message: t('knowledge.view.key_resource.messages.add_success', { count }),
      })

      resetSelectedEmployees()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  return (
    <BaseModal openModal={open} title={t(title)} handleClose={resetSelectedEmployees}>
      <Typography variant='body2' marginBottom={3}>
        {t(text)}
      </Typography>

      <SelectEmployees
        selectedEmployees={selectedEmployees}
        handleEmployeesChange={handleEmployeesChange}
      />

      {selectedEmployees.length > 0 && <SelectedEmployees employees={selectedEmployees} />}

      <DialogActions sx={{ justifyContent: 'flex-start', marginTop: '-20px' }}>
        <LoadingButton
          type='submit'
          loading={addKeyResourceLoading}
          loadingIndicator={t('common.buttons.save_loading')}
          variant='contained'
          onClick={onConfirm}
        >
          {t('common.buttons.save')}
        </LoadingButton>
        <Button variant='text' color='secondary' autoFocus onClick={resetSelectedEmployees}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeKeyResourceAddModal
