import React from 'react'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import AvatarSmall from './AvatarSmall'
import ChangeUserProps from '../types/components/ChangeUserProps'

function UserChange({ user, children }: ChangeUserProps) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={5} display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Box display='flex' alignItems='center' marginBottom={0.5}>
            <AvatarSmall
              firstName={user.firstName}
              lastName={user.lastName}
              fullName={user.fullName}
            />
            <Typography variant='subtitle2' component='p' marginLeft={1}>
              {user.fullName}
            </Typography>
          </Box>

          <Typography variant='body2' component='p' color='secondary' marginBottom={0.5}>
            {user.position}
          </Typography>

          <Typography variant='body2' component='p' color='secondary'>
            {user.email}
          </Typography>
        </Box>

        <Avatar sx={{ bgcolor: 'primary.main' }}>
          <ArrowForward />
        </Avatar>
      </Grid>
      <Grid item xs={7} display='flex' alignItems='center'>
        {children}
      </Grid>
    </Grid>
  )
}

export default UserChange
