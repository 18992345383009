import SelectedSharedToType from '../view/shareKnowledge/SelectedSharedToType'

export default interface NewKnowledgeType {
  name: string
  criticality: number
  positions: {
    id: string
    name: string
  }[]
  employeeBroadcasts: {
    id: string
    fullName: string
    firstName: string
    lastName: string
    email: string
    position: string
  }[]
  employeeShared: SelectedSharedToType[]
  questions: {
    id: string
    value: string
  }[]
}

export const initNewKnowledge = {
  name: '',
  criticality: 0,
  positions: [],
  employeeBroadcasts: [],
  employeeShared: [],
  questions: [],
}
