import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Grid, Typography } from '@mui/material'
import StatusBox from '../../../components/StatusBox'
import AvatarSmall from '../../../components/AvatarSmall'
import KnowledgeTableRowProps from '../../../types/knowledge/KnowledgeTableRowProps'
import knowledgeStatusNames from '../../../common/data/knowledgeStatusNames'

function KnowledgeTableRow({ knowledge }: KnowledgeTableRowProps) {
  const { t, i18n } = useTranslation()
  const status = knowledgeStatusNames

  return (
    <Link to={`/knowledge/${knowledge.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Card
        variant='outlined'
        sx={{
          display: 'flex',
          paddingY: 2,
          paddingLeft: 3,
          paddingRight: 0,
          marginBottom: 2,
          minHeight: 73,
          '&.MuiPaper-root:hover': {
            boxShadow: 2,
          },
        }}
      >
        <Grid container spacing={3} rowSpacing={2}>
          <Grid item xs={4} display='flex' alignItems='center'>
            <Typography variant='body2' component='p'>
              {knowledge.name}
            </Typography>
          </Grid>

          <Grid item xs={3} display='flex' alignItems='center'>
            <Typography variant='body2' component='p'>
              {knowledge.knowledgePositions.map((position) => {
                return i18n.language === 'en' ? (
                  <span key={position.positionTitles.en}>
                    {position.positionTitles.en}
                    <br />
                  </span>
                ) : (
                  <span key={position.positionTitles.fr}>
                    {position.positionTitles.fr}
                    <br />
                  </span>
                )
              })}
            </Typography>
          </Grid>

          <Grid item xs={1.5} display='flex' alignItems='center'>
            <Typography variant='body2' component='p'>
              {knowledge.criticality}
            </Typography>
          </Grid>

          <Grid item xs={2} display='flex' alignItems='center'>
            <StatusBox color={status[knowledge.status].color} />
            <Typography variant='body2' component='p'>
              {t(`knowledge.status.${status[knowledge.status].label}`)}
            </Typography>
          </Grid>

          <Grid item xs={1.5} display='flex' alignItems='center'>
            <AvatarSmall
              firstName={knowledge.owner.firstName}
              lastName={knowledge.owner.lastName}
              fullName={knowledge.owner.fullName}
            />
          </Grid>
        </Grid>
      </Card>
    </Link>
  )
}

export default KnowledgeTableRow
