import React from 'react'
import { SUPERADMIN } from '../../common/data/accessLevelNames'
import RequireAccessLevelProps from '../../types/auth/RequireAccessLevelProps'

function RequireAccessLevel({
  accessLevels,
  userAccessLevel,
  children,
  alternative,
}: RequireAccessLevelProps) {
  accessLevels.push(SUPERADMIN)
  const userHasAccessLevel = Boolean(
    accessLevels.find((accessLevel) => userAccessLevel === accessLevel),
  )

  // eslint-disable-next-line
  return userHasAccessLevel ? <>{children}</> : <>{alternative}</>
}

export default RequireAccessLevel

RequireAccessLevel.defaultProps = {
  alternative: '',
}
