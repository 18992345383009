import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { useUpdateKnowledgeDocumentationMutation } from '../../knowledgeApiSlice'
import KnowledgeDocumentationDocumentForm from './KnowledgeDocumentationDocumentForm'
import BaseModal from '../../../../components/modal/BaseModal'
import validateFields from '../../../../common/validations/validateFields'
import validateForm from '../../../../common/validations/validateForm'
import addDocumentSchema, {
  initAddDocumentValidationErrors,
} from '../../../../common/validations/addKnowledgeDocumentSchema'
import KnowledgeDocumentationEditDocumentModalProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationEditDocumentModalProps'
import AddKnowledgeDocumentType from '../../../../types/knowledge/view/documentation/AddKnowledgeDocumentType'

function KnowledgeDocumentationEditDocumentModal({
  openModal,
  handleModalClose,
  setPopupMessage,
  description,
  name,
  filename,
  id: documentationId,
}: KnowledgeDocumentationEditDocumentModalProps) {
  const { t } = useTranslation()
  const { id: knowledgeId } = useParams()
  const [updateDocumentation, { isLoading: updateDocumentationLoading }] =
    useUpdateKnowledgeDocumentationMutation()

  const [validationDocumentErrors, setValidationDocumentErrors] = useState(
    initAddDocumentValidationErrors,
  )

  const file = new File([], filename)
  const originalAnswers = {
    name,
    description,
    file,
  }
  const [addDocumentForm, setAddDocumentForm] = useState<AddKnowledgeDocumentType>(originalAnswers)

  const handleClose = () => {
    handleModalClose()
    setAddDocumentForm(originalAnswers)
  }

  const handleEditDocumentation = async () => {
    try {
      const formData = new FormData()
      formData.append('Name', addDocumentForm.name)
      formData.append('Description', addDocumentForm.description)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('File', addDocumentForm.file!, addDocumentForm.file!.name)
      formData.append('IsDocument', 'True')

      await updateDocumentation({
        knowledgeId,
        documentationId,
        data: formData,
      }).unwrap()
      const successMessage = t('knowledge.view.documentation.messages.edit_success')

      handleModalClose()

      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleSave = async () => {
    const isFormValid = await validateForm(addDocumentSchema, addDocumentForm)
    if (isFormValid) {
      handleEditDocumentation()
    } else {
      const errors = await validateFields(addDocumentSchema, addDocumentForm)
      setValidationDocumentErrors(errors)
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.documentation.edit_modal_title')}
      handleClose={handleClose}
    >
      <KnowledgeDocumentationDocumentForm
        addDocumentForm={addDocumentForm}
        setAddDocumentForm={setAddDocumentForm}
        validationErrors={validationDocumentErrors}
        setValidationErrors={setValidationDocumentErrors}
      />

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loadingIndicator={t('common.buttons.save_loading')}
          onClick={handleSave}
          loading={updateDocumentationLoading}
          variant='contained'
        >
          {t('common.buttons.save')}
        </LoadingButton>
        <Button variant='text' color='secondary' onClick={handleClose}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeDocumentationEditDocumentModal
