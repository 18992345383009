import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@mui/material'
import EmployeeFormProps from '../../types/employees/EmployeeFormProps'

function EmployeeForm({
  employee,
  setEmployee,
  validationErrors,
  setValidationErrors,
  employeeEmailNotAvailable,
  setEmployeeEmailNotAvailable,
}: EmployeeFormProps) {
  const { t } = useTranslation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setEmployee({ ...employee, [name]: value })
    setValidationErrors({ ...validationErrors, [name]: false })
    if (name === 'email') {
      setEmployeeEmailNotAvailable(false)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type='text'
          name='firstName'
          id='firstName'
          label={t('organization.employees.form.first_name')}
          value={employee.firstName}
          onChange={handleChange}
          error={validationErrors.firstName}
          helperText={validationErrors.firstName ? t('errors.forms.mandatory_field') : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type='text'
          name='lastName'
          id='lastName'
          label={t('organization.employees.form.last_name')}
          value={employee.lastName}
          onChange={handleChange}
          error={validationErrors.lastName}
          helperText={validationErrors.lastName ? t('errors.forms.mandatory_field') : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type='text'
          name='position'
          id='position'
          label={t('organization.employees.form.position')}
          value={employee.position}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type='text'
          name='email'
          id='email'
          label={t('organization.employees.form.email')}
          value={employee.email}
          onChange={handleChange}
          error={validationErrors.email || employeeEmailNotAvailable}
          /* eslint-disable no-nested-ternary */
          helperText={
            validationErrors.email
              ? t('errors.forms.invalid_email')
              : employeeEmailNotAvailable
              ? t('errors.forms.email_exists')
              : ''
          }
        />
      </Grid>
    </Grid>
  )
}

export default EmployeeForm
