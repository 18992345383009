import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore, InfoOutlined } from '@mui/icons-material'
import InfoAccordionProps from '../types/components/InfoAccordionProps'

function InfoAccordion({ title, text, expand, setExpand }: InfoAccordionProps) {
  return (
    <Accordion
      expanded={expand}
      onClick={() => setExpand(!expand)}
      sx={{
        bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='info'
        id='info'
        sx={{
          '& .MuiAccordionSummary-content': {
            alignItems: 'flex-end',
          },
        }}
      >
        <InfoOutlined sx={{ color: 'secondary.light', marginRight: 1.5 }} />
        <Typography variant='subtitle2' component='h3'>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body2' component='p'>
          {text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default InfoAccordion
