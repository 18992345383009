import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '@mui/material'
import { ArrowRightAlt } from '@mui/icons-material'
import { setUserAccessLevel } from '../knowledgeSlice'
import BaseContainer from '../../../components/BaseContainer'
import KnowledgeDetailsOverviewProps from '../../../types/knowledge/view/KnowledgeDetailsOverviewProps'

function KnowledgeDetailsOverview({ userAccessLevel }: KnowledgeDetailsOverviewProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleNavigate = () => {
    dispatch(setUserAccessLevel({ userAccessLevel }))
    navigate(`/knowledge/${id}/details`)
  }

  return (
    <>
      <Typography variant='h6' component='p' marginBottom={3}>
        {t('knowledge.details.page_title')}
      </Typography>
      <Typography variant='body2' component='p' marginBottom={2}>
        {t('knowledge.view.details_overview.text')}
      </Typography>
      <BaseContainer>
        <Typography variant='subtitle2' component='p'>
          {t('knowledge.view.details_overview.empty')}
        </Typography>
      </BaseContainer>

      <Button variant='contained' endIcon={<ArrowRightAlt />} onClick={handleNavigate}>
        {t('knowledge.view.overview.see_details')}
      </Button>
    </>
  )
}

export default KnowledgeDetailsOverview
