import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useAdminListQuery } from '../../../features/users/usersApiSlice'
import SelectAdminUserProps from '../../../types/components/SelectAdminUserProps'
import UserType from '../../../types/users/UserType'
import AutocompleteSelectedAdminUserType from '../../../types/components/AutocompleteSelectedAdminUserType'

function SelectAdminUser({
  id,
  newEmployeesSelected,
  handleAdminUserChange,
  validationErrors,
  employeesToExclude,
}: SelectAdminUserProps) {
  const { t } = useTranslation()
  const { data: adminList } = useAdminListQuery({ term: '' })
  const filteredAdminList = employeesToExclude
    ? employeesToExclude &&
      adminList &&
      adminList.filter((admin: UserType) => {
        return !employeesToExclude.includes(admin.employeeId)
      })
    : adminList

  const autocompleteAdminUsers: AutocompleteSelectedAdminUserType[] =
    filteredAdminList &&
    filteredAdminList.map((admin: UserType) => {
      return {
        userId: admin.userId,
        employeeId: admin.employeeId,
        fullName: admin.fullName,
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        position: admin.position,
      }
    })

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    newValue: AutocompleteSelectedAdminUserType | null,
  ) => {
    handleAdminUserChange(id, newValue)
  }

  const adminUsersFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AutocompleteSelectedAdminUserType) => option.fullName + option.email,
  })

  return (
    <Autocomplete
      fullWidth
      id={id}
      value={newEmployeesSelected || null}
      onChange={handleChange}
      options={autocompleteAdminUsers || []}
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      filterOptions={adminUsersFilterOptions}
      filterSelectedOptions
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.userId}>
            {option.fullName}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          error={validationErrors}
          helperText={validationErrors ? t('errors.forms.mandatory_user') : ''}
          {...params}
          label={t('knowledge.add.step_1.form.employees_label')}
        />
      )}
      sx={{
        marginBottom: 2,
        '&.MuiAutocomplete-root .MuiAutocomplete-tag': {
          bgcolor: (theme: any) => theme.shade.secondary.shade12p, // eslint-disable-line @typescript-eslint/no-explicit-any
        },
      }}
    />
  )
}

export default SelectAdminUser
