export default interface UserType {
  userId: string
  employeeId: string
  fullName: string
  firstName: string
  lastName: string
  position: string
  email: string
  role: {
    name: string
    translation: {
      en: string
      fr: string
    }
  }
  nbrKnowledgeOwner: number
  isActive: string
}

export const initUser = {
  userId: '',
  employeeId: '',
  fullName: '',
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  role: {
    name: '',
    translation: {
      en: '',
      fr: '',
    },
  },
  nbrKnowledgeOwner: 0,
  isActive: '',
}
