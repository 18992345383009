import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { useAddKnowledgeDocumentationMutation } from '../../knowledgeApiSlice'
import KnowledgeDocumentationLinkForm from './KnowledgeDocumentationLinkForm'
import KnowledgeDocumentationRadioButton from './KnowledgeDocumentationRadioButton'
import BaseModal from '../../../../components/modal/BaseModal'
import { DOCUMENT_TYPE, LINK_TYPE } from '../../../../common/data/knowledgeDocumentationType'
import { initAddKnowledgeLinkType } from '../../../../types/knowledge/view/documentation/AddKnowledgeLinkType'
import addLinkSchema, {
  initAddLinkValidationErrors,
} from '../../../../common/validations/addKnowledgeLinkSchema'

import validateFields from '../../../../common/validations/validateFields'
import validateForm from '../../../../common/validations/validateForm'
import KnowledgeDocumentationAddModalProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationAddModalProps'
import AddKnowledgeDocumentType, {
  initAddKnowledgeDocumentType,
} from '../../../../types/knowledge/view/documentation/AddKnowledgeDocumentType'
import KnowledgeDocumentationDocumentForm from './KnowledgeDocumentationDocumentForm'
import addDocumentSchema, {
  initAddDocumentValidationErrors,
} from '../../../../common/validations/addKnowledgeDocumentSchema'

function KnowledgeDocumentationModal({
  openModal,
  handleModalClose,
  setPopupMessage,
}: KnowledgeDocumentationAddModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [selectedDocumentationType, setSelectedDocumentationType] = useState(DOCUMENT_TYPE)
  const [validationLinkErrors, setValidationLinkErrors] = useState(initAddLinkValidationErrors)
  const [addLinkForm, setAddLinkForm] = useState(initAddKnowledgeLinkType)

  const [validationDocumentErrors, setValidationDocumentErrors] = useState(
    initAddDocumentValidationErrors,
  )
  const [addDocumentForm, setAddDocumentForm] = useState<AddKnowledgeDocumentType>(
    initAddKnowledgeDocumentType,
  )

  const [addDocumentation, { isLoading: addDocumentationLoading }] =
    useAddKnowledgeDocumentationMutation()

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDocumentationType(event.target.value)
  }

  const handleClose = () => {
    handleModalClose()
    setAddLinkForm(initAddKnowledgeLinkType)
    setAddDocumentForm(initAddKnowledgeDocumentType)
    setSelectedDocumentationType(DOCUMENT_TYPE)
  }

  const handleAddDocumentation = async () => {
    try {
      const formData = new FormData()
      if (selectedDocumentationType === LINK_TYPE) {
        formData.append('Name', addLinkForm.name)
        formData.append('Description', addLinkForm.description)
        formData.append('Link', addLinkForm.link)
        formData.append('IsDocument', 'False')
      } else {
        formData.append('Name', addDocumentForm.name)
        formData.append('Description', addDocumentForm.description)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        formData.append('File', addDocumentForm.file!, addDocumentForm.file!.name)
        formData.append('IsDocument', 'True')
      }

      await addDocumentation({ id, data: formData }).unwrap()
      const successMessage = t('knowledge.view.documentation.messages.add_success')

      handleClose()

      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.submit_failed'),
        })
      }
    }
  }

  const handleSave = async () => {
    if (selectedDocumentationType === LINK_TYPE) {
      const isFormValid = await validateForm(addLinkSchema, addLinkForm)
      if (isFormValid) {
        handleAddDocumentation()
      } else {
        const errors = await validateFields(addLinkSchema, addLinkForm)
        setValidationLinkErrors(errors)
      }
    } else {
      const isFormValid = await validateForm(addDocumentSchema, addDocumentForm)
      if (isFormValid) {
        handleAddDocumentation()
      } else {
        const errors = await validateFields(addDocumentSchema, addDocumentForm)
        setValidationDocumentErrors(errors)
      }
    }
  }

  return (
    <BaseModal
      openModal={openModal}
      title={t('knowledge.view.documentation.add_modal.title')}
      handleClose={handleModalClose}
    >
      <Typography variant='h6' component='p' marginBottom={2}>
        {t('knowledge.view.documentation.add_modal.documentation_type')}
      </Typography>

      <Grid container alignContent='center' spacing={2} marginBottom={3}>
        <Grid item>
          <KnowledgeDocumentationRadioButton
            handleChange={handleTypeChange}
            selectedType={selectedDocumentationType}
            type={DOCUMENT_TYPE}
          />
        </Grid>
        <Grid item>
          <KnowledgeDocumentationRadioButton
            handleChange={handleTypeChange}
            selectedType={selectedDocumentationType}
            type={LINK_TYPE}
          />
        </Grid>
      </Grid>

      {selectedDocumentationType === DOCUMENT_TYPE ? (
        <KnowledgeDocumentationDocumentForm
          addDocumentForm={addDocumentForm}
          setAddDocumentForm={setAddDocumentForm}
          validationErrors={validationDocumentErrors}
          setValidationErrors={setValidationDocumentErrors}
        />
      ) : (
        <KnowledgeDocumentationLinkForm
          addLinkForm={addLinkForm}
          setAddLinkForm={setAddLinkForm}
          validationErrors={validationLinkErrors}
          setValidationErrors={setValidationLinkErrors}
        />
      )}

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loadingIndicator={t('common.buttons.save_loading')}
          onClick={handleSave}
          loading={addDocumentationLoading}
          variant='contained'
        >
          {t('common.buttons.save')}
        </LoadingButton>
        <Button variant='text' color='secondary' onClick={handleClose}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeDocumentationModal
