import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import SummaryRowProps from '../../../../types/knowledge/add/SummaryRowProps'

function SummaryRow({ title, children, divider }: SummaryRowProps) {
  return (
    <>
      {divider && <Divider />}

      <Grid
        container
        spacing={3}
        paddingTop={2}
        paddingBottom={2}
        marginTop={divider ? 0 : -3}
        minHeight={76}
        display='flex'
        alignItems='center'
        sx={{
          '& .MuiGrid-item': {
            paddingTop: 0,
          },
        }}
      >
        <Grid item xs={6}>
          <Typography variant='subtitle2' component='p'>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' component='p'>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default SummaryRow
