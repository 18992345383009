import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { Image } from '@mui/icons-material'
import LogoContainer from '../../components/LogoContainer'
import OrganizationFormProps from '../../types/organizations/OrganizationFormProps'

function OrganizationForm({
  organization,
  setOrganization,
  validationErrors,
  setValidationErrors,
}: OrganizationFormProps) {
  const { t } = useTranslation()
  const [newLogo, setNewLogo] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setOrganization({ ...organization, [name]: value })
    setValidationErrors({ ...validationErrors, [name]: false })
  }

  const handleChangeLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setOrganization({
        ...organization,
        logo: e.target.files[0],
      })
      setNewLogo(true)
    }
  }

  const formatPhoneNumber = (phone: string) => {
    let newPhone = ''
    if (phone) {
      const numbers = phone.replace(/\D/g, '')
      newPhone = `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6)}`
    }

    setOrganization({ ...organization, phone: newPhone })
  }

  return (
    <Grid container>
      <Grid item xs={8} paddingRight={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='text'
              name='name'
              id='name'
              label={t('organization.profile.form.name')}
              value={organization.name}
              onChange={handleChange}
              error={validationErrors.name}
              helperText={validationErrors.name ? t('errors.forms.mandatory_field') : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type='text'
              name='address'
              id='address'
              label={t('organization.profile.form.address')}
              value={organization.address}
              onChange={handleChange}
              error={validationErrors.address}
              helperText={validationErrors.address ? t('errors.forms.mandatory_field') : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type='text'
              name='suite'
              id='suite'
              label={t('organization.profile.form.suite')}
              value={organization.suite}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type='text'
              name='city'
              id='city'
              label={t('organization.profile.form.city')}
              value={organization.city}
              onChange={handleChange}
              error={validationErrors.city}
              helperText={validationErrors.city ? t('errors.forms.mandatory_field') : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type='text'
              name='province'
              id='province'
              label={t('organization.profile.form.province')}
              value={organization.province}
              onChange={handleChange}
              error={validationErrors.province}
              helperText={validationErrors.province ? t('errors.forms.mandatory_field') : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type='text'
              name='postalCode'
              id='postalCode'
              label={t('organization.profile.form.postal_code')}
              value={organization.postalCode}
              onChange={handleChange}
              error={validationErrors.postalCode}
              helperText={validationErrors.postalCode ? t('errors.forms.mandatory_field') : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type='phone'
              name='phone'
              id='phone'
              label={t('organization.profile.form.phone')}
              value={organization.phone}
              onChange={handleChange}
              onBlur={() => formatPhoneNumber(organization.phone)}
              error={validationErrors.phone}
              helperText={validationErrors.phone ? t('errors.forms.invalid_phone') : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type='text'
              name='email'
              id='email'
              label={t('organization.profile.form.email')}
              value={organization.email}
              onChange={handleChange}
              error={validationErrors.email}
              helperText={validationErrors.email ? t('errors.forms.invalid_email') : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type='text'
              name='websiteUrl'
              id='websiteUrl'
              label={t('organization.profile.form.website')}
              value={organization.websiteUrl}
              onChange={handleChange}
              error={validationErrors.websiteUrl}
              helperText={validationErrors.websiteUrl ? t('errors.forms.invalid_website') : ''}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Typography variant='subtitle2' component='p' marginBottom={2}>
          {t('organization.profile.form.logo')}
        </Typography>

        <LogoContainer>
          {organization.logo ? (
            <img
              src={
                newLogo
                  ? URL.createObjectURL(organization.logo)
                  : `data:image/jpeg;base64,${organization.logo}`
              }
              alt='Logo'
              width='100%'
            />
          ) : (
            <Image
              fontSize='inherit'
              sx={{
                color: (theme: any) => theme.shade.secondary.shade30p, // eslint-disable-line @typescript-eslint/no-explicit-any
              }}
            />
          )}
        </LogoContainer>

        <Button
          variant='outlined'
          color='secondary'
          sx={{ width: '100%', maxWidth: 240, marginTop: 3, padding: 0 }}
        >
          <label
            style={{
              width: '100%',
              padding: '5px 15px',
            }}
            htmlFor='changeLogo'
          >
            <input type='file' name='logo' id='changeLogo' onChange={handleChangeLogo} hidden />
            <Typography variant='subtitle2' component='div'>
              {t('organization.profile.buttons.change_logo')}
            </Typography>
          </label>
        </Button>
      </Grid>
    </Grid>
  )
}

export default OrganizationForm
