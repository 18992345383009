export default interface OrganizationProfileType {
  id?: number
  canEdit: boolean
  name: string
  logo: any // eslint-disable-line
  address: string
  suite: string
  city: string
  province: string
  postalCode: string
  phone: string
  email: string
  websiteUrl: string
}

export const initOrganizationProfile = {
  canEdit: true,
  name: '',
  logo: '',
  address: '',
  suite: '',
  city: '',
  province: '',
  postalCode: '',
  phone: '',
  email: '',
  websiteUrl: '',
}
