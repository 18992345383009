import { mixed, object, string } from 'yup'

// eslint-disable-next-line
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

const organizationSchema = object().shape({
  name: string().required(),
  logo: mixed(),
  address: string().required(),
  suite: string(),
  city: string().required(),
  province: string().required(),
  postalCode: string().required(),
  phone: string().matches(PHONE_REGEX, { excludeEmptyString: true }),
  email: string().email().required(),
  websiteUrl: string().url(),
})

export const initValidationErrors = {
  name: false,
  logo: false,
  address: false,
  suite: false,
  city: false,
  province: false,
  postalCode: false,
  phone: false,
  email: false,
  websiteUrl: false,
}

export default organizationSchema
