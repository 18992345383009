import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectCurrentRole } from '../features/auth/authSlice'
import { EXPERT, ORGADMIN } from '../common/data/roleNames'

function Dashboard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userRole = useSelector(selectCurrentRole)

  useEffect(() => {
    if (userRole.name === EXPERT) {
      navigate('/knowledge', { replace: true })
    }
    if (userRole.name === ORGADMIN) {
      navigate('/organization/list', { replace: true })
    }
  }, [userRole, navigate])

  return <h1>{t('dashboard.page_title')}</h1>
}

export default Dashboard
