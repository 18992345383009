import React, { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography } from '@mui/material'
import CriticalityQuestion from './CriticalityQuestion'
import ButtonsBar from '../ButtonsBar'
import BaseContainer from '../../../../components/BaseContainer'
import KnowledgeAddStepTwoProps from '../../../../types/knowledge/add/KnowledgeAddStepTwoProps'
import CriticalityQuestionType from '../../../../types/knowledge/add/CriticalityQuestionType'
import validateForm from '../../../../common/validations/validateForm'
import validateFields from '../../../../common/validations/validateFields'
import addKnowledgeStepTwoSchema, {
  initValidationErrors,
} from '../../../../common/validations/addKnowledgeStepTwoSchema'

function StepTwo({
  handleNextStep,
  handlePreviousStep,
  handleCancel,
  newKnowledge,
  setNewKnowledge,
  questions,
}: KnowledgeAddStepTwoProps) {
  const { t } = useTranslation()
  const stepTwoRefRef = createRef<HTMLDivElement>()
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)

  const calculateCriticality = () => {
    let criticality = 0
    newKnowledge.questions.forEach((question) => {
      const questionIndex = questions.findIndex(
        (x: CriticalityQuestionType) => x.id === question.id,
      )
      const { weight } = questions[questionIndex]
      const value = weight * +question.value
      criticality += value
    })
    setNewKnowledge({ ...newKnowledge, criticality })
  }

  const handleQuestionChange = (id: string, value: string) => {
    if (newKnowledge.questions.length < 1) {
      setNewKnowledge({ ...newKnowledge, questions: [{ id, value }] })
    } else {
      const questionIndex = newKnowledge.questions.findIndex((x) => x.id === id)
      const newArray = newKnowledge.questions

      if (questionIndex === -1) {
        newArray.push({ id, value })
      } else {
        newArray[questionIndex].value = value
      }

      setNewKnowledge({ ...newKnowledge, questions: newArray })
    }
  }

  const handleClickNext = async () => {
    const isFormValid = await validateForm(addKnowledgeStepTwoSchema, newKnowledge)

    if (isFormValid) {
      calculateCriticality()
      handleNextStep()
    } else {
      const errors = await validateFields(addKnowledgeStepTwoSchema, newKnowledge)
      setValidationErrors(errors)

      if (stepTwoRefRef.current) {
        stepTwoRefRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      }
    }
  }

  return (
    <>
      <Typography variant='h6' component='h2' color='secondary' marginTop={7} ref={stepTwoRefRef}>
        2. {t('knowledge.add.step_2.title')}
      </Typography>

      <BaseContainer sx={{ paddingTop: '32px !important', paddingBottom: '48px !important' }}>
        {questions &&
          questions.map((question: CriticalityQuestionType, index: number) => {
            const selectedValue = newKnowledge.questions.find((x) => x.id === question.id)
            return (
              <Box key={question.id} marginTop={index === 0 ? 1.5 : 0}>
                {index > 0 && <Divider sx={{ marginTop: 1.2, marginBottom: 4 }} />}
                <CriticalityQuestion
                  index={index}
                  question={question}
                  selectedValue={selectedValue ? selectedValue.value : ''}
                  handleQuestionChange={handleQuestionChange}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </Box>
            )
          })}
      </BaseContainer>

      <Typography variant='caption' component='p' marginTop={-1} marginBottom={7}>
        * {t('knowledge.add.mandatory_fields')}
      </Typography>

      <ButtonsBar
        cancel
        back
        next
        handleClickCancel={handleCancel}
        handleClickBack={handlePreviousStep}
        handleClickNext={handleClickNext}
      />
    </>
  )
}

export default StepTwo
