import React, { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Typography } from '@mui/material'
import SelectedEmployees from './SelectedEmployees'
import ButtonsBar from '../ButtonsBar'
import BaseContainer from '../../../../components/BaseContainer'
import NoteBox from '../../../../components/NoteBox'
import SelectPositions from '../../../../components/forms/autocomplete/SelectPositions'
import SelectEmployees from '../../../../components/forms/autocomplete/SelectEmployees'
import KnowledgeAddStepOneProps from '../../../../types/knowledge/add/KnowledgeAddStepOneProps'
import AutocompleteSelectedEmployeeType from '../../../../types/components/AutocompleteSelectedEmployeeType'
import AutocompleteSelectedPositionType from '../../../../types/components/AutocompleteSelectedPositionType'
import validateForm from '../../../../common/validations/validateForm'
import addKnowledgeStepOneSchema, {
  initValidationErrors,
} from '../../../../common/validations/addKnowledgeStepOneSchema'
import validateFields from '../../../../common/validations/validateFields'

function StepOne({
  handleNextStep,
  handleCancel,
  newKnowledge,
  setNewKnowledge,
}: KnowledgeAddStepOneProps) {
  const { t } = useTranslation()
  const stepOneRefRef = createRef<HTMLDivElement>()
  const [validationErrors, setValidationErrors] = useState(initValidationErrors)

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValidationErrors({ ...validationErrors, name: false })
    setNewKnowledge({ ...newKnowledge, name: value })
  }

  const handlePositionsChange = (newValue: AutocompleteSelectedPositionType[]) => {
    setNewKnowledge({ ...newKnowledge, positions: newValue })
  }

  const handleEmployeesChange = (newValue: AutocompleteSelectedEmployeeType[]) => {
    setNewKnowledge({ ...newKnowledge, employeeBroadcasts: newValue })
  }

  const handleClickNext = async () => {
    const isFormValid = await validateForm(addKnowledgeStepOneSchema, newKnowledge)

    if (isFormValid) {
      handleNextStep()
    } else {
      const errors = await validateFields(addKnowledgeStepOneSchema, newKnowledge)
      setValidationErrors(errors)
      if (stepOneRefRef.current) {
        stepOneRefRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      }
    }
  }

  return (
    <>
      <Typography
        variant='h6'
        component='h2'
        color='secondary'
        marginTop={7}
        marginBottom={3}
        ref={stepOneRefRef}
      >
        1. {t('knowledge.add.step_1.title')}
      </Typography>

      <BaseContainer>
        <Typography variant='subtitle2' component='h3' marginBottom={2}>
          {t('knowledge.add.step_1.form.name_title')}*
        </Typography>

        <TextField
          fullWidth
          type='text'
          name='name'
          id='name'
          label={t('knowledge.add.step_1.form.name_label')}
          value={newKnowledge.name}
          onChange={handleNameChange}
          error={validationErrors.name}
          helperText={validationErrors.name ? t('errors.forms.mandatory_field') : ''}
          sx={{ marginBottom: 2 }}
        />
      </BaseContainer>

      <BaseContainer>
        <Typography variant='subtitle2' component='h3' marginBottom={2}>
          {t('knowledge.add.step_1.form.positions_title')}
        </Typography>

        <SelectPositions
          selectedPositions={newKnowledge.positions}
          handlePositionsChange={handlePositionsChange}
        />
      </BaseContainer>

      <BaseContainer>
        <Typography variant='subtitle2' component='h3' marginBottom={1}>
          {t('knowledge.add.step_1.form.employees_title')}
        </Typography>

        <Typography variant='body2' component='p' marginBottom={3}>
          {t('knowledge.add.step_1.form.employees_text')}
        </Typography>

        <SelectEmployees
          selectedEmployees={newKnowledge.employeeBroadcasts}
          handleEmployeesChange={handleEmployeesChange}
        />

        {newKnowledge.employeeBroadcasts.length > 0 && (
          <SelectedEmployees employees={newKnowledge.employeeBroadcasts} />
        )}

        <NoteBox
          title={t('knowledge.add.step_1.form.employees_note_title')}
          text={t('knowledge.add.step_1.form.employees_note_text')}
        />
      </BaseContainer>

      <Typography variant='caption' component='p' marginTop={-1} marginBottom={7}>
        * {t('knowledge.add.mandatory_fields')}
      </Typography>

      <ButtonsBar cancel next handleClickCancel={handleCancel} handleClickNext={handleClickNext} />
    </>
  )
}

export default StepOne
