import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import RequireRoles from '../../auth/RequireRoles'
import { UsersActionsProps } from '../../../types/users/list/UsersActionsProps'
import { SUPERADMIN } from '../../../common/data/roleNames'

export default function UsersActions({
  id,
  sendInvitation,
  handleUserToEdit,
  setIdsToDisable,
}: UsersActionsProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDisable = () => {
    setIdsToDisable([id])
    setAnchorEl(null)
  }

  const handleEdit = () => {
    handleUserToEdit(id)
    setAnchorEl(null)
  }

  const handleClickSendInvitation = () => {
    sendInvitation(id)
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz sx={{ color: open ? 'primary.dark' : 'secondary' }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem onClick={handleClickSendInvitation}>
          {t('settings.users.buttons.resend')}
        </MenuItem>
        <RequireRoles roles={[SUPERADMIN]}>
          <MenuItem onClick={handleEdit}>{t('settings.users.buttons.edit')}</MenuItem>
        </RequireRoles>
        <MenuItem onClick={handleDisable}>{t('settings.users.buttons.disable')}</MenuItem>
      </Menu>
    </>
  )
}
