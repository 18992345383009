import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import RegularLinkProps from '../types/components/RegularLinkProps'

function RegularLink({ to, children }: RegularLinkProps) {
  return (
    <Button
      component={Link}
      to={to}
      sx={{
        color: 'text.secondary',
        textTransform: 'none',
        fontWeight: 'normal',
        textDecoration: 'underline',
        padding: 0,
        justifyContent: 'left',
      }}
    >
      {children}
    </Button>
  )
}

export default RegularLink
