import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import AvatarSmall from '../../../components/AvatarSmall'
import SelectEmployeeItemProps from '../../../types/users/add/SelectEmployeeItemProps'

function SelectEmployeeItem({ option }: SelectEmployeeItemProps) {
  return (
    <>
      <Box display='flex' alignItems='center' marginY={1}>
        <Box marginRight={1}>
          <AvatarSmall
            firstName={option.firstName}
            lastName={option.lastName}
            fullName={option.fullName}
          />
        </Box>

        <Box>
          <Typography variant='body2' component='p'>
            {option.fullName}
          </Typography>
          <Typography variant='body2' component='p' color='secondary'>
            {option.email}
          </Typography>
        </Box>
      </Box>

      <Divider />
    </>
  )
}

export default SelectEmployeeItem
