import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDeleteKnowledgeDocumentationMutation } from '../../knowledgeApiSlice'
import BaseModal from '../../../../components/modal/BaseModal'
import KnowledgeDocumentationDeleteModalProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationDeleteModalProps'

function KnowledgeDocumentationDeleteModal({
  openDeleteModal,
  handleDeleteModalClose,
  id: documentationId,
  setPopupMessage,
}: KnowledgeDocumentationDeleteModalProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [deleteDocumentation, { isLoading: deleteDocumentationLoading }] =
    useDeleteKnowledgeDocumentationMutation()

  const onConfirm: React.FormEventHandler = async () => {
    try {
      await deleteDocumentation({
        knowledgeId: id,
        documentationId,
      }).unwrap()

      const successMessage = t('knowledge.view.documentation.messages.delete_success')
      setPopupMessage({
        type: 'success',
        show: true,
        message: successMessage,
      })

      handleDeleteModalClose()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!err.status) {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.login.server_not_responding'),
        })
      } else {
        setPopupMessage({
          type: 'error',
          show: true,
          message: t('errors.forms.delete_failed'),
        })
      }
    }
  }

  return (
    <BaseModal
      openModal={openDeleteModal}
      title={t('knowledge.view.documentation.messages.confirm_delete_title')}
      handleClose={handleDeleteModalClose}
    >
      <Typography variant='body1'>
        {t('knowledge.view.documentation.messages.confirm_delete_description')}
      </Typography>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          type='submit'
          loading={deleteDocumentationLoading}
          loadingIndicator={t('common.buttons.delete_loading')}
          variant='contained'
          onClick={onConfirm}
        >
          {t('common.buttons.delete')}
        </LoadingButton>
        <Button variant='text' color='secondary' autoFocus onClick={handleDeleteModalClose}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </BaseModal>
  )
}

export default KnowledgeDocumentationDeleteModal
