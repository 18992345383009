import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, TextField, Typography } from '@mui/material'
import FileUpload from '../../../../components/forms/fileUpload/FileUpload'
import KnowledgeDocumentationAddDocumentProps from '../../../../types/knowledge/view/documentation/KnowledgeDocumentationAddDocumentProps'
import AlertMessageType, { initAlertMessage } from '../../../../types/components/AlertMessageType'

function KnowledgeDocumentationDocumentForm({
  addDocumentForm,
  setAddDocumentForm,
  validationErrors,
  setValidationErrors,
}: KnowledgeDocumentationAddDocumentProps) {
  const { t } = useTranslation()
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)
  const acceptedFilesInput = ['application/pdf', 'image/png', 'image/svg+xml', 'image/jpeg']

  const setDocument = (newDocument: File | undefined) => {
    setAddDocumentForm({ ...addDocumentForm, file: newDocument })
    setValidationErrors({ ...validationErrors, file: false })
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddDocumentForm({ ...addDocumentForm, name: event.target.value })
    setValidationErrors({ ...validationErrors, name: false })
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddDocumentForm({ ...addDocumentForm, description: event.target.value })
    setValidationErrors({ ...validationErrors, description: false })
  }

  return (
    <Grid>
      <Typography variant='h6' component='p' marginBottom={2}>
        {t('knowledge.view.documentation.add_modal.upload_document')}
      </Typography>

      <Typography variant='subtitle2' component='p' marginBottom={2}>
        {t('knowledge.view.documentation.add_modal.upload_document_description')}
      </Typography>

      <Box marginBottom={3}>
        <FileUpload
          uploadedFile={addDocumentForm.file}
          setUploadedFile={setDocument}
          limitSizeInBytes={3000000}
          acceptedFilesInput={acceptedFilesInput}
          acceptedFilesText={t('knowledge.view.documentation.add_modal.upload_document_types')}
          validationErrors={validationErrors.file}
          popupMessage={popupMessage}
          setPopupMessage={setPopupMessage}
        />
      </Box>
      <TextField
        fullWidth
        type='text'
        onChange={handleNameChange}
        value={addDocumentForm.name}
        error={validationErrors.name}
        helperText={validationErrors.name ? t('errors.forms.mandatory_field') : ''}
        label={t('knowledge.view.documentation.add_modal.document_name')}
        sx={{ marginBottom: 1 }}
      />
      <TextField
        fullWidth
        type='text'
        multiline
        rows={4}
        value={addDocumentForm.description}
        error={validationErrors.description}
        helperText={validationErrors.description ? t('errors.forms.mandatory_field') : ''}
        onChange={handleDescriptionChange}
        label={t('knowledge.view.documentation.add_modal.description')}
        sx={{ marginBottom: 1 }}
      />
    </Grid>
  )
}

export default KnowledgeDocumentationDocumentForm
