import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import KnowledgeStatusModal from './KnowledgeStatusModal'
import KnowledgeEmployeeBroadcastModal from './KnowledgeEmployeeBroadcastModal'
import ChangePositionsModal from './ChangePositionsModal'
import ChangeEmployeeBroadcastModal from './ChangeEmployeeBroadcastModal'
import KnowledgeChangeStatusModal from './KnowledgeChangeStatusModal'
import KnowledgeResultsButton from '../resultsOverview/KnowledgeResultsButton'
import OverviewRow from './OverviewRow'
import BasicInfo from './BasicInfo'
import BaseContainer from '../../../../components/BaseContainer'
import StatusBox from '../../../../components/StatusBox'
import AlertMessage from '../../../../components/messages/AlertMessage'
import AlertMessageType, { initAlertMessage } from '../../../../types/components/AlertMessageType'
import KnowledgeOverviewProps from '../../../../types/knowledge/view/KnowledgeOverviewProps'
import {
  KnowledgePositionType,
  EmployeeBroadcastType,
} from '../../../../types/knowledge/view/KnowledgeType'
import knowledgeStatusNames from '../../../../common/data/knowledgeStatusNames'
import KnowledgeChangeCriticalitiesModal from './KnowledgeChangeCriticalitiesModal'
import { COLLABORATOR, OWNER } from '../../../../common/data/accessLevelNames'

function KnowledgeOverviewContainer({ knowledge, userAccessLevel }: KnowledgeOverviewProps) {
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [openPositionsModal, setOpenPositionsModal] = useState(false)
  const [openEmployeeBroadcastModal, setOpenEmployeeBroadcastModal] = useState(false)
  const [openChangeEmployeeBroadcastModal, setOpenChangeEmployeeBroadcastModal] = useState(false)
  const [openChangeCriticalitiesModal, setOpenChangeCriticalitiesModal] = useState(false)
  const { t, i18n } = useTranslation()
  const status = knowledgeStatusNames
  const [popupMessage, setPopupMessage] = useState<AlertMessageType>(initAlertMessage)

  const handleStatusModalOpen = () => {
    setOpenStatusModal(true)
  }

  const handleStatusModalClose = () => {
    setOpenStatusModal(false)
  }

  const handleChangeStatusModalOpen = () => {
    setOpenChangeStatusModal(true)
  }

  const handleChangeStatusModalClose = () => {
    setOpenChangeStatusModal(false)
  }

  const handlePositionsModalOpen = () => {
    setOpenPositionsModal(true)
  }

  const handlePositionsModalClose = () => {
    setOpenPositionsModal(false)
  }

  const handleEmployeeBroadcastModalOpen = () => {
    setOpenEmployeeBroadcastModal(true)
  }

  const handleEmployeeBroadcastModalClose = () => {
    setOpenEmployeeBroadcastModal(false)
  }

  const handleChangeEmployeeBroadcastModalOpen = () => {
    setOpenChangeEmployeeBroadcastModal(true)
  }

  const handleChangeEmployeeBroadcastModalClose = () => {
    setOpenChangeEmployeeBroadcastModal(false)
  }

  const handleChangeCriticalitiesModalOpen = () => {
    setOpenChangeCriticalitiesModal(true)
  }

  const handleChangeCriticalitiesModalClose = () => {
    setOpenChangeCriticalitiesModal(false)
  }

  return (
    <>
      <Typography variant='h6' component='p' marginBottom={3}>
        {t('knowledge.view.overview.title')}
      </Typography>

      <Grid container spacing={3} marginBottom={3}>
        <Grid item xs={8}>
          <BaseContainer>
            <OverviewRow
              title={t('knowledge.view.overview.status')}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              modalLinkText={t('knowledge.view.overview.status_question')!}
              modalOpen={handleStatusModalOpen}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              editButtonText={t('knowledge.view.overview.status_change')!}
              editButtonModalOpen={handleChangeStatusModalOpen}
              userAccessLevel={userAccessLevel}
              accessLevels={[OWNER, COLLABORATOR]}
              divider
            >
              <StatusBox color={status[knowledge.status].color} />
              <Typography variant='body2' component='p'>
                {t(`knowledge.status.${status[knowledge.status].label}`)}
              </Typography>
            </OverviewRow>

            <OverviewRow
              title={t('knowledge.view.overview.criticality')}
              button2={
                <KnowledgeResultsButton
                  criticalityQuestions={knowledge.knowledgeCriticalities}
                  criticalityValue={knowledge.criticality}
                />
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              editButtonText={t('knowledge.view.overview.recalculate_criticality')!}
              editButtonModalOpen={handleChangeCriticalitiesModalOpen}
              userAccessLevel={userAccessLevel}
              accessLevels={[OWNER]}
              divider
            >
              <Typography variant='subtitle2' component='span'>
                {knowledge.criticality}
              </Typography>
              <Typography variant='body2' component='span' sx={{ color: 'secondary.light' }}>
                /100
              </Typography>
            </OverviewRow>

            <OverviewRow
              title={t('knowledge.view.overview.position')}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              editButtonText={t('common.buttons.edit')!}
              editButtonModalOpen={handlePositionsModalOpen}
              userAccessLevel={userAccessLevel}
              accessLevels={[OWNER]}
              divider
            >
              <Typography variant='body2' component='span'>
                {knowledge.knowledgePositions && knowledge.knowledgePositions.length ? (
                  knowledge.knowledgePositions.map((position: KnowledgePositionType) => {
                    return i18n.language === 'en' ? (
                      <span key={position.positionTitles.en}>
                        {position.positionTitles.en}
                        <br />
                      </span>
                    ) : (
                      <span key={position.positionTitles.fr}>
                        {position.positionTitles.fr}
                        <br />
                      </span>
                    )
                  })
                ) : (
                  <Typography variant='body2' component='span' sx={{ color: 'secondary.light' }}>
                    {t('knowledge.view.overview.position_empty')}
                  </Typography>
                )}
              </Typography>
            </OverviewRow>

            <OverviewRow
              title={t('knowledge.view.overview.employee_broadcast')}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              modalLinkText={t('knowledge.view.overview.employee_broadcast_question')!}
              modalOpen={handleEmployeeBroadcastModalOpen}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              editButtonText={t('common.buttons.edit')!}
              editButtonModalOpen={handleChangeEmployeeBroadcastModalOpen}
              userAccessLevel={userAccessLevel}
              accessLevels={[OWNER]}
            >
              <Typography variant='body2' component='span'>
                {knowledge.employeeBroadcasts && knowledge.employeeBroadcasts.length ? (
                  knowledge.employeeBroadcasts.map((employee: EmployeeBroadcastType) => {
                    return (
                      <span key={employee.fullName}>
                        {employee.fullName}, {employee.position}
                        <br />
                      </span>
                    )
                  })
                ) : (
                  <Typography variant='body2' component='span' sx={{ color: 'secondary.light' }}>
                    {t('knowledge.view.overview.employee_broadcast_empty')}
                  </Typography>
                )}
              </Typography>
            </OverviewRow>
          </BaseContainer>
        </Grid>
        <Grid item xs={4}>
          <BasicInfo basicInfos={knowledge.basicInfos} />
        </Grid>
      </Grid>

      <KnowledgeStatusModal openModal={openStatusModal} handleModalClose={handleStatusModalClose} />

      <KnowledgeEmployeeBroadcastModal
        openModal={openEmployeeBroadcastModal}
        handleModalClose={handleEmployeeBroadcastModalClose}
      />

      <KnowledgeChangeStatusModal
        openModal={openChangeStatusModal}
        handleModalClose={handleChangeStatusModalClose}
        knowledgeStatus={knowledge.status}
        setPopupMessage={setPopupMessage}
      />

      <KnowledgeChangeCriticalitiesModal
        openModal={openChangeCriticalitiesModal}
        handleModalClose={handleChangeCriticalitiesModalClose}
        criticalityQuestions={knowledge.knowledgeCriticalities}
        setPopupMessage={setPopupMessage}
      />

      <ChangePositionsModal
        openModal={openPositionsModal}
        handleModalClose={handlePositionsModalClose}
        currentPositions={knowledge.knowledgePositions}
        setPopupMessage={setPopupMessage}
      />

      <KnowledgeEmployeeBroadcastModal
        openModal={openEmployeeBroadcastModal}
        handleModalClose={handleEmployeeBroadcastModalClose}
      />

      <ChangeEmployeeBroadcastModal
        openModal={openChangeEmployeeBroadcastModal}
        handleModalClose={handleChangeEmployeeBroadcastModalClose}
        currentEmployees={knowledge.employeeBroadcasts}
        setPopupMessage={setPopupMessage}
      />

      <AlertMessage message={popupMessage} setMessage={setPopupMessage} />
    </>
  )
}

export default KnowledgeOverviewContainer
